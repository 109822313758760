import { useState } from "react";
import Loading from "../../components/Loading.component";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Link } from "react-router-dom";

function PasswordRecover() {
    const [email, setEmail] = useState<string>('');
    const [alert, setAlert] = useState<any>({type: '', message: ''});
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        setAlert({ type: '', message: "" });
        setLoading(true);
        e.preventDefault();
        const auth = getAuth();

        await sendPasswordResetEmail(auth, email).then((result: any) => {
            console.log(result);
            setAlert({ type: 'success', message: "Recover email sent" });
        }).catch((error: any) => {
            setAlert({ type: 'alert', message: error.message });
        });
        setLoading(false);
    }

    return (
        <div className="container mt-4">
            <div className="row justify-content-center">
                <div className="col-12 col-md-4">

                    <div className="card shadow border-0">
                        <div className="card-body">
                            <div className="mb-4">
                                <strong>Password Recover</strong>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-2">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        data-testid="recover-email"
                                        disabled={loading}
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>

                                {alert.type !== "" && <div className={`alert mt-3 mb-3 alert-${alert.type}`} role="alert">{alert.message}</div>}

                                <button
                                    disabled={!email || loading}
                                    type="submit" className="btn btn-primary" data-testid="submit">
                                        <Loading loading={loading} parent="inline" color="text-white" /> Submit
                                    </button>
                            </form>
                        </div>
                    </div>

                    <div className="text-center mt-4">
                        <Link to="/" data-testid="backtologin" className="btn btn-outline-primary">&larr; back to login</Link>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default PasswordRecover;