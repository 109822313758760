import axios from 'axios';

export const getTemplates = async () => {
    return await axios.get("templates").then((response: any) => response.data).catch((err: any) => err);
}

export const editTemplate = async (payload: { id: string, templateName: string, fields: [{ fieldName: string, fieldDefaultValue: string }] }) => {
    return await axios.put(`templates/${payload.id}`, payload).then((response: any) => response.data).catch((err: any) => err);
}

export const createTemplate = async () => {
    return await axios.post(`templates`).then((response: any) => response.data).catch((err: any) => err);
}

export const deleteTemplate = async (templateId: string) => {
    return await axios.delete(`templates/${templateId}`).then((response: any) => response.data).catch((err: any) => err);
}

export const appendTemplate = async (payload: { targetCollection: string, templateId: string, documentIds: string[] }) => {
    return await axios.post(`templates/append`, payload).then((response: any) => response.data).catch((err: any) => err);
}
