import { useEffect, useState } from "react";
import { canAccess } from "../../services/helpers";
import { getDashboardSummaryDemographic } from "../../services/ecrf.service";
import Separator from "../../components/Separator.component";
import Loading from "../../components/Loading.component";
import Alert from "../../components/Alert/Alert.component";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import DateRangeInterface from "../../services/DateRange.interface";
import FilterBarDashboard from "../../components/FilterBarDashboard.component";
import { DashboardFilterInterface } from "../../services/DashboardFilterInterface";
import { runAi } from "../../services/ai.service";
import Markdown from "react-markdown";

// Using https://reactdatagrid.io/
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

dayjs.extend(utc);

function Dashboard(props: any) {
    // Do not add any other code here
    useEffect(() => {
        if (!canAccess(props.user, 'dashboard')) {
            window.location.href = '/home';
            return;
        }
    }, [props.user, props.user.role]);

    const [summary, setSummary] = useState([]);
    const [demographics, setDemographics] = useState([]);
    const [homeSwabsResults, setHomeSwabsResults] = useState([]);
    const [homeSwabsResultsColumns, setHomeSwabsResultsColumns] = useState([]);
    const [homeSwabsResultsFilters, setHomeSwabsResultsFilters] = useState([]);
    const [loadingDashboard, setLoadingDashboard] = useState(false);
    const [alert, setAlert] = useState({ type: '', message: '' });

    const [dashboardFilterParams, setDashboardFilterParams] = useState({
        dateRange: {
            from: dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
            to: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        } as DateRangeInterface,
        protocol: '',
        sites: '',
        participants: ''
    } as DashboardFilterInterface);

    const reloadDataByFilter = (dataFilter: DashboardFilterInterface) => {
        setDashboardFilterParams(dataFilter);
        loadDashboard(dataFilter);
    };

    
    const setHomeSwabsResultsBuildGrid = async (homeSwabsResultsData: any) => {
        setHomeSwabsResults(
            Object.keys(homeSwabsResultsData.rows).map((key: any) => {
                return homeSwabsResultsData.rows[key];
            }) as any
        );
        setHomeSwabsResultsColumns(homeSwabsResultsData.headers.map((header: any) => {
            if (header === "Site") return { name: header, header: header, width: 93, defaultFlex: 0, 
                render: ({ value }: { value: any }) => { return <div style={cellStyleBold}>{value}</div> } }
            return { name: header, header: header, defaultFlex: 0, }
        }))
        setHomeSwabsResultsFilters(homeSwabsResultsData.headers.map((header: any) => {
            return { name: header, operator: 'startsWith', type: 'string', value: '', };
        }));
    };

    const [rawData, setRawData] = useState([] as any[]);

    const loadDashboard = async (dataBoardFilterData: DashboardFilterInterface) => {
        setLoadingDashboard(true);
        await getDashboardSummaryDemographic(dataBoardFilterData).then((response: any) => {
            if (response.message === "OK") {

                console.log("Dashboard data received", response.rawData);
                console.log("Dashboard data received", JSON.stringify(response.rawData));
                setRawData(response.rawData);

                setSummary(response.dashboards.summary);
                setDemographics(response.dashboards.demographics);  
                setHomeSwabsResultsBuildGrid(response.dashboards.homeSwabsResults);
                setLoadingDashboard(false);
                return;
            }

            console.log("Error getting patients. Message received", response);
            setAlert({ type: 'danger', message: 'Error getting dashboard data. Message received' });
            setLoadingDashboard(false);
        }).catch((err: any) => {
            console.log(err);
            setAlert({ type: 'danger', message: 'Error getting dashboard data.' });
            setLoadingDashboard(false);
        });
    };

    useEffect(() => {
        loadDashboard(dashboardFilterParams);
    }, []);

    const gridStyle = { minHeight: 600 };

    const cellStyleBold = { fontWeight: 'bold' };
    const summaryColumns: any[] = [
        { name: 'SiteNo', header: 'Testing Site Number and Name', minWidth: 120, defaultFlex: 0, 
            render: ({ value }: { value: any }) => { return <div style={cellStyleBold}>{value}</div> } 
        },
        { name: 'TotalOfTests', header: 'Total of tests', minWidth: 120, defaultFlex: 1, },
        { name: '< 14 years', header: '< 14 years', minWidth: 220, defaultFlex: 1, },
        { name: '14-18 years', header: '14-18 years', minWidth: 170, defaultFlex: 1, },
        { name: '19-24 years', header: '19-24 years', minWidth: 140, defaultFlex: 1, },
        { name: '25-64 years', header: '25-64 years', minWidth: 170, defaultFlex: 1,},
        { name: '>= 65 years', header: '>= 65 years', minWidth: 220, defaultFlex: 1, }
    ];

    const summaryColumnsFilter: any[] = [
        { name: 'SiteNo', operator: 'startsWith', type: 'string', value: '', },
        { name: 'TotalOfTests', operator: 'startsWith', type: 'string', value: '', },
        { name: '< 14 years', operator: 'startsWith', type: 'string', value: '', },
        { name: '14-18 years', operator: 'startsWith', type: 'string', value: '', },
        { name: '19-24 years', operator: 'startsWith', type: 'string', value: '', },
        { name: '25-64 years', operator: 'startsWith', type: 'string', value: '', },
        { name: '>= 65 years', operator: 'startsWith', type: 'string', value: '', },
    ];

    const demographicsColumns: any[] = [
        { name: 'Age', header: "Age", minWidth: 215, defaultFlex: 0,
            render: ({ value }: { value: any }) => { return <div style={cellStyleBold}>{value}</div> } 
        },
        { name: 'Total', header: "Total", minWidth: 215, defaultFlex: 0, },
        { name: 'Female', header: "Female", minWidth: 215, defaultFlex: 0, },
        { name: "Male", header: "Male", minWidth: 216, defaultFlex: 0, },
        { name: 'Non-Binary', header: "Non-Binary", minWidth: 216, defaultFlex: 0, },
        { name: 'Unknown', header: "Unknown", minWidth: 216, defaultFlex: 0, },
    ];

    const demographicsColumnsFilter: any[] = [
        { name: 'Age', operator: 'startsWith', type: 'string', value: '', },
        { name: 'Total', operator: 'startsWith', type: 'string', value: '', },
        { name: 'Female', operator: 'startsWith', type: 'string', value: '', },
        { name: 'Male', operator: 'startsWith', type: 'string', value: '', },
        { name: "Non-Binary", operator: "startsWith", type: "string", value: "", },
        { name: "Unknown", operator: "startsWith", type: "string", value: "", },
    ];

    const RefreshDashboardListLink = () => {
        return <> 
            <div className="row align-items-center">
                <div className="col-12 col-md-12 text-end">
                    <button
                        onClick={() => loadDashboard(dashboardFilterParams)}
                        disabled={loadingDashboard}
                        className="btn btn-link btn-sm inline"
                        type="button"><i className="fas fa-sync me-2"></i> Refresh Dashboard List
                    </button>
                </div>
            </div>
        </>
    }

    const [loadingAi, setLoadingAi] = useState(false);
    const [aiMessage, setAiMessage] = useState("");
    const [aiResponse, setAiResponse] = useState("");
    const sendMessageToAI = async () => {
        setLoadingAi(true);
        setAiResponse("");
        // Call API to send message to AI
        const data: any = rawData && rawData.length > 0 ? JSON.stringify(rawData) : "";
        await runAi(aiMessage, data).then((response: any) => {
            console.log("RESPONSE AI", response);
            setAiResponse(response.aiResponse);
            setLoadingAi(false);
        }).catch((err: any) => {
            console.log("ERR AI", err);
            setLoadingAi(false);
        });
    };

    return <>
        <div className="container">
            <h2>Dashboard Clinical Trial Progress</h2>
            <p className="text-muted">
                Review daily patients and surveys from the ECRF project.
            </p>

            <Separator size={20} />

            {/*
            <textarea
                name="aiMessage"
                value={aiMessage}
                disabled={loadingAi}
                className="form-control"
                onChange={(e) => setAiMessage(e.target.value)}
                cols={8}></textarea>

            <Separator size={10} />

            <button
                onClick={sendMessageToAI}
                disabled={loadingAi}
                className="btn btn-primary">
                    <Loading loading={loadingAi} parent="inline" color="text-white" />
                    {!loadingAi && <i className="fas fa-check me-2"></i>} Send Message
            </button>

            {aiResponse !== "" && <>
                <Markdown>{aiResponse}</Markdown>
            </>}

            <Separator size={20} />
            */}

            <FilterBarDashboard onFilterDataChange={reloadDataByFilter}/>
            
            <Alert alert={alert} />

            <Separator size={20} />

            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="patients-tab" data-bs-toggle="tab" data-bs-target="#patients-tab-pane" type="button" role="tab" aria-controls="patients-tab-pane" aria-selected="true">
                        <Loading loading={loadingDashboard} parent="inline" /> Summary
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="survey-tab" data-bs-toggle="tab" data-bs-target="#survey-tab-pane" type="button" role="tab" aria-controls="survey-tab-pane" aria-selected="false">
                        <Loading loading={loadingDashboard} parent="inline" /> Demographics
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="home-swab-results-tab" data-bs-toggle="tab" data-bs-target="#home-swab-results-tab-pane" type="button" role="tab" aria-controls="home-swab-results-tab-pane" aria-selected="false">
                        <Loading loading={loadingDashboard} parent="inline" /> Home swabs Results
                    </button>
                </li>
            </ul>

            <Separator size={40} />

            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="patients-tab-pane" role="tabpanel" aria-labelledby="patients-tab">

                    <Loading loading={loadingDashboard} />

                    {!loadingDashboard && summary.length === 0 && <p>No summary found.</p>}

                    {!loadingDashboard && summary.length > 0 && <>
                        <RefreshDashboardListLink />
                        
                        <Separator size={10} />

                        <ReactDataGrid
                            idProperty="patientIdComposite"
                            style={gridStyle}
                            columns={summaryColumns}
                            dataSource={summary}
                            defaultFilterValue={summaryColumnsFilter}
                            editable={true}
                            enableSelection
                            multiSelect
                        />
                    </>}

                </div>
            </div>
            
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade" id="survey-tab-pane" role="tabpanel" aria-labelledby="survey-tab">

                    <Loading loading={loadingDashboard} />

                    {!loadingDashboard && demographics.length === 0 && <p>No demographics found.</p>}

                    {!loadingDashboard && demographics.length > 0 && <>
                        <RefreshDashboardListLink />

                        <Separator size={10} />

                        <ReactDataGrid
                            idProperty="patientIdComposite"
                            style={gridStyle}
                            columns={demographicsColumns}
                            dataSource={demographics}
                            defaultFilterValue={demographicsColumnsFilter}
                            editable={true}
                            enableSelection={false}
                        />
                    </>}

                </div>
            </div>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade" id="home-swab-results-tab-pane" role="tabpanel" aria-labelledby="home-swab-results-tab">

                    <Loading loading={loadingDashboard} />

                    {!loadingDashboard && demographics.length === 0 && <p>No home swab results found.</p>}

                    {!loadingDashboard && demographics.length > 0 && <>
                        <RefreshDashboardListLink />

                        <Separator size={10} />

                        <ReactDataGrid
                            idProperty="patientIdComposite"
                            style={gridStyle}
                            columns={homeSwabsResultsColumns}
                            dataSource={homeSwabsResults}
                            defaultFilterValue={homeSwabsResultsFilters}
                            editable={true}
                            enableSelection={false}
                        />
                    </>}

                </div>
            </div>
        </div>
    </>
}

export default Dashboard;