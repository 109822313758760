import ReactDataGrid from "@inovua/reactdatagrid-community";
import { useEffect, useState } from "react";
import { getReaders } from '../services/readers.service';
import Alert from './Alert/Alert.component';
import Loading from './Loading.component';


const ReadersGrid = (props: any) => {
    const [readers, setReaders] = useState<any>([]);
    const [loadingReaders, setLoadingReaders] = useState<boolean>(false);
    const [alert, setAlert] = useState<any>({ type: '', message: '' });
    const status: string = props.status || "all";

    const readersColumns = [
        { name: 'id', header: 'ID', minWidth: 50, maxWidth: 200, defaultFlex: 2 },
        { name: 'status', header: 'Status', minWidth: 50, maxWidth: 200, defaultFlex: 1 },
        { name: 'reader_name', header: 'Name', minWidth: 50, defaultFlex: 2 },
        { name: 'location', header: 'Location', maxWidth: 1000, defaultFlex: 1 },
        { name: 'group', header: 'Group', maxWidth: 1000, defaultFlex: 1 },
        { name: 'version', header: 'Version', maxWidth: 1000, defaultFlex: 1 },
        { name: 'firmware_version', header: 'Version Firmware', maxWidth: 1000, defaultFlex: 1 },
    ];
    
    const readerFilterValue = [
        { name: 'id', operator: 'startsWith', type: 'string', value: '' },
        { name: 'status', operator: 'startsWith', type: 'string', value: '' },
        { name: 'reader_name', operator: 'startsWith', type: 'string', value: '' },
        { name: 'location', operator: 'startsWith', type: 'string', value: null },
        { name: 'group', operator: 'startsWith', type: 'string', value: '' },
        { name: 'version', operator: 'startsWith', type: 'string', value: '' },
        { name: 'firmware_version', operator: 'startsWith', type: 'string', value: '' },
    ];
    
    const gridStyle = { minHeight: 450 };

    const _getReaders = async () => {
        setLoadingReaders(true);
        getReaders(status).then((res: any) => {
            if (!res.readers) {
                setAlert({ type: 'danger', message: 'Error loading readers list' });
                return;
            }
            setReaders(res.readers);
            setLoadingReaders(false);
        }).catch((err) => {
            console.log('ERRO', err);
            setAlert({ type: 'danger', message: 'Error loading readers' });
            setLoadingReaders(false);
        });
    }

    useEffect(() => {
        const load = async () => {
            _getReaders();
            return;
        }
        load();
    }, []);

    return (
        <>
            <Loading loading={loadingReaders} />

            <Alert alert={alert} />

            <ReactDataGrid
                idProperty="id"
                columns={readersColumns}
                dataSource={readers}
                style={gridStyle}
                pagination
                defaultFilterValue={readerFilterValue}
                enableSelection
                onSelectionChange={(selection: any) => {
                    const _selectedRows: any[] = [];
                    for (const [, value] of Object.entries(selection.selected)) {
                        _selectedRows.push(value);
                    }
                    props.setSelectedReaders([..._selectedRows]);
                }}
                multiSelect
                checkboxColumn
                />
        </>
    )
};

export default ReadersGrid;