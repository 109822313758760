import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap"
import { appendTemplate, getTemplates } from "../services/templates.service";
import Loading from "./Loading.component";
import { useFormik } from "formik";
import * as yup from 'yup';
import Separator from "./Separator.component";
import Alert from "./Alert/Alert.component";

const AppendTemplate = (props: any): any => {
    const [showModalAppendTemplate, setShowModalAppendTemplate] = useState(false);
    const [loadingGetTemplates, setLoadingGetTemplates] = useState(true);
    const [loadingAppendTemplates, setLoadingAppendTemplates] = useState(false);
    const [templates, setTemplates] = useState([] as any[]);
    const [alert, setAlert] = useState<any>({ type: '', message: '' });

    const selectedRows: any[] = props.selectedRows;
    const targetCollection: string = props.targetCollection;

    useEffect(() => {
      const _getTemplatesList = async () => {
        setLoadingGetTemplates(true);
        await getTemplates().then((res: any) => {
          setTemplates(res.templates);
          setLoadingGetTemplates(false);
        });
      };
      _getTemplatesList();
    }, []);

    const batchDataEditInitialValues: any = {
        targetCollection,
        templateId: '',
    };

    const appendTemplateFormik = useFormik({
        initialValues: batchDataEditInitialValues,
        isInitialValid: false,
        validationSchema: yup.object({
            targetCollection: yup.string().required('Target Collection is required'),
            templateId: yup.string().required('Template ID is required'),
        }),
        onSubmit: async (values: any) => {
            setLoadingAppendTemplates(true);

            const rowsToUpdate: string[] = [];
            selectedRows.forEach((item: any) => {
                rowsToUpdate.push(item.id);
            });

            const body: any = {
                targetCollection,
                documentIds: rowsToUpdate,
                templateId: values.templateId,
            };

            await appendTemplate(body).then((res: any) => {
                if (res.message === "OK") {
                    setLoadingAppendTemplates(false);
                    setAlert({ type: 'success', message: `${rowsToUpdate.length} rows updated successfully` });
                    setShowModalAppendTemplate(false);
                    appendTemplateFormik.resetForm();
                    props.onTemplateAppend(true);
                    return;
                }

                console.log("Error appending template (res)", res);
                setAlert({ type: 'danger', message: 'Error appending template' });
                setLoadingAppendTemplates(false);
            }).catch((err: any) => {
                console.log("Error on update template", err);
                setAlert({ type: 'danger', message: 'Error appending template' });
                setLoadingAppendTemplates(false);
            });
        }
    });

    return <>
        <Alert alert={alert} />

        <button
            type="button"
            disabled={selectedRows.length === 0}
            onClick={() => setShowModalAppendTemplate(true)}
            className='btn btn-outline-secondary'>
                Append Template
        </button>

        <Modal show={showModalAppendTemplate} size='lg'>
            <Modal.Header closeButton onClick={() => setShowModalAppendTemplate(false)}>
                <Modal.Title>Append Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loading loading={loadingGetTemplates} />

                {!loadingGetTemplates && templates.length > 0 && <>
                    <strong>Rows Selected: </strong>{selectedRows.length}<br />
                    <strong>Target Collection: </strong>{targetCollection}<br />

                    <Separator size={20} />

                    <form onSubmit={(e: any) => {
                        e.preventDefault();
                        appendTemplateFormik.handleSubmit();
                    }}>
                        <label className="form-label">Select a template to append</label>
                        <select
                            disabled={loadingAppendTemplates || selectedRows.length === 0}
                            name="templateId"
                            value={appendTemplateFormik.values.templateId}
                            onChange={appendTemplateFormik.handleChange}
                            className="form-select" aria-label="Select template"
                        >
                            <option value="">No templates selected</option>
                            {templates.map((item: any, index: number) => {
                                return <option key={`template-${index}`} value={item.id}>{item.templateName}</option>
                            })}
                        </select>

                        {appendTemplateFormik.values.templateId && <>
                            <Separator size={20} />

                            <small className="text-muted">Template Preview:</small>

                            <Separator size={20} />

                            <code>
                                <pre>
                                    &#123;<br />
                                        <div style={{ paddingLeft: '20px' }}>
                                            ...rest of document
                                            <Separator size={10} />
                                            {(templates.find((item: any) => item.id === appendTemplateFormik.values.templateId).fields).map((field: any, index: number) => {
                                                return <div key={`field-${index}`}>
                                                    "{field.fieldName}": "{field.fieldDefaultValue}"
                                                </div>
                                            })}
                                        </div>
                                    &#125;
                                </pre>
                            </code>

                            <small className="text-muted">
                                All fields from the selected template will be added to <strong>{selectedRows.length} selected rows</strong>.<br />
                                If a field already exists in the target, it will be skipped.<br />
                                Reference fields (ref_) will append the full object found.
                            </small>

                            <Separator size={40} />

                            <hr />

                            <strong>Append template "{templates.find((item: any) => item.id === appendTemplateFormik.values.templateId).templateName}" to {selectedRows.length} rows on "{targetCollection}" collection</strong>

                            <Separator size={10} />

                            <button
                                type="submit"
                                disabled={loadingAppendTemplates || !appendTemplateFormik.isValid}
                                className='btn btn-primary'>
                                    <Loading loading={loadingAppendTemplates} parent="inline" color="text-white" />
                                    {!loadingAppendTemplates && <><i className="fas fa-check me-2"></i></>} Proceed
                            </button>

                        </>}
                    </form>
                </>}
            </Modal.Body>
        </Modal>
    </>
}

export default AppendTemplate;