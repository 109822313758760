import { useEffect, useState } from "react";
import { addRowsToProcess, getAlgorithms } from "../../services/data.service";
import { Modal } from "react-bootstrap";
import Loading from "../Loading.component";
import Separator from "../Separator.component";
import Alert from "../Alert/Alert.component";
import React from "react";
import _ from 'lodash';

const DataAnalysis = (props: any) => {
    const {
        selectedRows,
        collection,
    } = props;

    const [showModalAlgorithms, setShowModalAlgorithms] = useState(false);
    const [loadingAlgorithms, setLoadingAlgorithms] = useState(true);
    const [loadingHandleProcess, setLoadingHandleProcess] = useState(false);
    const [algorithms, setAlgorithms] = useState([]);
    const [selectedAlgorithms, setSelectedAlgorithms] = useState([] as any);
    const [alert, setAlert] = useState({type: "", message: ""});
    const [showAlgorithmsChanged, setShowAlgorithmsChanged] = useState(false);

    const backendUrl: string = process.env.REACT_APP_AXIOS_URL || "";

    useEffect(() => {
        const load = async () => {
            setLoadingAlgorithms(true);
            await getAlgorithms().then((res: any) => {
                if (res.message === "OK") {
                    setAlgorithms(res.algorithms)
                    setLoadingAlgorithms(false);
                    return;
                }
                setLoadingAlgorithms(false);
                setAlert({type: "danger", message: "Error loading algorithms"});
                console.log("ERR", res);
            }).catch((err: any) => {
                console.log("ERR", err);
                setAlert({type: "danger", message: "Catch: Error loading algorithms"});
                setLoadingAlgorithms(false);
            });
        };
        load();
    }, [showModalAlgorithms]);

    const handleProcess = async () => {
        setLoadingHandleProcess(true);
        await addRowsToProcess({selectedAlgorithms, selectedRows, collection}).then((response: any) => {
            if (response.message === "OK") {
                setSelectedAlgorithms([]);
                setShowModalAlgorithms(false);
                setLoadingHandleProcess(false);
                setAlert({type: "success", message: "Data sent to process"});
                return;
            }

            setAlert({type: "danger", message: "Error sending data to process"});
            setLoadingHandleProcess(false);
        }).catch((err: any) => {
            console.log("RESPONSE ERR", err);
            setAlert({type: "danger", message: "Catch Error sending data to process"});
            setLoadingHandleProcess(false);
        });
    };

    const _openModalAlgorithms = () => {
        setSelectedAlgorithms([]);
        setShowModalAlgorithms(true);
    };

    return <>
        <Alert alert={alert} />

        <button
            type='button'
            disabled={selectedRows.length === 0}
            onClick={() => _openModalAlgorithms()}
            className='btn btn-outline-secondary'>
                Data Analysis
        </button>

        <Modal show={showModalAlgorithms} size="lg">
            <Modal.Header closeButton onClick={() => {
                setShowModalAlgorithms(false);
                setAlgorithms([]);
            }}>
                <Modal.Title>Algorithms</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Loading loading={loadingAlgorithms} />

                {showAlgorithmsChanged && <>
                    <div className="alert bg-warning">
                        <i className="fas fa-exclamation-triangle me-2"></i> One or more algorithm features has changed.<br />
                        Once you click on "Process" button, all the {selectedRows.length} selected rows will be processed with the new features.<br />
                        You should reload this modal to see what changed.
                    </div>
                    <Separator size={10} />
                </>}

                {!loadingAlgorithms && algorithms.length > 0 && <>
                    Select an algorithm to process {selectedRows.length} selected rows:

                    <Separator size={20} />

                    <div className="row">
                        {algorithms.map((algorithm: any, index: number) => {
                            const algorithmName: string = algorithm.name;
                            return <div key={`algorithm-list-${index}`}>
                                <label>
                                    <input type="checkbox"
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                if (selectedAlgorithms.includes(algorithmName)) return;
                                                setSelectedAlgorithms([...selectedAlgorithms, algorithmName]);
                                                return;
                                            }
                                            setSelectedAlgorithms(selectedAlgorithms.filter((selectedAlgorithm: any) => selectedAlgorithm !== algorithmName));
                                        }} />
                                    <span className="ms-2" /> {algorithmName}

                                    <Separator size={10} />

                                    {algorithm.chipType && <>
                                        <div className="ps-3">
                                            <small className="text-muted">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Chip type</th>
                                                            <th className="text-center">Cutoff start</th>
                                                            <th className="text-center">Cutoff end</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {algorithm.chipType.map((item: any, index: number) => {
                                                            return <React.Fragment key={`algorithm-list-chipType-${index}`}>
                                                                <tr>
                                                                    <td>{item.name}</td>
                                                                    <td className="text-center">{item.cutoff_start}</td>
                                                                    <td className="text-center">{item.cutoff_end}</td>
                                                                </tr>
                                                            </React.Fragment>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </small>
                                        </div>
                                    </>}
                                </label>

                                <Separator size={10} />
                            </div>
                        })}
                    </div>

                    <Separator size={20} />

                    <small className="text-muted">
                        <ul>
                            <li>Algorithm worker runs each 5 minutes (<a href={`${backendUrl}/data/queue/run`} target="_blank" rel="noopener noreferrer">run manually</a>).</li>
                            <li>Only "finish" rows will be processed.</li>
                            <li>Only rows with channels and data will be processed.</li>
                            <li>Rows with status "running" will be queued to process automatically when they finish.</li>
                            <li>When processed by the same algorithms, the document will be overwritten with the new results.</li>
                            <li>When processed by the different algorithms, the document will be appended with the new results.</li>
                            {selectedAlgorithms.length > 0 && selectedRows.length > 0 && <>
                                <li>This will create {selectedAlgorithms.length * selectedRows.length} jobs in the queue.</li>
                            </>}
                        </ul>
                    </small>

                    <Separator size={10} />

                    <button
                        disabled={selectedAlgorithms.length === 0 || loadingHandleProcess}
                        className="btn btn-primary"
                        onClick={() => handleProcess()}>
                            <Loading loading={loadingHandleProcess} parent="inline" color="text-white" />
                            {!loadingHandleProcess && <><i className="fas fa-cog me-2"></i></>} Process
                    </button>
                </>}

            </Modal.Body>
        </Modal>
    </>;
};

export default DataAnalysis;