import { useFormik } from "formik";
import * as yup from 'yup';
import dayjs from 'dayjs';
import { getPlotData } from "../services/plot.service";
import { useState } from "react";
import Loading from "./Loading.component";

const LoadMoreBar = (props: any) => {
    const [next] = useState('')
    const [loadingMoreData, setLoadingMoreData] = useState(false);
    const filterInitialValues: any = {
        dataOrigin: 'usb_data',
        dateRange: {
            from: props.filterCriteriaFromParent.dateRange.from,
            to: props.filterCriteriaFromParent.dateRange.to,
        },
        dateField: 'createdAt', // start_time, date (the DATE column)
        next: next
    };
    const filterFormik = useFormik({
        initialValues: filterInitialValues,
        validationSchema: yup.object({
            dateRange: yup.object({
                from: yup.string().required('From date is required'),
                to: yup.string().required('To date is required'),
            }),
            dateField: yup.string().required('Name is required'),
            next: yup.number(),
            readers: yup.array().of(yup.string()).optional().default([]),
        }),
        onSubmit: (values) => {
            setLoadingMoreData(true);
            props.onLoadingDataRows(true);
            values = {
                ...values, 
                ...{dataOrigin: props.filterCriteriaFromParent.dataOrigin},
                ...{next: props.dataRows[props.dataRows.length - 1].id},
                ...{readers: props.getSelectedReaders().map((reader: any) => reader.id.toString())}
            }
            const filterObjectToToAPI = {
                ...values,
                dataOrigin: props.filterCriteriaFromParent.dataOrigin,
                ranges: { 
                    xAxis: props.filterCriteriaFromParent.ranges.xAxis,
                    yAxis: props.filterCriteriaFromParent.ranges.yAxis
                },
                rangesNormalized: { 
                    xAxis: props.filterCriteriaFromParent.rangesNormalized.xAxis,
                    yAxis: props.filterCriteriaFromParent.rangesNormalized.yAxis
                },
                normalizedValue: props.filterCriteriaFromParent.normalizedValue,
            }
            props.onFilterCriteriaChanges(filterObjectToToAPI);
            getPlotData(values).then((data: any) => {
                if (data.data) {
                    props.onFilter([...props.dataRows, ...data.data]);
                }else{
                    props.onFilter([...props.dataRows]);
                }
                props.onLoadingDataRows(false);
                setLoadingMoreData(false);
            });
        },
    });

    return <>
        <form onSubmit={(e: any) => {
            e.preventDefault();
            filterFormik.handleSubmit();
        }} className="inline">
            <button type="submit" className="btn btn-link inline" disabled={loadingMoreData}>
                <small>
                    <i className="fas fa-sync-alt me-2"></i> Load More Rows
                </small>
            </button>
        </form>
    </>;
}

export default LoadMoreBar;