import axios from "axios";

export const getAccessGroups = async () => {
  return await axios.get(`/access/groups`).then((res) => res.data).catch((err) => err);
};

export const createAccessGroup = async (payload: { groupName: string }) => {
  return await axios.post(`/access/groups`, payload).then((res) => res.data).catch((err) => err);
};

interface PermissionsInterface {
  id: string;
  groupName: string;
  permissions: string[];
}

export const savePermissionsAccessGroup = async (payload: PermissionsInterface[]) => {
  return await axios.patch(`/access/groups`, payload).then((res) => res.data).catch((err) => err);
};

interface UpdateUsersPermissionsAccessGroupInterface {
  users: string[];
  groupId: string;
}

export const updateUsersPermissionsAccessGroup = async (payload: UpdateUsersPermissionsAccessGroupInterface) => {
  return await axios.post(`/access/groups/updateUsers`, payload).then((res) => res.data).catch((err) => err);
};
