import axios from "axios";

export const getSettings = async () => {
  return await axios.get(`/settings`).then((res) => res.data).catch((err) => err);
};

interface CreateRule {
  type: string
  watchCollection: string
  templateToAppend: {
    id: string
    name: string
  }
  recipients: string
}

export const createRule = async (payload: CreateRule) => {
  return await axios.post(`/settings/rules`, payload).then((res) => res.data).catch((err) => err);
};

export const deleteRule = async (ruleId: string) => {
  return await axios.delete(`/settings/rules/${ruleId}`).then((res) => res.data).catch((err) => err);
};
