import { CSSProperties } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import dayjs from "dayjs";
import { flatten } from 'flat';

export const flattenAndFilter = (obj: any, filterStartsWith: string): any => {
  const flattenDoc: any = flatten(obj, {
    maxDepth: 2,
  });

  const newFlattenDoc: any = Object.fromEntries(
    Object.entries(flattenDoc).filter(([key, value]) => !key.startsWith(filterStartsWith))
  );

  Object.keys(newFlattenDoc).forEach((key) => {
    if (typeof newFlattenDoc[key] === "object" && Object.keys(newFlattenDoc[key]).length === 0) {
      delete newFlattenDoc[key];
    }
  });

  return newFlattenDoc;
};

export const COLORS: string[] = [
  "#55EfC4",
  "#FFF200",
  "#74B9FF",
  "#A29BFE",
  "#E84393",
  "#D63031",
  "#E17055",
  "#33D9B2",
  "#6C5CE7",
  "#FF9F1A",
  "#4B4B4B",
  "#0652DD",
  "#833471",
  "#009432",
  "#1B1464",
  "#CCAE62",

  // '#4dc9f6',
  // '#f67019',
  // '#f53794',
  // '#537bc4',
  // '#acc236',
  // '#166a8f',
  // '#00a950',
  // '#58595b',
  // '#8549ba',
  // '#4dc9f6',
  // '#f67019',
  // '#f53794',
  // '#537bc4',
  // '#acc236',
  // '#166a8f',
  // '#00a950'
];

export const prefixSelectFields = "CH";

export const cellStyleGreen: CSSProperties = {backgroundColor: "green", color: "white"};
export const cellStyleRed: CSSProperties = {backgroundColor: "red", color: "white"};

export const reactDataGridRenderCellStyleResult = ({ value }: any) => {
  let cellStyle: CSSProperties = {};
  switch (parseInt(value, 10)) {
      case 1:
          cellStyle = cellStyleRed;
          break;

      case 0:
          cellStyle = cellStyleGreen;
          break;

      case -1:
          cellStyle = {};
          break;
  }

  return <div style={cellStyle}>{value ? value.toString() : ""}</div>
}

export const reactDataGridFilterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
  "number": {
    name: 'number',
    operators: [
      {
        name: 'Equals', fn: ({ value, filterValue }: { value: number, filterValue: number | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          return +value === +filterValue;
        }
      },
      {
        name: 'Not Equals', fn: ({ value, filterValue }: { value: number, filterValue: number | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          return +value !== +filterValue;
        }
      },
      {
        name: 'Greater Than', fn: ({ value, filterValue }: { value: number, filterValue: number | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          return +value > +filterValue;
        }
      },
      {
        name: 'Less Than', fn: ({ value, filterValue }: { value: number, filterValue: number | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          return +value < +filterValue;
        }
      },
      {
        name: 'Greater or Equals Than', fn: ({ value, filterValue }: { value: number, filterValue: number | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          return +value >= +filterValue;
        }
      },
      {
        name: 'Less or Equals Than', fn: ({ value, filterValue }: { value: number, filterValue: number | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          return +value <= +filterValue;
        }
      },
    ]
  },
  "channel": {
    name: 'channel',
    operators: [
      {
        name: 'eq', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          return value.toString() === filterValue.toString();
        }
      }
    ]
  },
  "aptRegexContains": {
    name: 'regex',
    operators: [
      {
        name: 'OR', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          let re = new RegExp(filterValue.split(" ").map(item => item.replace("[", "")).join("|"), "i");
          return value.toString().match(re) !== null;
        }
      },
      {
        name: 'AND', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          let re = new RegExp(filterValue.split(" ").map(item => item.replace("[", "")).map(item => `(?=.*${item})`).join(""), "i");
          return value.toString().match(re) !== null;
        }
      },
      {
        name: 'contains', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          return value.toString().toLowerCase().includes(filterValue.toLowerCase());
        }
      },
      {
        name: 'Equals', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          return value.toString().toLowerCase() === filterValue.toLowerCase();
        }
      },
      {
        name: 'Does not equal', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          return !(value.toString().toLowerCase() === filterValue.toLowerCase());
        }
      },
      {
        name: 'Empty', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          return value === undefined;
        }
      },
      {
        name: 'Not empty', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          return value !== undefined;
        }
      },
      {
        name: 'Starts with', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          let re = new RegExp(`^${filterValue}`, "i")
          return value.toString().match(re) !== null;
        }
      },
      {
        name: 'Ends with', fn: ({ value, filterValue }: { value: any, filterValue: string | null }) => {
          if (!filterValue) return true;
          if (!value) return false;
          let re = new RegExp(`${filterValue}$`, "i");
          return value.toString().match(re) !== null;
        }
      },
    ]
  },
});

export const convertFirebaseTimestampToString = (firebaseTimestamp: { _seconds: number, _nanoseconds: number } | any, format: string = "YYYY-MM-DD HH:mm:ss"): string => {
  if (!firebaseTimestamp || !firebaseTimestamp._seconds) {
    return "";
  }

  const fireBaseTime = new Date(
    firebaseTimestamp._seconds * 1000 + firebaseTimestamp._nanoseconds / 1000000,
  );
  return dayjs(fireBaseTime).format(format);
}

export const getAllAdminRoles = () => ["superadmin", "admin"];

export const getAllRoles = () => ["superadmin", "admin", "userlab", "sales", "ecrf"];

export const getRoles = (role: string): string[] => {
    if (role === "superadmin") {
        return getAllRoles();
    }
    if (role === "admin") {
        return ['admin', 'userlab', 'sales', 'ecrf'];
    }
    return ['userlab', 'sales', 'ecrf'];
}
export const getAdminRoles = (role:string): string[] => {
    if (role === "superadmin") {
        return getAllAdminRoles();
    }
    if (role === "admin") {
        return ['admin'];
    }
    return [];
}
export const slugifyString = (str: string): string => {
    return str.toLowerCase().replace(/ /g, '_').replace(/[^\w-]+/g, '');
}
export const getRamdomColor = (): string => {
    const color = `#${Math.floor(Math.random()*16777215).toString(16).toUpperCase()}`
    return color.length < 6 ? color.padEnd(6, "0") : color;
}

export const CSVColumns = [
    { column: "id", label: "ID", }
];

export const getColumnLabel = (column: string): string => {
  const columnObj = CSVColumns.find((col: any) => col.column === column);
  return columnObj ? columnObj.label : column;
};

/**
 * ROLE is the GROUP of permissions
 * GROUPS: admin, sales, labuser
 */
export const modulesDetails: any[] = [
  { module: "plot", icon: "fas fa-chart-bar", description: "ECHEM Data" },
  { module: "accessGroups", icon: "fas fa-user-cog", description: "Access Groups" },
  {
    module: "readers",
    icon: "fas fa-digital-tachograph",
    description: "Readers Management",
  },
  // { module: "sales", icon: "fab fa-salesforce", description: "Sales from Webflow" },
  { module: "users", icon: "fas fa-users", description: "Users Management" },
  { module: "lamp", icon: "fas fa-lightbulb", description: "LAMP Data" },
  {
    module: "primerViewer",
    icon: "fas fa-syringe",
    description: "Primer Viewer Tool",
  },
  {
    module: "ecrf",
    icon: "fas fa-file-medical",
    description: "ECRF Patients Review",
  },
  {
    module: "dashboard",
    icon: "far fa-list-alt",
    description: "Dashboard Clinical trial progress",
  },
  {
    module: "templates",
    icon: "fas fa-file",
    description: "Pre-set data templates",
  },
  { module: "settings", icon: "fas fa-cog", description: "General Settings" },
  { module: "algorithms", icon: "fas fa-wrench", description: "Data Analysis Algorithms" },
  {
    module: "plotting",
    icon: "fas fa-chart-pie",
    description: "Plotting - Data Analysis",
    url: process.env.REACT_APP_PLOTTING_URL || "",
    sendAccessToken: true,
  },
  {
    module: "idtdna",
    icon: "fas fa-file-medical",
    description: "IDT DNA",
    url: process.env.REACT_APP_IDTDNA_URL || "",
    sendAccessToken: true,
  },
  {
    module: "inventory",
    icon: "fas fa-puzzle-piece",
    description: "Inventory Management",
    url: process.env.REACT_APP_INVENTORY_URL || "",
    sendAccessToken: false,
  },
  {
    module: "cencalReports",
    icon: "fas fa-chart-line",
    description: "Cencal Labs Reports",
  },
  {
    module: "firmwareHub",
    icon: "fas fa-microchip",
    description: "Firmware Downloads",
  },
];

export const getModuleDetails = (module: string): any => {
  return modulesDetails.find((item: any) => item.module === module);
};

export const canAccess = (user: any, page: string): boolean => {
    if (user.groups.includes(page)) {
      return true;
    }

    return false;
}

/*
export const selectedFieldsNamesThatContainsYvalues = (keysFromItemRow: string[]): string[] => {
    
  if (!keysFromItemRow) return [];
  
  return  Object.keys(keysFromItemRow)
    .filter(key => {
      return key.includes(prefixSelectFields)
    })
    .sort()
    .filter(x => x.indexOf("userMarkedAs") === -1 && x.indexOf("ReaderCalling") === -1 && x.indexOf("label") === -1);
}
*/

export const selectedFieldsNamesThatContainsYvalues = (selectedRows: any[]): any => {
  const result = [] as any;
  if (!selectedRows) return [];
  selectedRows.forEach((row: any) => {
    const channelsNames = Object.keys(row)
      .filter((key) => key.includes(prefixSelectFields))
      .sort()
      .filter(
        (x) =>
          x.indexOf("userMarkedAs") === -1 &&
          x.indexOf("ReaderCalling") === -1 &&
          x.indexOf("label") === -1
      );
    channelsNames.forEach((channelNameRow: any) => {
      result.push(channelNameRow);
    });
  });
  return result.sort().filter((item:any, idx:any) => result.indexOf(item) === idx);
};
