import { useEffect, useState } from "react";
import { canAccess, getModuleDetails, modulesDetails } from "../../services/helpers";
import Separator from "../../components/Separator.component";
import Alert from "../../components/Alert/Alert.component";
import { createAccessGroup, getAccessGroups, savePermissionsAccessGroup, updateUsersPermissionsAccessGroup } from "../../services/accessGroups.service";
import { getUsers } from "../../services/users.service";
import { useFormik } from "formik";
import * as yup from 'yup';
import Loading from "../../components/Loading.component";

const AccessGroups = (props: any) => {
  // Do not add any other code here
  useEffect(() => {
    if (!canAccess(props.user, 'accessGroups')) {
        window.location.href = '/home';
        return;
    }
  /* eslint-disable-next-line */
  }, [props.user.role]);

  const [alert, setAlert] = useState({ type: '', message: '' });

  useEffect(() => {
    _getAccessGroups();
    _getUsers();
  }, []);

  const [loadingGroups, setLoadingGroups] = useState(false);
  const [groups, setGroups] = useState([] as any[]);

  const _getAccessGroups = async () => {
    setLoadingGroups(true);
    await getAccessGroups().then((response: any) => {
      if (response.message === "OK") {
        setGroups(response.groups);
        _formatPermissions(response.groups);
        setLoadingGroups(false);
        return;
      }
    }).catch((err: any) => {
      console.log("Error", err);
      setLoadingGroups(false);
    });
  };

  const [permissions, setPermissions] = useState([] as any[]);

  // For each group, add all the modules that are in its own permissions array
  const _formatPermissions = (groups: any) => {
    const formatPermissions: any = groups.map((group: any) => {
      return {
        id: group.id,
        groupName: group.groupName,
        permissions: group.permissions,
      }
    });

    setPermissions(formatPermissions);
  };

  const [users, setUsers] = useState([] as any);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const _getUsers = async () => {
    setLoadingUsers(true);
    await getUsers().then((response: any) => {
      if (response.users && response.users.length > 0) {
        response.users.sort((a: any, b: any) => a.name.localeCompare(b.name));
        setUsers(response.users);
        setLoadingUsers(false);
        return;
      }
    }).catch((err: any) => {
      console.log("Error", err);
      setLoadingUsers(false);
    });
  };

  const [toggleQuickUsersManagement, setToggleQuickUsersManagement] = useState(false);

  const [loadingCreateGroup, setLoadingCreateGroup] = useState(false);

  const formCreateGroup: any = useFormik({
    initialValues: {
      groupName: '',
    },
    validateOnMount: true,
    validationSchema: yup.object({
      groupName: yup.string().required('Group Name is required'),
    }),
    onSubmit: async (values) => {
      setLoadingCreateGroup(true);
      await createAccessGroup({ groupName: values.groupName }).then((response: any) => {
        if (response.message === "OK") {
          setAlert({ type: 'success', message: 'Group created successfully' });
          _getAccessGroups();
          setLoadingCreateGroup(false);
          formCreateGroup.resetForm();
          return;
        }

        setLoadingCreateGroup(false);
        setAlert({ type: 'danger', message: 'Error creating group' });
      }).catch((err: any) => {
        console.log("Error", err);
        setAlert({ type: 'danger', message: 'Error creating group' });
        setLoadingCreateGroup(false);
      });
    }
  });

  const [loadingSavingGroupPermissions, setLoadingSavingGroupPermissions] = useState(false);

  const saveGroupPermissions = async () => {
    setLoadingSavingGroupPermissions(true);
    await savePermissionsAccessGroup(permissions).then((response: any) => {
      if (response.message === "OK") {
        setAlert({ type: 'success', message: 'Group permissions saved successfully' });
        setLoadingSavingGroupPermissions(false);
        return;
      }

      setLoadingSavingGroupPermissions(false);
      setAlert({ type: 'danger', message: 'Error saving group permissions' });
    }).catch((err: any) => {
      console.log("Error", err);
      setAlert({ type: 'danger', message: 'Error saving group permissions' });
      setLoadingSavingGroupPermissions(false);
    });
  };

  const [loadingUpdateUsers, setLoadingUpdateUsers] = useState(false);
  const formUpdateUsers: any = useFormik({
    initialValues: {
      users: [],
      groupId: '',
    },
    validateOnMount: true,
    validationSchema: yup.object({
      users: yup.array().required('Users are required'),
      groupId: yup.string().required('Group is required'),
    }),
    onSubmit: async (values) => {
      setLoadingUpdateUsers(true);
      await updateUsersPermissionsAccessGroup({ users: values.users, groupId: values.groupId }).then((response: any) => {
        if (response.message === "OK") {
          setAlert({ type: 'success', message: 'Users moved to group successfully' });
          setLoadingUpdateUsers(false);
          return;
        }

        setLoadingUpdateUsers(false);
        setAlert({ type: 'danger', message: 'Error moving users to group' });
      }).catch((err: any) => {
        console.log("Error", err);
        setAlert({ type: 'danger', message: 'Error moving users to group' });
        setLoadingUpdateUsers(false);
      });
    }
  });

  return <>
    <Alert alert={alert} />

    <div className="container">
      <h2>Access Groups</h2>
      <p className="text-muted">
        Create and Manage Access Groups
      </p>

      <Separator size={20} />

      <div className="card bg-light border-0">
        <div className="card-body">
          <strong>Create Group</strong>
          <div>
            <small className="text-muted">
              In this step, you just need to create a new group. After that, you can add permissions to it.
            </small>
          </div>

          <Separator size={20} />

          <form onSubmit={formCreateGroup.handleSubmit}>
            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  disabled={loadingCreateGroup || loadingGroups}
                  autoComplete="off"
                  className="form-control"
                  name="groupName"
                  onChange={formCreateGroup.handleChange}
                  value={formCreateGroup.values.groupName}
                  placeholder="Group Name" />
              </div>
              <div className="col-6">
                <button disabled={!formCreateGroup.isValid || loadingCreateGroup || loadingGroups} type="submit" className="btn btn-primary">
                  <Loading loading={loadingCreateGroup} parent="inline" color="text-white" />
                  {!loadingCreateGroup && <i className="fas fa-plus me-2"></i>} Create Group
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Separator size={40} />

      <Loading loading={loadingGroups} />

      {!loadingGroups && groups.length > 0 && <>
        {groups.map((group: any, indexGroup: number) => <div key={`group-${group.id}`} className="mb-3">
            <strong>{group.groupName} ({permissions.find((p: any) => p.groupName === group.groupName)?.permissions.length || 0} permissions)</strong>

            <Separator size={20} />

            <div className="row">
              {modulesDetails.map((module: any, indexModule: number) => {
                return <div className="col-12 col-md-3" key={`module-${module.module}-${indexModule}-${indexGroup}`}>
                  <label>
                    <input
                      type="checkbox"
                      className="me-2"
                      disabled={loadingSavingGroupPermissions}
                      onChange={(e) => {
                        if (e.target.checked) {
                          // Add module to group
                          const newPermissions = permissions.map((permission: any) => {
                            if (permission.groupName === group.groupName) {
                              return {
                                ...permission,
                                groupName: permission.groupName,
                                permissions: [...permission.permissions, module.module],
                              }
                            }
                            return permission;
                          });
                          setPermissions(newPermissions);
                        } else {
                          // Remove module from group
                          const newPermissions = permissions.map((permission: any) => {
                            if (permission.groupName === group.groupName) {
                              return {
                                ...permission,
                                groupName: permission.groupName,
                                permissions: permission.permissions.filter((p: string) => p !== module.module),
                              }
                            }
                            return permission;
                          });
                          setPermissions(newPermissions);
                        }
                      }}
                      checked={permissions.find((p: any) => p.groupName === group.groupName)?.permissions.includes(module.module)}
                    />
                    <i className={`${module.icon} me-2`}></i>
                    {module.module.toUpperCase()}<br />
                    <small className="text-muted">
                      {module.description}
                    </small>
                  </label>

                  <Separator size={20} />
                </div>
              })}
            </div>

            <Separator size={50} />

        </div>)}

        <button
          onClick={() => saveGroupPermissions()}
          disabled={loadingSavingGroupPermissions}
          className="btn btn-primary">
            <Loading loading={loadingSavingGroupPermissions} parent="inline" color="text-white" />
            {!loadingSavingGroupPermissions && <i className="fas fa-check me-2"></i>} Save Group Changes
        </button>
      </>}

      <Separator size={40} />

      {!toggleQuickUsersManagement && <>
        <button type="button" className="btn btn-link inline" onClick={() => setToggleQuickUsersManagement(true)}>
          <i className="fas fa-eye me-2"></i> Quick Users Groups Management
        </button>
      </>}

      {toggleQuickUsersManagement && <>
        <button type="button" className="btn btn-link inline" onClick={() => setToggleQuickUsersManagement(false)}>
          <i className="fas fa-eye-slash me-2"></i> Quick Users Groups Management
        </button>

        <Separator size={10} />

        <h2>Quick Users Groups Management</h2>

        <Separator size={10} />

        <form onSubmit={formUpdateUsers.handleSubmit}>
          <ul className="list-unstyled">
            {!loadingUsers && users.length > 0 && <>
              {users.map((user: any) => <li key={`user-${user.id}`}>
                <label>
                  <input
                    type="checkbox"
                    name="users"
                    disabled={loadingUsers || loadingUpdateUsers}
                    onChange={formUpdateUsers.handleChange}
                    value={user.id}
                    className="me-2" /> {user.name} <span className="text-muted">({user.email})</span>
                </label>
              </li>)}
            </>}
          </ul>

          <Separator size={10} />

          <div className="row">
            <div className="col-5">
              <select
                name="groupId"
                disabled={loadingUsers || loadingUpdateUsers || groups.length === 0}
                onChange={formUpdateUsers.handleChange}
                value={formUpdateUsers.values.groupId}
                className="form-select">
                  <option value="">Select a Group</option>
                  {groups.map((group: any, index: number) => {
                    return <option key={`group-${group.id}`} value={group.id}>{group.groupName}</option>
                  })}
              </select>
            </div>
          </div>

          <Separator size={10} />

          <button
            type="submit"
            disabled={!formUpdateUsers.isValid || loadingUpdateUsers}
            className="btn btn-primary">
              <Loading loading={loadingUpdateUsers} parent="inline" color="text-white" />
              {!loadingUpdateUsers && <i className="fas fa-check me-2"></i>} Move Selected Users to Group
          </button>
        </form>
      </>}
    </div>
  </>
};

export default AccessGroups;