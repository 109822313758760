import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getProtocols } from "../services/ecrf.service";
import Separator from "./Separator.component";
import { DashboardFilterInterface } from "../services/DashboardFilterInterface";

const FilterBarDashboard = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const lastWeek: any = dayjs().subtract(2, "day").format("YYYY-MM-DD");
  const today: any = dayjs().add(2, "day").format("YYYY-MM-DD");
  const [dataDashboardFilter, setDataDashboardFilter] = useState({
    dateRange: { from: lastWeek, to: today },
    protocol: "",
    sites: "",
    participants: ""
  } as DashboardFilterInterface);
  const [protocols, setProtocols] = useState([] as any[]);

  const getLocalProtocols = async () => {
    await getProtocols()
      .then((data: any) => {
        return setProtocols(data.protocols);
      })
      .catch((err: any) => {
        
        console.log(err);
      });
  };

  useEffect(() => {
    setIsLoading(props.loadingDataRows || props.loadingDataRowsPaginated);
    getLocalProtocols();
  }, [props.loadingDataRows, props.loadingDataRowsPaginated]);

  const [isDateValid, setIsDateValid] = useState(false);

  useEffect(() => {
    const checkDateValidity = () => {
      const tomorrow = dayjs(dataDashboardFilter.dateRange.to);
      const from = dayjs(dataDashboardFilter.dateRange.from);
      if (tomorrow.isBefore(from)) {
        setIsDateValid(false);
        return;
      }
      setIsDateValid(true);
    };
    checkDateValidity();
  }, [dataDashboardFilter]);

  let index = 0;

  return (
    <>
      <div className="card bg-light border-0">
        <div className="card-body">
          <div className="row">
            <div className="col-2 col-md-4">
              <div className="form-group">
                <label>Protocols</label>
                <select
                  name="protocol"
                  value={dataDashboardFilter.protocol}
                  onChange={(e) =>
                    setDataDashboardFilter({
                      ...dataDashboardFilter,
                      protocol: e.target.value,
                    })
                  }
                  className="form-control"
                >
                  
                  <option value="">All</option>;
                    {protocols && protocols.length > 0 && protocols.map((protocol: any) => {
                      return (
                        <option key={`option-protocol-${index++}`} value={protocol.id}>
                          {protocol.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>From</label>
                <input
                  type="date"
                  defaultValue={dataDashboardFilter.dateRange.from}
                  disabled={isLoading}
                  name="dateRange.from"
                  onChange={(e) =>
                    setDataDashboardFilter({
                      ...dataDashboardFilter,
                      dateRange: {
                        ...dataDashboardFilter.dateRange,
                        from: e.target.value,
                      },
                    })
                  }
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>To</label>
                <input
                  type="date"
                  defaultValue={dataDashboardFilter.dateRange.to}
                  disabled={isLoading}
                  name="dateRange.to"
                  onChange={(e) =>
                    setDataDashboardFilter({
                      ...dataDashboardFilter,
                      dateRange: {
                        ...dataDashboardFilter.dateRange,
                        to: e.target.value,
                      },
                    })
                  }
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <Separator size={20} />
          <div className="card-body border">
            <p className="card-subtitle mb-2 text-muted">Fill in "Sites" and "Participants" to exclude data from results.</p>
            <p className="card-subtitle mb-2 text-muted">Separate values by comma ",".</p>
            <div className="row">
              <div className="col-4 col-md-4">
                <div className="form-group">
                  <label>Sites</label>
                  <input
                    name="sites"
                    value={dataDashboardFilter.sites}
                    onChange={(e) =>
                      setDataDashboardFilter({
                        ...dataDashboardFilter,
                        sites: e.target.value,
                      })
                    }
                    className="form-control"
                  >
                  </input>
                </div>
              </div>
              <div className="col-5 col-md-4">
                <div className="form-group">
                  <label>Participants</label>
                  <input
                    name="participants"
                    value={dataDashboardFilter.participants}
                    onChange={(e) =>
                      setDataDashboardFilter({
                        ...dataDashboardFilter,
                        participants: e.target.value,
                      })
                    }
                    className="form-control"
                  >
                  </input>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <button
                type="button"
                className="btn btn-primary mt-4"
                disabled={!isDateValid || isLoading}
                onClick={() => props.onFilterDataChange(dataDashboardFilter)}
              >
                <i className="fas fa-filter me-2"></i> Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterBarDashboard;
