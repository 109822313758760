import axios from "axios";
import { getApp } from "firebase/app";
import { getFirestore, collection, getDocs, query } from 'firebase/firestore/lite';

// export const getUsers = async () => {
//   return await axios.get(`/users`).then((res) => res.data).catch((err) => err);
// };

export const addUser = async (payload: {name: string, email: string, password: string, role: string}) => {
  return await axios.post(`/users`, payload).then((res) => res.data).catch((err) => err);
};

// This endpoint will return only the permissions
// No data need to be returned at this moment
export const getUserPermissionsOnCencal = async (payload: {email: string, cencalRole: string}) => {
  return await axios.post(`/users/permissionsOnCencal`, payload).then((res) => res.data).catch((err) => err);
};

export const editUser = async (payload: {id: string, name: string, email: string, password?: string, role: string, dynamicColumns: string}) => {
  return await axios.put(`/users/${payload.id}`, payload).then((res) => res.data).catch((err) => err);
};

export const deleteUser = async (payload: {id: string}) => {
  return await axios.delete(`/users/${payload.id}`).then((res) => res.data).catch((err) => err);
};

export const getCencalUsersFromOrgIDs = async (orgIds: string[]) => {
  return await axios.post(`/cencal/usersFromOrgs`, {orgIds}).then((res) => res.data).catch((err) => err);
};

export const getUsers = async () => { 
  const app = getApp();
  const db = getFirestore(app);
  const usersCol = collection(db, 'profiles');
  const q = query(usersCol);
  const querySnapshot = await getDocs(q);
  const users: any[] = [];
  querySnapshot.forEach((user) => {
    users.push({
      id: user.id,
      ...user.data(),
    });
  });
  return {users: users};
}