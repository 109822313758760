import { useEffect } from "react";
import { canAccess } from "../../services/helpers";
import Separator from "../../components/Separator.component";

function Lamp(props: any) {
    // Do not add any other code here
    useEffect(() => {
        if (!canAccess(props.user, 'lamp')) {
            window.location.href = '/home';
            return;
        }
    }, [props.user, props.user.role]);

    return <>
        <div className="container">
            <h2>LAMP</h2>
            <p className="text-muted">
                Not fully migrated yet.
            </p>

            <Separator size={40} />

            {props.user.email === 'lucas.moreira@aptitudemedical.com' && <>
                <iframe
                    style={{ height: "650px", width: "350px" }}
                    src="https://aptitude-test-projects.web.app/index.html"></iframe>
            </>}
        </div>
    </>
}

export default Lamp;