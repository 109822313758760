import {initializeApp} from 'firebase/app';
import {getRemoteConfig} from "firebase/remote-config";

const app: any = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_API_ID,
});

const appInit = () => app;

const firebaseRemoteConfig = getRemoteConfig(app);
firebaseRemoteConfig.settings = {
  minimumFetchIntervalMillis: 3600,
  fetchTimeoutMillis: 6000,
};

export {
  app,
  appInit,
  firebaseRemoteConfig,
};