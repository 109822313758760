import { CSSProperties, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Alert from "../Alert/Alert.component";
import Separator from "../Separator.component";
import Loading from "../Loading.component";
import {
    dataComparison,
    getAlgorithms,
} from "../../services/data.service";
import EchemPlot from "../Plot/Plot.component";
import {
    cellStyleGreen,
    cellStyleRed,
    reactDataGridFilterTypes,
    reactDataGridRenderCellStyleResult,
} from "../../services/helpers";
import {buildXlsxDataComparison} from "../../services/xlsxDataComparison";

// Using https://reactdatagrid.io/
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

import './DataComparison.component.scss';

const DataComparisonViewer = (props: any) => {
    const { selectedRows, dataOrigin } = props;

    const [alert, setAlert] = useState({type: "", message: ""});
    const [showModalDataComparison, setShowModalDataComparison] = useState(false);
    const [result, setResult] = useState([]);
    const [loadingResult, setLoadingResult] = useState(false);

    const [algorithms, setAlgorithms] = useState([]);
    const [loadingAlgorithms, setLoadingAlgorithms] = useState(false);

    const [selectedAlgorithms, setSelectedAlgorithms] = useState([] as any);

    const [selectedChannel, setSelectedChannel] = useState("CH0");

    useEffect(() => {
        _getAlgorithms();
    }, []);

    useEffect(() => {
        if (!showModalDataComparison) {
            setSelectedAlgorithms([]);
            setSelectedGridRows([]);
            setResult([]);
        }
    }, [showModalDataComparison]);

    /**
     * User can choose the algorithms to compare
     * So we need to update the grid groups and columns accordingly
     * It needs to be dynamic because we're going to add more algorithms in the future
     */
    useEffect(() => {
        _updateGridGroups();
        _updateGridColumns();
        _updateGridFilters();
    }, [selectedAlgorithms, selectedChannel]);

    const _renderCellStyleCalculations = ({ value, ...props }: any) => {
        const rowData: any = props.data;
        const calculatedResult: string = rowData[`${props.cellProps.group}_calculation`];
        let cellStyle: CSSProperties = {};

        switch (calculatedResult) {
            case "positive":
                cellStyle = cellStyleRed;
                break;

            case "negative":
                cellStyle = cellStyleGreen;
                break;
        }

        return <div style={cellStyle}>{value}</div>
    };

    const _defaultGridGroups: any = [
        { name: 'document', header: 'Document' },
        { name: 'reader', header: `Reader (${selectedChannel})` },
    ];

    const [gridGroups, setGridGroups] = useState([] as any);

    const _defaultGridFilters: any = [
        { name: 'docId', type: 'string', operator: 'startsWith', value: '', },
        { name: 'start_time', type: 'string', operator: 'startsWith', value: '', },
        { name: 'reader_info_name', type: 'string', operator: 'startsWith', value: '', },
        { name: 'filename', type: 'string', operator: 'startsWith', value: '', },
        { name: 'reader_id', type: 'string', operator: 'startsWith', value: '', },
        { name: 'summaryResults', type: 'string', operator: 'contains', value: '', },
        { name: 'channelCT', type: 'number', operator: 'gte', value: null, },
        { name: 'channelPR', type: 'number', operator: 'gte', value: null, },
        { name: 'channelSD', type: 'number', operator: 'gte', value: null, },
        { name: 'channelUserMarkedAs', type: 'channel', operator: 'eq', value: null, },
        { name: 'channelReaderCalling', type: 'channel', operator: 'eq', value: null, },
    ];

    const [gridFilters, setGridFilters] = useState(_defaultGridFilters as any);

    const _defaultGridColumns: any = [
        // Data from selected document
        {
            name: "docId",
            header: "docId",
            minWidth: 270,
            defaultFlex: 1,
            group: "document",
        },
        {
            name: "start_time",
            header: "start_time",
            minWidth: 180,
            defaultFlex: 1,
            group: "document",
        },
        {
            name: "reader_info_name",
            header: "reader_info_name",
            minWidth: 200,
            defaultFlex: 1,
            group: "document",
        },
        {
            name: "filename",
            header: "filename",
            minWidth: 270,
            defaultFlex: 1,
            group: "document",
        },
        {
            name: "reader_id",
            header: "reader_id",
            minWidth: 150,
            defaultFlex: 1,
            group: "document",
        },
        {
            name: "summaryResults",
            header: "Summary",
            minWidth: 110,
            defaultFlex: 1,
            group: "document",
        },

        // Data from selected channel
        {
            name: "channelUserMarkedAs",
            header: "UserMarkedAs",
            minWidth: 145,
            defaultFlex: 1,
            group: "document",
            render: reactDataGridRenderCellStyleResult,
        },
        {
            name: "channelCT",
            header: "CT",
            minWidth: 100,
            defaultFlex: 1,
            group: "reader",
        },
        {
            name: "channelPR",
            header: "PR",
            minWidth: 100,
            defaultFlex: 1,
            group: "reader",
        },
        {
            name: "channelSD",
            header: "SD",
            minWidth: 100,
            defaultFlex: 1,
            group: "reader",
        },
        {
            name: "channelReaderCalling",
            header: "ReaderCalling",
            minWidth: 145,
            defaultFlex: 1,
            group: "reader",
            render: reactDataGridRenderCellStyleResult,
        },
    ];

    const [gridColumns, setGridColumns] = useState([] as any);

    // 
    // Update grid groups, columns, and filters dynamically based on selected algorithms
    // 
    const _updateGridGroups = () => {
        selectedAlgorithms.forEach((algorithm: any) => {
            _defaultGridGroups.push({ name: algorithm, header: `Algorithm: ${algorithm}` });
        });
        setGridGroups(_defaultGridGroups);
    };

    const _updateGridColumns = () => {
        selectedAlgorithms.forEach((algorithm: any) => {
            _defaultGridColumns.push({
                name: `${algorithm}_CT`,
                header: "CT",
                minWidth: 200,
                defaultFlex: 1,
                group: algorithm,
                render: _renderCellStyleCalculations,
            });
            _defaultGridColumns.push({
                name: `${algorithm}_PR`,
                header: "PR",
                minWidth: 200,
                defaultFlex: 1,
                group: algorithm,
                render: _renderCellStyleCalculations,
            });
            _defaultGridColumns.push({
                name: `${algorithm}_SD`,
                header: "SD",
                minWidth: 200,
                defaultFlex: 1,
                group: algorithm,
                render: _renderCellStyleCalculations,
            });
        });
        setGridColumns(_defaultGridColumns);
    };

    const _updateGridFilters = () => {
        const _filterValue: any = [];
        selectedAlgorithms.forEach((algorithm: any) => {
            _filterValue.push({ name: `${algorithm}_CT`, type: 'number', operator: 'gte', value: null, });
            _filterValue.push({ name: `${algorithm}_PR`, type: 'number', operator: 'gte', value: null, });
            _filterValue.push({ name: `${algorithm}_SD`, type: 'number', operator: 'gte', value: null, });
        });
        setGridFilters([..._defaultGridFilters, ..._filterValue]);
    };

    const gridStyle = { minHeight: 680 };

    const _getAlgorithms = async () => {
        setLoadingAlgorithms(true);
        await getAlgorithms().then((response: any) => {
            if (response.message === "OK") {
                setAlgorithms(response.algorithms);

                // Create default thresholds based on all algorithms available
                // When the user selects the algorithms, we already have the thresholds
                _createDefaultThresholds(response.algorithms);
                setLoadingAlgorithms(false);
                return;
            }

            setAlert({type: "danger", message: response.message});
            console.log('Error getAlgorithms', response);
            setLoadingAlgorithms(false);
        }).catch((err: any) => {
            console.log("ERROR", err);
            setAlert({type: "danger", message: err.message});
            setLoadingAlgorithms(false);
        });
    };

    const changeAlgorithmSelection = (e: any) => {
        if (e.target.checked) {
            setSelectedAlgorithms([...selectedAlgorithms, e.target.value]);
            return;
        }
        setSelectedAlgorithms(selectedAlgorithms.filter((item: any) => item !== e.target.value));
    };

    // Per each row, use features CT, PR, and SD to calculate results based on given thresholds
    // ReactDataGrid can change the cell color based on its own value but we need to change the color based on other values
    // So we're using this function to calculate the result and append it to the row in the format ${algorithm}_calculation
    // Then we use _renderCellStyleCalculations to just change the cell color based on this group value
    const _calculateResults = (rows: any) => {
        console.group("Calculate results based on thresholds");
        console.log("all thresholds", thresholds);
        rows.forEach((row: any) => {
            const keys: any = Object.keys(row);
            const algorithmKeys = keys.filter((key: string) => selectedAlgorithms.some((algorithm: string) => key.startsWith(algorithm)));

            algorithmKeys.forEach((key: string) => {
                const algorithm: string = key.split("_").slice(0, -1).join("_");
                const threshold: any = thresholds.find((item: any) => item.algorithm === algorithm);

                const rowCT: number = parseFloat(row[`${algorithm}_CT`]);
                const rowPR: number = parseFloat(row[`${algorithm}_PR`]);
                const rowSD: number = parseFloat(row[`${algorithm}_SD`]);

                // In the HTML select element is not possible to use the real operator (>=, <=, etc.)
                // I'm using "ge" (greater than or equal to) or "le" (less than or equal to)
                // We need to convert it (ge to >= and le to <=) to the real operator to use in the eval function
                const realOperatorCT: string = threshold["CT_operator"] === "ge" ? ">=" : "<=";
                const realOperatorPR: string = threshold["PR_operator"] === "ge" ? ">=" : "<=";
                const realOperatorSD: string = threshold["SD_operator"] === "ge" ? ">=" : "<=";

                const tCT: number = parseFloat(threshold.CT);
                const tPR: number = parseFloat(threshold.PR);
                const tSD: number = parseFloat(threshold.SD);

                // Do the calculation and append the result to the row
                // If the result is positive, the cell color will be red, otherwise, it will be green
                // eg: row[`${algorithm}_calculation`] = "positive" or "negative"
                // eg: CT op 8 && PR op 0 && SD op 0, where op is >= or <=

                console.log(`${rowCT} ${realOperatorCT} ${tCT} && ${rowPR} ${realOperatorPR} ${tPR} && ${rowSD} ${realOperatorSD} ${tSD}`, eval(`${rowCT} ${realOperatorCT} ${tCT} && ${rowPR} ${realOperatorPR} ${tPR} && ${rowSD} ${realOperatorSD} ${tSD}`));

                // IF condition1 && condition2 && condition3 ARE ALL TRUE, THEN positive
                // IF any of the conditions is false, THEN negative
                if (eval(`${rowCT} ${realOperatorCT} ${tCT} && ${rowPR} ${realOperatorPR} ${tPR} && ${rowSD} ${realOperatorSD} ${tSD}`)) {
                    row[`${algorithm}_calculation`] = "positive";
                } else {
                    row[`${algorithm}_calculation`] = "negative";
                }
            });
        });
        console.groupEnd();

        // Create a new columns with a summary of results (userMarkedAs, readerCalling, algorithmResults) based on the results of each algorithm
        // Converting positive, negative, or undefined to +, -, or * respectively
        rows.forEach((row: any) => {
            row["summaryResults"] = _calculateSummaryResults(row);
        });

        setResult(rows);
    };

    const _calculateSummaryResults = (row: any): string => {
        const signs: any = {
            "undefined": "",
            "-1": "*",
            "0": "-",
            "1": "+",
            "positive": "+",
            "negative": "-",
        };

        const signUserMarkedAs: any = signs[row["channelUserMarkedAs"]];
        const signReaderCalling: any = signs[row["channelReaderCalling"]];

        let response: string = `${signUserMarkedAs}${signReaderCalling}`;

        selectedAlgorithms.forEach((algorithm: string) => {
            const algorithmSign: any = signs[row[`${algorithm}_calculation`]];
            response = `${response}${algorithmSign}`;
        });

        return response;
    };

    const compareSelectedRows = async () => {
        setLoadingResult(true);
        setResult([]);

        const idsToCompare: string[] = selectedRows.map((row: any) => row.id);

        const payload: any = {
            idsToCompare,
            dataOrigin,
            selectedAlgorithms,
            selectedChannel: [selectedChannel],
        };

        await dataComparison(payload).then((response: any) => {
            if (response.message === "OK") {
                setResult(response.data);

                // When rows come from the backend, we need to calculate the results based on the thresholds
                _calculateResults(response.data);
                setLoadingResult(false);
                return;
            }

            setAlert({type: "danger", message: response.message});
            console.log('Error getLogsOfRowId', response);
            setLoadingResult(false);
        }).catch((err: any) => {
            console.log("ERROR", err);
            setAlert({type: "danger", message: err.message});
            setLoadingResult(false);
        });
    };

    // Create default thresholds based on the algorithms
    const _createDefaultThresholds = (algorithms: any[]) => {
        const newThresholds: any = [];
        algorithms.forEach((algorithm: any) => {
            newThresholds.push({
                algorithm: algorithm.name,
                CT: 20,
                CT_operator: "le",

                PR: 0.4,
                PR_operator: "ge",

                SD: 0.1,
                SD_operator: "ge",
            });
        });
        setThresholds(newThresholds);
    };

    const [thresholds, setThresholds] = useState([] as any);

    const [selectedGridRows, setSelectedGridRows] = useState([] as any);

    const [rowsToChart, setRowsToChart] = useState([] as any);

    // Use the selectedGridRows to plot chart
    // selectedRows comes from the parent component to be used in the grid comparison
    // Inside the grid, the user can select the rows to be plotted
    useEffect(() => {
        if (selectedGridRows.length > 0) {
            const _rowsToChart: any = [];
            selectedGridRows.forEach((selectedRow: any) => {
                const foundRow: any = selectedRows.find((row: any) => row.id === selectedRow.docId);
                _rowsToChart.push(foundRow);
            });
            setRowsToChart(_rowsToChart);
        }
    }, [selectedGridRows]);

    const _getXlsxDataComparison = () => {
      if (result.length === 0) {
        return;
      }

      const date: any = new Date();
      const currentMonth: any = (date.getMonth() + 1).toString().padStart(2, '0');
      const xlsxFileName: string = `data-comparison ${date.getFullYear()}-${currentMonth}-${date.getDate()}.xlsx` 

      buildXlsxDataComparison({data: selectedGridRows, selectedAlgorithms, selectedChannel }).then((res: any) => {
        const blob: any = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link: any = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = xlsxFileName;
        link.click();
      });
    };

    return <>
        <Alert alert={alert} />

        <div className="DataComparisonViewer">
            <div className="d-grid">
                <button
                type='button'
                disabled={!selectedRows || selectedRows.length === 0}
                onClick={() => setShowModalDataComparison(true)}
                className='btn btn-outline-secondary'>Compare</button>
            </div>

            <Modal show={showModalDataComparison} size="xl" dialogClassName="w-98">
                <Modal.Header closeButton onClick={() => setShowModalDataComparison(false)}>
                    <Modal.Title>Data Comparison</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-center">
                        <div className="col-1 pe-0">
                            <label>Select a channel</label>
                        </div>
                        <div className="col col-12 col-md-2">
                            <div className="form-group">
                                <select
                                    name="selectedChannel"
                                    onChange={(e) => setSelectedChannel(e.target.value)}
                                    value={selectedChannel}
                                    className="form-control">
                                        {["CH0", "CH1", "CH2", "CH3", "CH4", "CH5", "CH6", "CH7"].map((item: string) => {
                                            return <option key={`channel-${item}`} value={item}>{item}</option>
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>

                    <Separator size={30} />

                    Select algorithms to compare

                    <Separator size={10} />

                    <Loading loading={loadingAlgorithms} />

                    {!loadingAlgorithms && algorithms.length > 0 && <>
                        <div className="row">
                            {algorithms.map((algorithm: any, index: number) => {
                                return <div className="col col-6 col-md-3 mb-3" key={`select-algorithm-${index}`}>
                                    <div className="card bg-light border-0">
                                        <div className="card-body">

                                            <div className="form-check form-switch">
                                                <label className="form-check-label cursor-pointer">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        disabled={loadingResult || !selectedRows || selectedRows.length === 0 || loadingAlgorithms}
                                                        onChange={changeAlgorithmSelection}
                                                        value={algorithm.name} />
                                                            {algorithm.name}
                                                </label>
                                            </div>

                                            {selectedAlgorithms.includes(algorithm.name) && <>
                                                <Separator size={10} />

                                                <div className="row align-items-center mb-2">
                                                    <div className="col-2">
                                                        <small>CT</small>
                                                    </div>
                                                    <div className="col-2">
                                                        <select
                                                            disabled={loadingResult || !selectedRows || selectedAlgorithms.length === 0 || selectedRows.length === 0 || loadingAlgorithms || algorithms.length === 0}
                                                            value={thresholds.find((item: any) => item.algorithm === algorithm.name)?.CT_operator}
                                                            onChange={(e) => {
                                                                const newThresholds = thresholds.map((item: any) => {
                                                                    if (item.algorithm === algorithm.name) {
                                                                        item.CT_operator = e.target.value;
                                                                    }
                                                                    return item;
                                                                });
                                                                setThresholds(newThresholds);
                                                            }}
                                                            className="form-control form-control-sm">
                                                                <option value="ge">&ge;</option>
                                                                <option value="le">&le;</option>
                                                        </select>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            disabled={loadingResult || !selectedRows || selectedAlgorithms.length === 0 || selectedRows.length === 0 || loadingAlgorithms || algorithms.length === 0}
                                                            type="number"
                                                            className="form-control form-control-sm"
                                                            placeholder="CT"
                                                            value={thresholds.find((item: any) => item.algorithm === algorithm.name)?.CT}
                                                            onChange={(e) => {
                                                                const newThresholds = thresholds.map((item: any) => {
                                                                    if (item.algorithm === algorithm.name) {
                                                                        item.CT = +e.target.value;
                                                                    }
                                                                    return item;
                                                                });
                                                                setThresholds(newThresholds);
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row align-items-center mb-2">
                                                    <div className="col-2">
                                                        <small>PR</small>
                                                    </div>
                                                    <div className="col-2">
                                                        <select
                                                            disabled={loadingResult || !selectedRows || selectedAlgorithms.length === 0 || selectedRows.length === 0 || loadingAlgorithms || algorithms.length === 0}
                                                            onChange={(e) => {
                                                                const newThresholds = thresholds.map((item: any) => {
                                                                    if (item.algorithm === algorithm.name) {
                                                                        item.PR_operator = e.target.value;
                                                                    }
                                                                    return item;
                                                                });
                                                                setThresholds(newThresholds);
                                                            }}
                                                            className="form-control form-control-sm">
                                                                <option value="ge">&ge;</option>
                                                                <option value="le">&le;</option>
                                                        </select>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            type="number"
                                                            disabled={loadingResult || !selectedRows || selectedAlgorithms.length === 0 || selectedRows.length === 0 || loadingAlgorithms || algorithms.length === 0}
                                                            className="form-control form-control-sm"
                                                            placeholder="PR"
                                                            value={thresholds.find((item: any) => item.algorithm === algorithm.name)?.PR}
                                                            onChange={(e) => {
                                                                const newThresholds = thresholds.map((item: any) => {
                                                                    if (item.algorithm === algorithm.name) {
                                                                        item.PR = +e.target.value;
                                                                    }
                                                                    return item;
                                                                });
                                                                setThresholds(newThresholds);
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row align-items-center">
                                                    <div className="col-2">
                                                        <small>SD</small>
                                                    </div>
                                                    <div className="col-2">
                                                        <select
                                                            disabled={loadingResult || !selectedRows || selectedAlgorithms.length === 0 || selectedRows.length === 0 || loadingAlgorithms || algorithms.length === 0}
                                                            onChange={(e) => {
                                                                const newThresholds = thresholds.map((item: any) => {
                                                                    if (item.algorithm === algorithm.name) {
                                                                        item.SD_operator = e.target.value;
                                                                    }
                                                                    return item;
                                                                });
                                                                setThresholds(newThresholds);
                                                            }}
                                                            className="form-control form-control-sm">
                                                                <option value="ge">&ge;</option>
                                                                <option value="le">&le;</option>
                                                        </select>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            disabled={loadingResult || !selectedRows || selectedAlgorithms.length === 0 || selectedRows.length === 0 || loadingAlgorithms || algorithms.length === 0}
                                                            type="number"
                                                            className="form-control form-control-sm"
                                                            placeholder="SD"
                                                            value={thresholds.find((item: any) => item.algorithm === algorithm.name)?.SD}
                                                            onChange={(e) => {
                                                                const newThresholds = thresholds.map((item: any) => {
                                                                    if (item.algorithm === algorithm.name) {
                                                                        item.SD = +e.target.value;
                                                                    }
                                                                    return item;
                                                                });
                                                                setThresholds(newThresholds);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </>}

                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>

                        <Separator size={10} />

                        <small className="text-muted">
                            &ge; greater than or equal to<br />
                            &le; less than or equal to
                        </small>
                    </>}

                    <Separator size={20} />

                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={loadingResult || !selectedRows || selectedAlgorithms.length === 0 || selectedRows.length === 0 || loadingAlgorithms || algorithms.length === 0}
                      onClick={() => compareSelectedRows()}>
                        <Loading loading={loadingResult} parent="inline" color="text-white" />
                        {!loadingResult && <i className="fas fa-check me-2"></i>}
                        Compare {selectedRows.length} Selected Rows
                    </button>

                    <button
                      type="button"
                      className="btn btn-outline-primary ms-2"
                      disabled={loadingResult || !selectedRows || selectedGridRows.length === 0 || result.length === 0 || selectedAlgorithms.length === 0 || selectedRows.length === 0 || loadingAlgorithms || algorithms.length === 0}
                      onClick={() => _getXlsxDataComparison()}>
                        <i className="fas fa-download me-2"></i> Download Excel
                    </button>

                    <Loading loading={loadingResult} />

                    <Separator size={20} />

                    {selectedRows && !loadingResult && result.length > 0 && <>
                        <div style={{ position: 'relative', zIndex: '9999999' }}>
                            <ReactDataGrid
                                idProperty="docId"
                                columns={gridColumns}
                                groups={gridGroups}
                                dataSource={result}
                                style={gridStyle}
                                defaultFilterValue={gridFilters}
                                filterTypes={reactDataGridFilterTypes}
                                editable
                                enableSelection
                                multiSelect
                                onSelectionChange={(selection: any) => {
                                    const _selectedRows: any[] = [];
                                    for (const [, value] of Object.entries(selection.selected)) {
                                        _selectedRows.push(value);
                                    }
                                    setSelectedGridRows(_selectedRows);
                                }}
                            />
                        </div>

                        <Separator size={10} />

                        <small className="text-muted">
                            {selectedGridRows.length} rows selected of {selectedRows.length} rows displayed
                        </small>

                        {selectedGridRows.length > 0 && <>
                            <Separator size={20} />

                            <EchemPlot selectedRows={rowsToChart} />
                        </>}
                    </>}

                    <Separator size={20} />

                    <button type="button" className="btn btn-outline-primary" onClick={() => setShowModalDataComparison(false)}>
                        Close
                    </button>
                </Modal.Body>
            </Modal>

        </div>
    </>
};

export default DataComparisonViewer;