import React from "react";
import { useEffect, useState } from "react";
import Separator from "../Separator.component";
import { getStiMessages, markMessageAsComplete, sendMessage } from "../../services/sti.service";
import Loading from "../Loading.component";
import { useFormik } from "formik";
import { getCencalUsersFromOrgIDs } from "../../services/users.service";
import * as yup from 'yup';
import Alert from "../Alert/Alert.component";
import dayjs from "dayjs";
import { convertFirebaseTimestampToString } from "../../services/helpers";
import { getUserPermissionsOnCencal } from "../../services/users.service";

const Messages = (props: any) => {
  const {
    user,
    patientDocId,
    patient,
  } = props;

  useEffect(() => {
    _getMessages();
    _getUsers();
  }, []);

  const [users, setUsers] = useState([] as any[]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const _getUsers = async () => {
    setLoadingUsers(true);
    const orgIds: string[] = [];

    /**
     * Get all orgs that the user has access to based on his cencalRole(echem)+orgId(ecrf)
     */
    await getUserPermissionsOnCencal({email: user.email, cencalRole: user.cencalRole}).then((response: any) => {
      if (response.message === "OK") {
        if (response.data.allOrgs && response.data.allOrgs.length > 0) {
          response.data.allOrgs.map((org: any) => org.id && orgIds.push(org.id));
        }
        return;
      }

      console.log("ERROR getUserPermissionsOnCencal >>> ", response);
      setAlert({ type: 'danger', message: 'Error fetching permissions' });
      setLoadingUsers(false);
    }).catch((error: any) => {
      console.log("ERROR", error);
      setAlert({ type: 'danger', message: 'Error fetching permissions' });
      setLoadingUsers(false);
    });

    /**
     * Get all users from the orgs that the user has access to
     * This users will be used to populate the dropdown list
     */
    await getCencalUsersFromOrgIDs(orgIds).then((response: any) => {
      if (response.users && response.users.length > 0) {
        response.users.sort((a: any, b: any) => a.name.localeCompare(b.name));
        setUsers(response.users);
        setLoadingUsers(false);
        return;
      }

      setAlert({ type: 'danger', message: 'Error fetching users' });
      console.log("Response", response);
      setLoadingUsers(false);
    }).catch((err: any) => {
      console.log("Error", err);
      setAlert({ type: 'danger', message: 'Error fetching users' });
      setLoadingUsers(false);
    });
  }

  const [alert, setAlert] = useState({ type: '', message: '' });
  const [messages, setMessages] = useState([] as any[]);

  const [loadingMessages, setLoadingMessages] = useState(false);

  const _getMessages = async () => {
    setLoadingMessages(true);
    await getStiMessages(patientDocId).then((response: any) => {
      if (response.message === "OK") {
        setMessages(response.messages);
        setLoadingMessages(false);
        return;
      }

      console.log("Response", response);
      setAlert({ type: 'danger', message: 'Error fetching messages' });
    }).catch((err: any) => {
      console.log("Error", err);
      setLoadingMessages(false);
      setAlert({ type: 'danger', message: 'Error fetching messages' });
    });
  };

  const _markMessageAsComplete = async (id: string) => {
    await markMessageAsComplete(id).then((response: any) => {
      if (response.message === "OK") {
        setAlert({ type: 'success', message: 'Message marked as complete' })
        setMessages(messages.map((message: any) => {
          if (message.id === id) {
            message.isComplete = true;
            message.completedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
          }
          return message;
        }));
        return;
      }

      setAlert({ type: 'danger', message: 'Error marking message as complete' });
      console.log("Response", response);
    }).catch((err: any) => {
      console.log("Error", err);
      setAlert({ type: 'danger', message: 'Error marking message as complete' });
    });
  };

  const [loadingSendMessage, setLoadingSendMessage] = useState(false);

  const formSendMessage: any = useFormik({
    initialValues: {
      patientDocId,
      message: '',
      to: '',

      patientIdComposite: patient.patientIdComposite,
      name: patient.name,
      protocol: patient.protocol,
    },
    validationSchema: yup.object({
      message: yup.string().required('Message is required'),
      to: yup.string().required('To is required'),
    }),
    isInitialValid: false,
    onSubmit: async (values) => {
      setLoadingSendMessage(true);

      const payload: any = {
        patientDocId: patientDocId,
        message: values.message,
        to: values.to,

        patientIdComposite: patient.patientIdComposite,
        name: patient.name,
        protocol: patient.protocol,
      };

      await sendMessage(payload).then((response: any) => {
        if (response.message === "OK") {
          setAlert({ type: 'success', message: 'Message sent' });
          formSendMessage.resetForm();
          _getMessages();
          setLoadingSendMessage(false);
          return;
        }

        setAlert({ type: 'danger', message: 'Error sending message' });
        console.log("Response", response);
        setLoadingSendMessage(false);
      }).catch((err: any) => {
        console.log("Error", err);
        setAlert({ type: 'danger', message: 'Error sending message' });
        setLoadingSendMessage(false);
      });
    }
  });

  return <>
    <Alert alert={alert} />

    <div className="Messages">
      <div className="row">
        <div className="col">

          <form onSubmit={formSendMessage.handleSubmit}>
            <textarea
              className="form-control"
              placeholder="Type a message"
              name="message"
              disabled={loadingSendMessage}
              onChange={formSendMessage.handleChange}
              value={formSendMessage.values.message}
              rows={3}></textarea>

            <Separator size={10} />

            <select
              name="to"
              disabled={loadingSendMessage || users.length === 0 || loadingUsers}
              onChange={formSendMessage.handleChange}
              value={formSendMessage.values.to}
              className="form-select">
                <option value="">Select a user</option>
                {users.map((item: any, index: number) => {
                  return <option
                    key={`users-list-${index}`}
                    value={item.id}>{item.name} ({item.email}) {item.id === user.uid && <>(you)</>}</option>
                })};
            </select>

            {formSendMessage.values.to === user.uid && <>
              <Separator size={10} />
              <small className="text-muted">
                Messages to yourself will not be sent via email.
              </small>
            </>}

            <Separator size={10} />

            <button
              type="submit"
              disabled={!formSendMessage.isValid || loadingSendMessage}
              className="btn btn-primary">
                <Loading loading={loadingSendMessage} parent="inline" color="text-white" />
                {!loadingSendMessage && <i className="fas fa-check me-2"></i>} Send Message
            </button>
          </form>

        </div>
        <div className="col">

          <Loading loading={loadingMessages} />

          {!loadingMessages && <>
            {messages.length === 0 && <strong>No messages found</strong>}

            {messages.length > 0 && <>

              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pending-tab" data-bs-toggle="tab" data-bs-target="#pending-tab-pane" type="button" role="tab" aria-controls="pending-tab-pane" aria-selected="true">Pending ({messages.length > 0 && messages.filter((item: any) => item.isComplete === false).length})</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="completed-tab" data-bs-toggle="tab" data-bs-target="#completed-tab-pane" type="button" role="tab" aria-controls="completed-tab-pane" aria-selected="false">Completed ({messages.length > 0 && messages.filter((item: any) => item.isComplete === true).length})</button>
                </li>
              </ul>

              <Separator size={30} />

              <div className="tab-content" id="myTabContent">
                <div className="tab-pane show active" id="pending-tab-pane" role="tabpanel" aria-labelledby="pending-tab">

                  {messages.length > 0 && <>
                    {messages.map((message: any, index: number) => {
                      return message.isComplete === false && <>
                        <div key={`messages-pending-${index}-${message.id}-${patient.id}`} className="card mb-3">
                          <div className="card-body">
                            {message.message}

                            <Separator size={10} />

                            <small className="text-muted">
                              <div className="row">
                                <div className="col">
                                  {message.from.name} &rarr; {message.to.name}
                                </div>
                                <div className="col text-end">

                                  <div
                                    className="text-primary cursor-pointer"
                                    onClick={() => _markMessageAsComplete(message.id)}>
                                      mark as complete
                                  </div>

                                </div>
                              </div>

                            </small>
                          </div>
                        </div>
                      </>
                    })}
                  </>}

                </div>
                <div className="tab-pane" id="completed-tab-pane" role="tabpanel" aria-labelledby="completed-tab">

                  {messages.length > 0 && <>
                    {messages.map((message: any, index: number) => {
                      return <React.Fragment key={`messages-complete-${index}-${message.id}-${patient.id}`}>
                        {message.isComplete === true && <>
                          <div key={`messages-complete-${index}-${message.id}-${patient.id}`} className={`card mb-3 ${index}-${message.id}-${patient.id}`}>
                            <div className="card-body">
                              {message.message}

                              <Separator size={10} />

                              <small className="text-muted">
                                <div className="row">
                                  <div className="col">
                                    {message.from.name} &rarr; {message.to.name}
                                  </div>
                                  <div className="col text-end">

                                    completed at {message.completedAt && message.completedAt._seconds ? convertFirebaseTimestampToString(message.completedAt, "MM/DD/YYYY HH:mm") : dayjs(message.completedAt).format("MM/DD/YYYY HH:mm") || "N/A"}

                                  </div>
                                </div>

                              </small>
                            </div>
                          </div>
                        </>}
                      </React.Fragment>
                    })}
                  </>}

                </div>
              </div>

            </>}
          </>}

        </div>
      </div>
    </div>
  </>
};

export default Messages;