import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { batchUpdateDocument } from "../../services/batchUpdate.service";
import Alert from "../Alert/Alert.component";
import Separator from "../Separator.component";
import Loading from "../Loading.component";
import DropDownColumnsInputNewValue from "./DropDownColumnsInputNewValue.component";

const DataBatchEdit = (props: any) => {
  const [loadingBatchDataEdit, setLoadingBatchDataEdit] =
    useState<boolean>(false);
  const [alert, setAlert] = useState<any>({ type: "", message: "" });

  const batchDataEditInitialValues: any = { column: "", newValue: "" };

  const notEditableColumns = ["id", "dataPoints", "createdAt", "results"];

  const newColumnsToParse: [] = props.dynamicColumns
    .filter(
      (item: { header: string; name: string }) =>
        !notEditableColumns.includes(item.name as never)
    )
    .map((item: any) => {
      return { [item.header]: item.name };
    });

  const _formatReaderCallingColumnName = (column: string) => {
    const columnArr = column.split("-");
    const channelPrefix = `CH${
      parseInt(columnArr[0].replace("CH", ""), 10) + 1
    }`;
    const fixedColumnName = `${channelPrefix}-${columnArr[1]}`;
    const columnName = newColumnsToParse.find(
      (item: any) => Object.keys(item)[0] === fixedColumnName
    );
    const columnNameToUpdateArray: string[] = (
      Object.values(columnName as any)[0] as string
    ).split(".");
    return `${
      columnNameToUpdateArray[0]
    }.${columnNameToUpdateArray[1].toLowerCase()}`;
  };

  const _parseBatchDataEditColumn = (column: any) => {
    if (column.includes("userMarkedAs") || column.includes("label"))
      return column.replace("-", ".");
    if (column.includes("ReaderCalling"))
      return _formatReaderCallingColumnName(column);
    return column;
  };

  const _setTypeOfColumn = (column: string, newValue: any): any => {
    if (column.includes("userMarkedAs") || column.includes("isReaderData"))
      return parseInt(newValue, 10);
    return newValue;
  };

  const batchDataEditFormik = useFormik({
    initialValues: batchDataEditInitialValues,
    // isInitialValid: false,
    validationSchema: yup.object({
      column: yup.string().required("Column is required"),
      newValue: yup.string().optional().default(""),
    }),
    onSubmit: async (values: any) => {
      const rowsToUpdate: any[] = [];
      setLoadingBatchDataEdit(true);
      props.selectedRows.forEach((item: any) => {
        rowsToUpdate.push(item.id);
      });
      const body: any = {
        collection: props.dataOrigin || "usb_data",
        rows: rowsToUpdate,
        column: _parseBatchDataEditColumn(values.column),
        newValue: _setTypeOfColumn(values.column, values.newValue),
      };
      batchUpdateDocument(body)
        .then((res: any) => {
          if (res.message === "OK") {
            props.setShowModalDataEdit(false);
            setAlert({
              type: "success",
              message: `${rowsToUpdate.length} rows updated successfully`,
            });
            const _rows = [...props.dataRows];
            rowsToUpdate.forEach((itemRowToUpdate: string) => {
              _rows.forEach((item: any) => {
                if (item.id === itemRowToUpdate) {
                  item[values.column] = values.newValue;
                }
              });
            });
            batchDataEditFormik.resetForm();
            props.setDataRows(_rows);
            // console.log("Data updated", _rows);
          } else {
            setAlert({ type: "danger", message: "Error updating readers" });
          }
          setLoadingBatchDataEdit(false);
        })
        .catch((err: any) => {
          console.log("Error on batch update", err);
        })
        .finally(() => {
          batchDataEditFormik.resetForm();
        });
    },
  });

  const _getTableRowChanges = (reader: any) => {
    const oldValue = reader[batchDataEditFormik.values.column] || "";
    const newValue = batchDataEditFormik.values.newValue;
    return (
      <>
        <span className="batch-edit-old-value me-1">
          {oldValue.toString() || <>&nbsp;</>}
        </span>
        &rarr;
        <span className="batch-edit-new-value ms-1">{newValue}</span>
      </>
    );
  };

  return (
    <>
      <Alert alert={alert} />
      <Modal show={props.showModalDataEdit} size="lg">
        <Modal.Header
          closeButton
          onClick={() => props.setShowModalDataEdit(false)}
        >
          <Modal.Title>Data Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              batchDataEditFormik.handleSubmit();
            }}
          >
            <div className="row">
              <DropDownColumnsInputNewValue
                newValue={batchDataEditFormik.values.newValue}
                setFieldValue={batchDataEditFormik.setFieldValue}
                column={batchDataEditFormik.values.column}
                newColumnsToParse={newColumnsToParse}
                handleChange={batchDataEditFormik.handleChange}
                loadingBatchDataEdit={loadingBatchDataEdit}
                resetForm={batchDataEditFormik.resetForm}
              />
            </div>

            {batchDataEditFormik.isValid && (
              <>
                <Separator size={20} />
                Check the changes before confirm:
                <Separator size={10} />
                <table className="table table-sm table-bordered">
                  <tbody>
                    {props.selectedRows.length > 0 &&
                      props.selectedRows.map((reader: any, index: number) => {
                        return (
                          <tr key={`tr-preview-reader-updates-${index}`}>
                            <td>{reader.id}</td>
                            <td>{_getTableRowChanges(reader)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </>
            )}

            <Separator size={20} />

            <button
              disabled={
                loadingBatchDataEdit ||
                !batchDataEditFormik.isValid ||
                !props.dataOrigin ||
                props.dataOrigin === ""
              }
              type="submit"
              className="btn btn-primary"
            >
              <Loading
                loading={loadingBatchDataEdit}
                parent="inline"
                color="text-white"
              />
              {!loadingBatchDataEdit && <i className="fas fa-check me-2"></i>}{" "}
              Update {props.selectedRows.length} Rows on "{props.dataOrigin}"
            </button>

            <button
              type="button"
              className="btn btn-outline-primary ms-2"
              onClick={() => {
                props.setShowModalDataEdit(false);
                setLoadingBatchDataEdit(false);
                batchDataEditFormik.resetForm();
              }}
            >
              Close
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DataBatchEdit;
