import { useState } from "react";
import { getUserProfile } from "../../services/auth.service";
import Loading from "../../components/Loading.component";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Link } from "react-router-dom";

function Login() {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const _getUserDataFromFirebaseLogin = (data: any): any => {
        return {
            email: data.user.email,
            emailVerified: data.user.emailVerified,
            providerId: data.user.providerId,
            uid: data.user.uid,
        };
    }

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        setLoading(true);
        setError('');
        e.preventDefault();
        const auth = getAuth();
        const signIn: any = await signInWithEmailAndPassword(auth, email, password).then((result: any) => result).catch((error: any) => error);
        if (!signIn.user) {
            console.log('signIn error', signIn);
            setError(signIn.message);
            setLoading(false);
            return;
        }
        const user: any = _getUserDataFromFirebaseLogin(signIn);
        const profile: any = await getUserProfile(user.uid).then((response: any) => response.user);

        localStorage.setItem('user', JSON.stringify({
            ...user,
            ...profile,
        }));
        setLoading(false);
        window.location.href = '/home';
    }

    return (
        <div className="container mt-4">
            <div className="row justify-content-center">
                <div className="col-12 col-md-4">

                    <div className="card shadow border-0">
                        <div className="card-body">
                            <div className="mb-4">
                                <strong>Login</strong>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-2">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        data-testid="email"
                                        id="email"
                                        disabled={loading}
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        data-testid="password"
                                        id="password"
                                        disabled={loading}
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>

                                {error && <div className="alert alert-danger mt-3 mb-3" role="alert">{error}</div>}

                                <button
                                    disabled={!email || !password || loading}
                                    type="submit" className="btn btn-primary" data-testid="signin">
                                        <Loading loading={loading} parent="inline" color="text-white" /> 
                                        {!loading && <> <i className="fas fa-sign-in-alt me-2"></i></>} Sign In
                                    </button>
                            </form>
                        </div>
                    </div>

                    <div className="text-center mt-4">
                        <Link to="/passwordRecover" className="btn btn-outline-primary">Recover Password</Link>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Login;