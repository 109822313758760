import { useEffect, useState } from "react";
import { getPendingEchemQueueItems } from "../../services/data.service";
import Loading from "../Loading.component";
import Separator from "../Separator.component";

import "./EchemQueueViewer.component.scss";

const EchemQueueViewer = () => {
    useEffect(() => {
        _getPendingItems(true);
    }, []);

    const [loadingDocuments, setLoadingDocuments] = useState(true);
    const [loadingDocumentsButton, setLoadingDocumentsButton] = useState(true);
    const [documents, setDocuments] = useState([] as any);

    const _getPendingItems = async (showLoading: boolean = false) => {
        setLoadingDocuments(showLoading);
        setLoadingDocumentsButton(true);
        await getPendingEchemQueueItems().then((response: any) => {
            if (response.message === "OK") {
                setDocuments(response.documents);
                setLoadingDocuments(false);
                setLoadingDocumentsButton(false);
                return;
            }

            setLoadingDocuments(false);
            setLoadingDocumentsButton(false);
            console.log("Error on getPendingEchemQueueItems", response);
        }).catch((err: any) => {
            console.log(err);
            setLoadingDocuments(false);
            setLoadingDocumentsButton(false);
        });
    };

    const backendUrl: string = process.env.REACT_APP_AXIOS_URL || "";

    return <>
        <div className="EchemQueueViewer">
            <div className="row align-items-center">
                <div className="col-8">
                    <strong>Pending Items in Queue</strong>
                </div>
                <div className="col-4 text-end">
                    <button
                        type="button"
                        disabled={loadingDocumentsButton}
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => _getPendingItems(false)}>
                            <Loading loading={loadingDocumentsButton} parent="inline" />
                            {!loadingDocumentsButton && <><i className="fas fa-sync me-2"></i></>} 
                            Refresh
                    </button>
                </div>
            </div>

            <Separator size={20} />

            <Loading loading={loadingDocuments} />

            {!loadingDocuments && documents.length > 0 && <>
                <ul className="list-unstyled">
                    {documents.map((doc: any) => <li key={doc.id} className={`${doc.status !== "finish" ? "" : "opacity-50"}`}>
                        {doc.status === "pending" && <><i className="fas fa-clock text-muted me-2"></i></>}
                        {doc.status === "finish" && <><i className="fas fa-check-circle text-primary me-2"></i></>}
                        {doc.status === "error" && <><i className="fas fa-times-circle text-danger me-2"></i></>}
                        {doc.id}
                    </li>)}
                </ul>
            </>}

            <Separator size={20} />

            <small>
                <a href={`${backendUrl}/echem_queue/run`} target="_blank" rel="noopener noreferrer" className="btn btn-link btn-sm p-0 m-0">
                    run manually <i className="fas fa-external-link-alt"></i>
                </a>
            </small>
        </div>
    </>
};

export default EchemQueueViewer;