import { useEffect, useState } from "react";
import { canAccess, slugifyString } from "../../services/helpers";
import * as yup from 'yup';
import Separator from "../../components/Separator.component";
import { Modal } from "react-bootstrap";
import Loading from "../../components/Loading.component";
import { useFormik } from "formik";

// Using https://reactdatagrid.io/
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { createTemplate, deleteTemplate, editTemplate, getTemplates } from "../../services/templates.service";
import Alert from "../../components/Alert/Alert.component";

function Templates(props: any) {
    // Do not add any other code here
    useEffect(() => {
        if (!canAccess(props.user, 'templates')) {
            window.location.href = '/home';
            return;
        }
    }, [props.user, props.user.role]);

    const [alert, setAlert] = useState<any>({ type: '', message: '' });

    useEffect(() => {
        const load = async () => {
            _getTemplates();
        }
        load();
    }, []);

    const [loadingTemplates, setLoadingTemplates] = useState(false);
    const _getTemplates = (): void  => {
        setLoadingTemplates(true);
        getTemplates().then((response: any) => {
            if (!response.templates) {
                console.log("Error getting templates: ", response);
                setAlert({ type: 'danger', message: "Error getting templates" });
                setLoadingTemplates(false);
                return;
            }
            setTemplates(response.templates);
            setLoadingTemplates(false);
        }).catch((error: any) => {
            console.log("Error getting templates: ", error);
            setAlert({ type: 'danger', message: "Error getting templates" });
            setLoadingTemplates(false);
        });
    }

    const [templates, setTemplates] = useState<any[]>([] as any[]);

    const [showModal, setShowModal] = useState(false);

    const [loadingCreateTemplate, setLoadingCreateTemplate] = useState(false);

    const createNewTemplate = async () => {
        setLoadingCreateTemplate(true);
        createTemplate().then((response: any) => {
            if (!response.newTemplateData.id) {
                console.log("Error creating template: ", response);
                setAlert({ type: 'danger', message: 'Error creating template' });
                setLoadingCreateTemplate(false);
                return;
            }
            setTemplates([...templates, response.newTemplateData]);
            setAlert({ type: 'success', message: 'New template is ready' });
            setLoadingCreateTemplate(false);
        }).catch((error: any) => {
            console.log("Error creating template: ", error);
            setLoadingCreateTemplate(false);
        });
    }

    // Table settings
    const gridStyle = { minHeight: 450 };

    const userColumns = [
        { name: 'id', header: 'id', minWidth: 50, defaultFlex: 1, defaultVisible: false },
        { name: 'templateName', header: 'templateName', minWidth: 50, defaultFlex: 1 },
        { name: 'fields', header: 'fields', minWidth: 250, defaultFlex: 2, render: (value: any) => JSON.stringify(value.data, null, 1) },
    ];

    const filterValue = [
        { name: 'id', operator: 'startsWith', type: 'string', value: '' },
        { name: 'templateName', operator: 'startsWith', type: 'string', value: null },
    ];
    // /Table settings

    const [loadingSaveTemplate, setLoadingSaveTemplate] = useState(false);

    const edtiTemplateInitialValues: any = {
        id: '',
        templateName: '',
        fields: [],
    }

    const editTemplateFormik = useFormik({
        initialValues: edtiTemplateInitialValues,
        isInitialValid: false,
        validationSchema: yup.object({
            id: yup.string().required('Template ID is required'),
            templateName: yup.string().required('Template Name is required'),
            fields: yup.array(yup.object({
                fieldName: yup.string().required('Field Name is required'),
                fieldDefaultValue: yup.string().default(''),
            })).required('Fields is required'),
        }),
        onSubmit: async (values: any) => {
            setLoadingSaveTemplate(true);
            editTemplate(values).then((response: any) => {
                if (response.message === 'OK') {
                    setAlert({ type: 'success', message: 'Template updated successfully' });
                    const newTemplates = [...templates];
                    const index = newTemplates.findIndex((item: any) => item.id === values.id);
                    newTemplates[index] = values;
                    setTemplates(newTemplates);
                    setShowModal(false);
                    setLoadingSaveTemplate(false);
                    editTemplateFormik.resetForm();
                    return;
                }
                setAlert({ type: 'danger', message: 'Error updating template' });
                setLoadingSaveTemplate(false);
            }).catch((error: any) => {
                console.log("Error adding user: ", error);
                setAlert({ type: 'danger', message: 'Error updating template' });
                setLoadingSaveTemplate(false);
            });
        }
    });

    const [fieldName, setFieldName] = useState('');
    const [fieldDefaultValue, setFieldDefaultValue] = useState('');

    const [selectedTemplate, setSelectedTemplate] = useState<any>(null as any);

    const [loadingDeleteTemplate, setLoadingDeleteTemplate] = useState(false);
    const deleteSelectedTemplate = async () => {
        setLoadingDeleteTemplate(true);
        deleteTemplate(selectedTemplate.id).then((response: any) => {
            if (response.message !== 'OK') {
                setAlert({ type: 'danger', message: 'Error deleting template' });
                setLoadingDeleteTemplate(false);
                return;
            }
            setAlert({ type: 'success', message: 'Template deleted successfully' });
            const newTemplates = [...templates];
            const index = newTemplates.findIndex((item: any) => item.id === selectedTemplate.id);
            newTemplates.splice(index, 1);
            setTemplates(newTemplates);
            setShowModal(false);
            setLoadingDeleteTemplate(false);
        }).catch((error: any) => {
            console.log("Error deleting template: ", error);
            setAlert({ type: 'danger', message: 'Error deleting template' });
            setLoadingDeleteTemplate(false);
        });
    }

    return <>
        <div className="container">
            <h2>Templates <span className="text-muted">({templates.length})</span></h2>
            <p className="text-muted">
                Create/Edit pre-set templates to be used when adding new data and to append to existing data.
            </p>

            <Alert alert={alert} />

            <Separator size={10} />

            <button
                onClick={() => createNewTemplate()}
                type="button"
                disabled={loadingTemplates || loadingCreateTemplate}
                className="btn btn-primary">
                    {loadingCreateTemplate && <><Loading loading={loadingCreateTemplate} parent="inline" color="text-white" /> Creating Template...</>}
                    {!loadingCreateTemplate && <><i className="fas fa-plus-circle me-2"></i> Create Template</>}
            </button>

            <Separator size={20} />

            <Loading loading={loadingTemplates} />

            {/* Using https://reactdatagrid.io/ */}
            {!loadingTemplates && templates.length > 0 && <>
                <ReactDataGrid
                    idProperty="id"
                    columns={userColumns}
                    dataSource={templates}
                    style={gridStyle}
                    pagination
                    defaultFilterValue={filterValue}
                    enableSelection
                    onSelectionChange={(selection: any) => {
                        editTemplateFormik.setValues(selection.data);
                        setSelectedTemplate(selection.data);
                        setShowModal(true);
                    }}
                />
            </>}


            {/* Modal EDIT template */}

            <Modal show={showModal}>
                <Modal.Header closeButton onClick={() => setShowModal(false)}>
                    <Modal.Title>Pre-set Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {selectedTemplate && <>
                        <input
                            disabled={loadingSaveTemplate || loadingDeleteTemplate}
                            autoComplete="off"
                            name="id"
                            readOnly
                            value={selectedTemplate.id}
                            placeholder="Template ID"
                            type="hidden" className="form-control" id="id" />

                        <input
                            disabled={loadingSaveTemplate || loadingDeleteTemplate}
                            autoComplete="off"
                            name="templateName"
                            value={selectedTemplate.templateName}
                            onChange={(e: any) => {
                                setSelectedTemplate({
                                    ...selectedTemplate,
                                    templateName: e.target.value,
                                });
                                editTemplateFormik.setFieldValue('templateName', e.target.value);
                            }}
                            placeholder="Template Name"
                            type="text" className="form-control" id="name" />

                        <Separator size={20} />

                        <table className="table table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th>Field Name</th>
                                    <th>Default Value</th>
                                    <th className="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedTemplate.fields.map((field: any, index: number) => {
                                    return <tr key={`field-${index}`}>
                                        <td>{field.fieldName}</td>
                                        <td>{field.fieldDefaultValue}</td>
                                        <td className="text-center">
                                            <i className="fas fa-minus-circle text-danger cursor-pointer"
                                                onClick={() => {
                                                    const newFields = [...selectedTemplate.fields];
                                                    newFields.splice(index, 1);
                                                    setSelectedTemplate({
                                                        ...selectedTemplate,
                                                        fields: newFields,
                                                    });
                                                    editTemplateFormik.setFieldValue('fields', newFields);
                                                }}>
                                            </i>
                                        </td>
                                    </tr>
                                })}
                                <tr>
                                    <td>
                                        <input
                                            disabled={loadingSaveTemplate}
                                            autoComplete="off"
                                            name="fieldName"
                                            value={fieldName}
                                            onChange={(e: any) => setFieldName(slugifyString(e.target.value))}
                                            placeholder="New Field Name"
                                            type="text" className="form-control" id="name" />
                                    </td>
                                    <td>
                                        <input
                                            disabled={loadingSaveTemplate}
                                            autoComplete="off"
                                            name="fieldDefaultValue"
                                            value={fieldDefaultValue}
                                            onChange={(e: any) => setFieldDefaultValue(e.target.value)}
                                            placeholder="New Field Value"
                                            type="text" className="form-control" id="email" />
                                    </td>
                                    <td className="text-center">
                                        <button
                                            disabled={loadingSaveTemplate || !fieldName}
                                            onClick={() => {
                                                const newFields = [...selectedTemplate.fields];
                                                newFields.push({
                                                    fieldName: fieldName,
                                                    fieldDefaultValue: fieldDefaultValue || "",
                                                });
                                                setSelectedTemplate({
                                                    ...selectedTemplate,
                                                    fields: newFields,
                                                });
                                                editTemplateFormik.setFieldValue('fields', newFields);
                                                setFieldName('');
                                                setFieldDefaultValue('');
                                            }}
                                            type="button"
                                            className="btn btn-primary">
                                            <i className="fas fa-plus cursor-pointer"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <Separator size={10} />

                        <small className="text-muted">Template Preview:</small>

                        <Separator size={20} />

                        <code>
                            <pre>
                                &#123;<br />
                                    <div style={{ paddingLeft: '20px' }}>
                                        ...rest of document
                                        <Separator size={10} />
                                        {selectedTemplate.fields.map((field: any, index: number) => {
                                            return <div key={`field-${index}`}>
                                                "{field.fieldName}": "{field.fieldDefaultValue}"
                                            </div>
                                        })}
                                    </div>
                                &#125;
                            </pre>
                        </code>

                        <Separator size={20} />

                        <form onSubmit={(e: any) => {
                            e.preventDefault();
                            editTemplateFormik.handleSubmit();
                        }}>
                            <small className="text-muted">
                                <i className="fas fa-info-circle me-2"></i> By updating this template, existing data using it will not be updated.
                            </small>

                            <Separator size={20} />

                            <button
                                disabled={loadingSaveTemplate || !editTemplateFormik.isValid || editTemplateFormik.values.fields.length === 0 || loadingDeleteTemplate}
                                type="submit" className="btn btn-primary">
                                    <Loading loading={loadingSaveTemplate} parent="inline" color="text-white" />
                                    {!loadingSaveTemplate && <i className="fas fa-check me-2"></i>} Save Template
                            </button>

                            <button disabled={loadingSaveTemplate || loadingDeleteTemplate} type="button" className="btn btn-outline-primary ms-2" onClick={() => setShowModal(false)}>Close</button>
                            <button type="button" className="btn btn-danger ms-3 float-end" onClick={() => deleteSelectedTemplate()}>
                                {loadingDeleteTemplate && <><Loading loading={loadingDeleteTemplate} parent="inline" color="text-white" /> Deleting...</>}
                                {!loadingDeleteTemplate && <><i className="fas fa-trash me-2"></i> Delete Template</>}
                            </button>
                        </form>
                    </>}

                </Modal.Body>
            </Modal>

        </div>
    </>
}

export default Templates;