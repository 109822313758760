import {fetchAndActivate, getAll} from "firebase/remote-config";
import {firebaseRemoteConfig} from "./appInit.service";

export async function getFirebaseRemoteConfig() {
  return fetchAndActivate(firebaseRemoteConfig).then(() => {
    return getAll(firebaseRemoteConfig);

    // Or a single value
    // getValue(remoteConfig, "Firebase parameter key");

  }).catch((err) => {
    console.log('FIREBASE remoteConfig.fetchConfig() error', err)
  });
}