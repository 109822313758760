import axios from 'axios';

export const getOrdersFromWebflow = async () => {
    return await axios.get("fetchOrders").then((response: any) => response.data).catch((err: any) => err);
}

export const sendOrderToZapier = async (payload: any) => {
    return await axios.post("sendToZapier", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const getSyncHistory = async () => {
   return  await axios.get("syncHistory").then((response: any) => response.data).catch((err: any) => err);
}

export const getLastAutoSyncDate = async () => {
    return await axios.get("sales/getLastAutoSyncDate").then((response: any) => response.data).catch((err: any) => err);
}

export const getTwilioSmsLog = async () => {
    return await axios.get("sales/getTwilioSmsLog").then((response: any) => response.data).catch((err: any) => err);
}