import axios from 'axios';
import DateRangeInterface from './DateRange.interface';
import { DashboardFilterInterface } from "./DashboardFilterInterface";

export const getDailyPatientsFromECRF = async (payload: DashboardFilterInterface) => {
    return await axios.post("ecrf/dailyPatients", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const getSurveysFromECRF = async (payload: DashboardFilterInterface) => {
    return await axios.post("ecrf/surveys", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const getDashboardSummaryDemographic = async (payload: DashboardFilterInterface) => {
    return await axios.post("ecrf/dashboardSummaryDemographics", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const getProtocols = async () => {
    return await axios.get("ecrf/protocols").then((response: any) => response.data).catch((err: any) => err);
}
