import { Link } from "react-router-dom";
import Separator from "../../components/Separator.component";
import {getModuleDetails} from "../../services/helpers";

function Home(props: any) {
    const user: any = props.user || {};
    const userGroups: string[] = user.groups || [];

    const externalTiles: string[] = ["plotting", "idtdna", "inventory"];

    return <>
        <div className="container">
            <h2>Welcome, <span className="text-muted">{user.name}</span>.</h2>
            <p className="text-muted">
                Select the module you want to access.
            </p>

            <Separator size={40} />

            <div className="row">
                {userGroups.map((page: string, index: number) => {

                    let pageUrl: string = "";
                    if (externalTiles.includes(page)) {
                        pageUrl = getModuleDetails(page).url;
                        if (getModuleDetails(page).sendAccessToken) {
                            pageUrl = `${pageUrl}?access_token=${user.accessToken}`;
                        }
                    }

                    return <div key={`page-${page}-${index}`} className="col-3">
                        {externalTiles.includes(page) && <>
                            <a href={pageUrl} target="_blank" rel="noopener noreferrer" className="link-none" data-testid={`module-${page}`}>
                                <div className="card shadow border-0 mb-4">
                                    <div className="card-body">
                                        <strong className="uppercase"><i className={`${getModuleDetails(page).icon} me-2`}></i> {page} <i className="fas fa-external-link-alt ms-2 text-muted"></i></strong>
                                        <Separator size={10} />
                                        <span className="text-muted">
                                            {getModuleDetails(page).description}
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </>}

                        {!externalTiles.includes(page) && <>
                            <Link to={`/${page}`} className="link-none" data-testid={`module-${page}`}>
                                <div className="card shadow border-0 mb-4">
                                    <div className="card-body">
                                        <strong className="uppercase"><i className={`${getModuleDetails(page).icon} me-2`}></i> {page}</strong>
                                        <Separator size={10} />
                                        <span className="text-muted">
                                            {getModuleDetails(page).description}
                                        </span>
                                    </div>
                                </div>
                            </Link>
                        </>}
                    </div>
                })}
            </div>
        </div>
    </>
}

export default Home;