import { useFormik } from "formik";
import * as yup from 'yup';
import dayjs from 'dayjs';
import { getPlotData } from "../services/plot.service";
import { useEffect, useState } from "react";
import DownloadFlatFile from "./DownloadFlatFile/DownloadFlatFile.component";
// import Separator from "./Separator.component";

const FilterBar = (props: any) => {
    const {user} = props;
    const [next] = useState('')
    const [hasSelectedRows, setHasSelectedRows] = useState(false);
    useEffect(() => {
        setHasSelectedRows((props.selectedRows && props.selectedRows.length > 0) ? true : false);
    }, [props.selectedRows]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(props.loadingDataRows || props.loadingDataRowsPaginated);
    },
    [props.loadingDataRows, props.loadingDataRowsPaginated]);

    const lastWeek: any = dayjs().subtract(2, 'day').format('YYYY-MM-DD');
    const today: any = dayjs().add(2,'day').format('YYYY-MM-DD');

    const filterInitialValues: any = {
        dataOrigin: 'usb_data',
        dateRange: {
            from: lastWeek,
            to: today,
        },
        dateField: 'createdAt', // start_time, createdAt, date (the DATE column)
        next: next,
    };
    const filterFormik = useFormik({
        initialValues: filterInitialValues,
        validationSchema: yup.object({
            dataOrigin: yup.string().required('Data Origin is required'),
            dateRange: yup.object({
                from: yup.string().required('From date is required'),
                to: yup.string().required('To date is required'),
            }),
            dateField: yup.string().required('Name is required'),
            next: yup.number(),
            readers: yup.array().of(yup.string()).optional().default([]),
        }),
        onSubmit: (values:any) => {

            if (props.columns.length === 0) return;

            setLoadingToggleOnOff(true);

            values = {
                ...values, 
                ...{next}, 
                ...{readers: props.getSelectedReaders().map((reader: any) => reader.id.toString())}
            }
            const filterObjectToToAPI = {
                ...values,
                dataOrigin: values.dataOrigin,
                ranges: { 
                    xAxis: props.filterCriteriaFromParent.ranges.xAxis,
                    yAxis: props.filterCriteriaFromParent.ranges.yAxis
                },
                rangesNormalized: { 
                    xAxis: props.filterCriteriaFromParent.rangesNormalized.xAxis,
                    yAxis: props.filterCriteriaFromParent.rangesNormalized.yAxis
                },
                normalizedValue: props.filterCriteriaFromParent.normalizedValue,
                dataRange: {
                    from: values.dateRange.from,
                    to: values.dateRange.to,
                }
            }
            props.onFilterCriteriaChanges(filterObjectToToAPI);

            getPlotData(values).then((data: any) => {
                if (data.data){
                    if (next){
                        props.onFilter([...props.dataRows, ...data.data]);
                    } else {
                        props.onFilter([...data.data]);
                    }
                } else {
                    props.onFilter([...props.dataRows]);
                }
            }).catch((err: any) => {
                console.log(err);
            }).finally(() => {
                setLoadingToggleOnOff();
            })

        },
    });

    const setLoadingToggleOnOff  = (toggle : boolean = false)  => {
        props.onLoadingDataRows(toggle);
        setIsLoading(toggle);
    }

    const _getDocumentsForFlatFile = () => {
        if (props.dataRows.length === 0 && props.selectedRows.length === 0) return [];
        if (props.selectedRows.length > 0) {
            return props.selectedRows.map((row: any) => row.id);
        }
        return props.dataRows.map((row: any) => row.id);
    };

    return <>
        <div className="card bg-light border-0">
            <div className="card-body">
                <form onSubmit={(e: any) => {
                    e.preventDefault();
                    filterFormik.handleSubmit();
                }}>
                    <div className="row align-items-end">
                        <div className="col-12 col-md-2">

                            <label>Data Origin</label>
                            <select
                                disabled={isLoading}
                                name="dataOrigin"
                                value={filterFormik.values.dataOrigin}
                                onChange={(e: any) => {
                                    props.onFilter([]);
                                    return filterFormik.handleChange(e);
                                }}
                                className="form-control">
                                <option value="clinical_data">Clinical (clinical_data)</option>
                                <option value="dev_data">DEV (dev_data)</option>
                                <option value="usb_data">RnD (usb_data)</option>
                                {["lucas.moreira@aptitudemedical.com", "qin.yang@aptitudemedical.com"].includes(user.email) && <>
                                    <option value="archive_data">Archive (archive_data)</option>
                                </>}
                            </select>

                        </div>
                        <div className="col-12 col-md-3">
                            <label>Filter by Readers {props.getSelectedReaders().length > 0 && <><small className="text-muted">({props.getSelectedReaders().length} selected)</small></>}</label>
                            <div className="form-group d-grid">
                                <button type='button' className="btn btn-primary" onClick={() => props.setShowModalReadersSelection(true)}>
                                    <i className="fas fa-plus-circle me-2"></i> Add Readers Selection
                                </button>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <label>Date Range</label>
                            <div className="row align-items-center">
                                <div className="col-5">
                                    <div className="form-group">
                                        <input
                                            name="dateRange.from"
                                            disabled={isLoading}
                                            value={filterFormik.values.dateRange.from}
                                            onChange={filterFormik.handleChange}
                                            max={today}
                                            type="date" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-1 p-0 text-center">
                                    <small className="text-muted">to</small>
                                </div>
                                <div className="col-5">
                                    <div className="form-group">
                                        <input
                                            name="dateRange.to"
                                            value={filterFormik.values.dateRange.to}
                                            disabled={isLoading}
                                            onChange={filterFormik.handleChange}
                                            max={today}
                                            type="date" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col d-grid">
                            <button className="btn btn-primary"
                                disabled={props.loadingDataRows || props.loadingDataRowsPaginated}>
                                    <i className="fas fa-filter me-2"></i> Filter
                            </button>
                        </div>

                        <div className="col d-grid">
                            <button
                                disabled={!hasSelectedRows}
                                type="button" className="btn btn-primary"
                                onClick={() => props.getXlsxReaderCallingFile()}>
                                    <i className="fas fa-download me-2"></i> RC Excel
                            </button>
                        </div>

                        <div className="col d-grid">
                            <button
                                disabled={!hasSelectedRows}
                                type="button" className="btn btn-primary"
                                onClick={() => props.getXlsxRawFile()}>
                                    <i className="fas fa-download me-2"></i> Save Excel
                            </button>
                        </div>
                    </div>

                    {["lucas.moreira@aptitudemedical.com", "qin.yang@aptitudemedical.com"].includes(user.email) && <>
                        <div className="row mt-2">
                            <div className="col-4 offset-md-8 d-grid">
                                <DownloadFlatFile
                                        project="echem"
                                        collection={props.filterCriteriaFromParent.dataOrigin}
                                        documents={_getDocumentsForFlatFile()}
                                    />
                            </div>
                        </div>
                    </>}
                </form>
            </div>
        </div>
    </>;
}

export default FilterBar;