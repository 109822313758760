import { useFormik } from "formik"; 
import * as yup from 'yup';

const NormalizedFilter = (props: any) => {
    const filterInitialValues: any = {
        normalizedValue: props.filterCriteriaFromParent.normalizedValue,
        ranges: {
            xAxis :{
                min: props.rangeMinMaxAxis.xAxis.min,
                max: props.rangeMinMaxAxis.xAxis.max
            },
            yAxis :{
                min: props.rangeMinMaxAxis.yAxis.min,
                max: props.rangeMinMaxAxis.yAxis.max
            }
        },
        rangesNormalized: {
            xAxis :{
                min: props.rangeMinMaxAxis.xAxis.min,
                max: props.rangeMinMaxAxis.xAxis.max
            },
            yAxis :{
                min: props.rangeMinMaxAxis.yAxis.min,
                max: props.rangeMinMaxAxis.yAxis.max
            }
        }
    };

    const filterFormik = useFormik({
        initialValues: filterInitialValues,
        validationSchema: yup.object({
            normalizedValue: yup.number().required(''),
        }),
        onSubmit: (values) => {
            const filterObjectToToAPI = {
                ...values,
                dateRange: {
                    from: props.filterCriteriaFromParent.dateRange.from,
                    to: props.filterCriteriaFromParent.dateRange.to,
                },
                dateField: props.filterCriteriaFromParent.dateField,
                ranges: { 
                    xAxis: props.filterCriteriaFromParent.ranges.xAxis,
                    yAxis: props.filterCriteriaFromParent.ranges.yAxis
                },
                rangesNormalized: { 
                    xAxis: props.filterCriteriaFromParent.rangesNormalized.xAxis,
                    yAxis: props.filterCriteriaFromParent.rangesNormalized.yAxis
                }
            }
            props.onFilterCriteriaChanges(filterObjectToToAPI)
            props.getNormalized(filterObjectToToAPI)
        }
    });

    return <>
        <form onSubmit={(e: any) => {
            e.preventDefault();
            filterFormik.handleSubmit();
        }}>
            <div className="row align-items-center">
                <div className="col text-end">
                    <small className="text-muted">Set normalization</small>
                </div>
                <div className="col-3 pe-0">
                    <div className="form-group">
                        <input
                            name="normalizedValue"
                            value={filterFormik.values.normalizedValue}
                            onChange={(e: any) => {
                                filterFormik.handleChange(e);
                                filterFormik.handleSubmit();
                            }}
                            type="number" className="form-control form-control-sm" />
                    </div>
                </div>
                {/* <div className="col-6">
                    <button type="submit" className="btn btn-primary">
                        <i className="fas fa-filter me-2"></i> Filter
                    </button>
                </div> */}
            </div>
        </form>
    </>
}

export default NormalizedFilter;