import { useEffect } from "react";
import { canAccess } from "../../services/helpers";

function PrimerViewer(props: any) {
    // Do not add any other code here
    useEffect(() => {
        if (!canAccess(props.user, 'primerViewer')) {
            window.location.href = '/home';
            return;
        }
    }, [props.user, props.user.role]);

    return <>
        <div className="container">
            <h2>Primer Viewer</h2>
            <p className="text-muted">
                Not fully migrated yet.
            </p>
        </div>
    </>
}

export default PrimerViewer;