const DropDownColumnsInputNewValue = (props: any) => {
  const _getOptionsByColumn = (column: keyof typeof optionsByColumn) => {
    const optionsByColumn = {
      "reader_info.status": [
        "",
        "idle",
        "init",
        "running",
        "finish",
        "done",
      ].map((status: string) => {
        return { value: status, label: status };
      }),
      status: ["", "finish", "running"].map((status: string) => {
        return { value: status, label: status };
      }),
      isReaderData: ["", "1", "0"].map((status: string) => {
        return { value: status, label: status };
      }),
      userMarkedAs: ["", "0", "1", "-1"].map((status: string) => {
        return { value: status, label: status };
      }),
      ReaderCalling: ["", "0", "1", "-1"].map((status: string) => {
        return { value: status, label: status };
      }),
    };
    return optionsByColumn[column];
  };

  const _captilizeFirstLetter = (column: string): string => {
    if (typeof column === "string")
      return column.charAt(0).toUpperCase() + column.slice(1);
    return "";
  };

  const _getFormatKey = (column: string): string => {
    if (typeof column !== "string") return column;
    if (column.includes("ReaderCalling")) return column.split("-")[0];
    if (column.includes("label") || column.includes("userMarkedAs"))
      return column
        .split("-")
        .map((columnName: string) => columnName)
        .join(" - ");
    if (column.includes("_"))
      return column
        .split("_")
        .map((columnName: string) => _captilizeFirstLetter(columnName))
        .join(" ");
    if (column.includes("-"))
      return column
        .split("-")
        .map((columnName: string) => _captilizeFirstLetter(columnName))
        .join(" - ");

    return _captilizeFirstLetter(column);
  };

  const _getParsedKeyValue = (column: string): string => {
    if (column.toLowerCase().includes("ch"))
      return `${column.replace(".", "-")}`;
    return column;
  };

  const _getDropdownColumns = () => {
    let index = 0;
    const columns_options = [
      <option value="" key={`batch-edit-column-option-${index++}`}></option>,
    ];
    props.newColumnsToParse.forEach((column: Object) => {
      const name = Object.keys(column)[0];
      columns_options.push(
        <option
          key={`batch-edit-column-option-${index++}`}
          value={_getParsedKeyValue(Object.values(column)[0])}
        >
          {_getFormatKey(name)}
        </option>
      );
    });
    return (
      <select
        disabled={props.loadingBatchDataEdit}
        name="column"
        value={props.column}
        onChange={(e) => {
          props.handleChange(e);
          props.setFieldValue("newValue", "");
        }}
        className="form-select"
        aria-label="Select role"
      >
        {columns_options}
      </select>
    );
  };

  const _getDropdown = (options: any[]) => {
    let index = 0;
    return (
      <select
        disabled={props.loadingBatchDataEdit}
        name="newValue"
        value={props.newValue}
        onChange={props.handleChange}
        className="form-select"
        aria-label="Select role"
      >
        {options.map((option: any) => {
          return (
            <option
              key={`batch-edit-column-option-${index++}`}
              value={option.value}
            >
              {option.label}
            </option>
          );
        })}
      </select>
    );
  };

  const _getNewValueInputType = () => {
    let options = [];
    let columnName = props.column;
    if (columnName.includes("-")) {
      columnName = columnName.split("-")[1];
    }
    switch (columnName) {
      case "reader_info.status":
        options = _getOptionsByColumn("reader_info.status");
        return _getDropdown(options);
      case "status":
        options = _getOptionsByColumn("status");
        return _getDropdown(options);
      case "isReaderData":
        options = _getOptionsByColumn("isReaderData");
        return _getDropdown(options);
      case "userMarkedAs":
        options = _getOptionsByColumn("userMarkedAs");
        return _getDropdown(options);
      case "ReaderCalling":
        options = _getOptionsByColumn("ReaderCalling");
        return _getDropdown(options);
      default:
        return (
          <input
            disabled={props.loadingBatchDataEdit}
            name="newValue"
            value={props.newValue}
            onChange={props.handleChange}
            className="form-control"
          />
        );
    }
  };

  return (
    <>
      <div className="col-6">
        <label className="form-label">Column</label>
        {_getDropdownColumns()}
      </div>
      <div className="col-6">
        <label className="form-label">New Value</label>
        {_getNewValueInputType()}
      </div>
    </>
  );
};

export default DropDownColumnsInputNewValue;
