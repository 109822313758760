import { useEffect, useState } from 'react';
import { COLORS } from '../services/helpers';
import Separator from './Separator.component';

const labelStyle = {
    'paddingLeft': '10px',
    'fontSize': '10px'
}

const labelStyleGroup = {
    'paddingLeft': '10px',
    'fontSize': '12px'
}

const noBorder = {
    border: 'none',
}

const padTop = {
    paddingTop: '70px'
}

const LegendChart = (props: any) => {    
    const [color, setColor] = useState('');
    const [checked, setChecked] = useState([] as any);
    const [shiftHeld, setShiftHeld] = useState(false);
    const [checkedAll, setCheckedAll] = useState(false as any);

    useEffect(() => { 
        onColorChange(color)
    }, [color])

    function OnChangeSelectedAllCheckebox(){
        if (!checkedAll) {
            Array.from(document.getElementsByClassName("curvesItem")).forEach((element: any, index:any) => {
                element.checked = true;
                setChecked((checked:any) => ([...checked, ...[index]]))
            });
        } else {
            Array.from(document.getElementsByClassName("curvesItem")).forEach((element: any, index:any) => {
                element.checked = false;
                setChecked((checked:any) => ([]))
            });
        }
    }

    function downHandler({key}: KeyboardEvent) {
        if (key === 'Shift') {
            setShiftHeld(true);
        }
    }

    function upHandler({key}: KeyboardEvent) {
        if (key === 'Shift') {
            setShiftHeld(false);
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        return () => {
        window.removeEventListener('keydown', downHandler);
        window.removeEventListener('keyup', upHandler);
        };
        
    }, []);

    const [groupCurvesByChannel, setGroupCurvesByChannel] = useState(true);

    useEffect(() => { }, [color, groupCurvesByChannel])

    const onColorChange = (newColor: any) => {
        const value = newColor
        let checkbox: any;
        if(checked.length > 0){
            checked
                .filter((item:any,index:any) => checked.indexOf(item) === index)
                .forEach((id: any) => {
                    props.curvesMain[id].line.color = value
                    props.curvesNormalized[id].line.color = value
                    props.setMainCurves([...props.curvesMain])
                    props.setNormalizedCurves([...props.curvesNormalized])        
                    checkbox = document.getElementById(`curves-${id}`) as HTMLInputElement;
                    checkbox.checked = false
            })
            setCheckedAll(false)
            setChecked([])
        }
    }

    const arrayRange = (start:number, stop:number, step:number) => {
        return Array.from({ length: (stop - start) / step + 1 },
            (value, index) => start + index * step
        );
    }

    const checkedAllGap = (start:number, stop:number, step:number): number[] => {
        return arrayRange(start, stop, step).map((i: any) => {
            const checkElement = document.getElementById(`curves-${i}`) as HTMLInputElement;
            checkElement.checked = true;
            return i;
        })
    }

    const handleCheck = (event: any) => {
        if (shiftHeld) {
            if (checked.length > 0) {
                const last = checked[checked.length - 1]
                if (last > event.target.value) {
                    setChecked([...checked, ...checkedAllGap(parseInt(event.target.value,10),parseInt(last,10), 1)]); 
                }else{
                    setChecked([...checked, ...checkedAllGap(parseInt(last,10) + 1, parseInt(event.target.value,10), 1)]); 
                }
            }else{
                let updatedList = [...checked];
                if (event.target.checked) {
                    updatedList = [...checked, event.target.value];
                } else {
                    updatedList.splice(checked.indexOf(parseInt(event.target.value, 10)), 1);
                }
                setChecked(updatedList);
            }
        } else {
            let updatedList = [...checked];

            if (event.target.checked) {
                    updatedList = [...checked, parseInt(event.target.value, 10)];
            } else {
                updatedList.splice(checked.indexOf(parseInt(event.target.value, 10)), 1);
            }
            setChecked(updatedList);
        }
    };

    const sortCurvesMain = ()  => {
        setGroupCurvesByChannel(!groupCurvesByChannel)
        if (groupCurvesByChannel) {
            props.curvesMain.sort((a:any, b:any) => (a.channel > b.channel) ? 1 : -1)
            props.curvesNormalized.sort((a:any, b:any) => (a.channel > b.channel) ? 1 : -1)
        }else{
            props.curvesMain.reverse().sort((a:any, b:any) => (b.rowIndex < a.rowIndex) ? 1 : -1)
            props.curvesNormalized.reverse().sort((a:any, b:any) => (b.rowIndex < a.rowIndex) ? 1 : -1)
        }
    }

    return (
        <div>
            {(props.curvesMain && props.curvesMain.length > 0) && <>
                <GroupCurvesByChannel sortCurvesMain={sortCurvesMain}/>

                <Separator size={10} />

                <SelectAllCurves 
                    onChangeSelectedAll={OnChangeSelectedAllCheckebox} 
                    checkedAll={checkedAll} 
                    setCheckedAll={setCheckedAll}
                    totalRows={props.curvesMain.length}
                />

                <Separator size={15} />

                <div style={{overflowY:'auto', maxHeight: '360px'}}>
                    {props.curvesMain.map((curve: any, index: any) => { 
                        return (
                            <div key={index} style={{whiteSpace: 'nowrap'}}>
                                <label style={labelStyle}>
                                    <input
                                        className="curvesItem"
                                        type="checkbox"
                                        id={`curves-${index}`}
                                        value={index}
                                        style={{float: "left", marginRight: "5px"}} onChange={(event) => handleCheck(event)}/>
                                    <div style={{
                                        float: 'left',
                                        border: 'none',
                                        width: '20px', 
                                        height: '10px', 
                                        backgroundColor: curve.line.color, 
                                        paddingLeft: '5px',
                                        }}
                                    ></div>
                                    <span style={{paddingLeft: '5px'}}>{`${curve.name}`}</span>
                                </label>
                            </div>
                        )
                    })}
                </div>

                <Separator size={15} />

                <AutoColor 
                    curvesMain={props.curvesMain} 
                    curvesNormalized={props.curvesNormalized}
                    setMainCurves={props.setMainCurves}
                    setNormalizedCurves={props.setNormalizedCurves}
                />
                
                <Separator size={15} />

                <InputBottom 
                    curvesMain={props.curvesMain} 
                    curvesNormalized={props.curvesNormalized}
                    setMainCurves={props.setMainCurves}
                    setNormalizedCurves={props.setNormalizedCurves}
                    setColor={setColor}
                    onColorChange={onColorChange}
                /> 
            </>}
        </div>
        )
}

const InputBottom = (props:any) => {
    const onColorClick = (color: any) => {
        props.setColor(color)
        props.onColorChange(color)
    }

    const transparentSquare = (
        <li style={{float: 'left'}}>
            <Square value={"transparent"} onSquareClick={() => onColorClick("transparent")} />
        </li>
    )

    return (
        <div>
            <ul style={{listStyleType: 'none',padding: '0px', margin: '0px'}}>
                {Array(16).fill(0).map((_, i) => {
                    return (
                        <li key={i} style={{float: 'left'}}>
                            <Square value={COLORS[i]} onSquareClick={() => onColorClick(COLORS[i])} />
                        </li>
                    )
                })}
                {transparentSquare}
            </ul>
        </div>
    )
}

const Square = ({ value, onSquareClick }: any) => {
    let colorButton = {
        border: 'solid 1px #ccc', 
        padding: '3px', 
        margin: '4px', 
        width: '25px', 
        height: '25px',
        background: value
    }

    return (
        <button 
            style={colorButton} 
            onClick={() => onSquareClick(value)}>
            &nbsp;
        </button>
    );
}

const GroupCurvesByChannel = (props:any) => {
    return (
        <label 
            style={labelStyleGroup}
            htmlFor="groupCurvesByChannel" >
            <input
                type="checkbox"
                id="groupCurvesByChannel"
                name="groupCurvesByChannel"
                value="groupCurvesByChannel"
                style={noBorder} 
                onChange={props.sortCurvesMain}
                />
                <span style={{paddingLeft: '5px'}}>Group curves by channel</span>
        </label>
    )
}

const SelectAllCurves = (props:any) => {
    const {totalRows} = props;
    function handleChange(event:any) {
        props.setCheckedAll(event.target.checked)
        props.onChangeSelectedAll()
    }

    return (
        <label 
            style={labelStyleGroup}
            htmlFor="selectAllCurves" >
            <input
                className="selectdAllCurves"
                type="checkbox"
                id="selectAllCurves"
                name="selectAllCurves"
                value="selectAllCurves"
                style={noBorder} 
                onChange={handleChange}
                checked={props.checkedAll}
                />
                <span style={{paddingLeft: '5px'}}>Select All {totalRows || ""} Curves</span>
        </label>
    )
}

const AutoColor = (props:any) => {
    const handleClick = () => {
        props.curvesMain.forEach((curve:any, index:any) => {
            if (props.curvesMain[index].channel === "CH0") props.curvesMain[index].line.color = "green";
            if (props.curvesMain[index].channel === "CH1") props.curvesMain[index].line.color = "green";
            if (props.curvesMain[index].channel === "CH2") props.curvesMain[index].line.color = "red";
            if (props.curvesMain[index].channel === "CH3") props.curvesMain[index].line.color = "red";
        })
        props.curvesNormalized.forEach((cursor:any, index:any) => {
            if(props.curvesNormalized[index].channel === "CH0") props.curvesNormalized[index].line.color = "green";
            if(props.curvesNormalized[index].channel === "CH1") props.curvesNormalized[index].line.color = "green";
            if(props.curvesNormalized[index].channel === "CH2") props.curvesNormalized[index].line.color = "red";
            if(props.curvesNormalized[index].channel === "CH3") props.curvesNormalized[index].line.color = "red";
        })
        props.setMainCurves([...props.curvesMain])
        props.setNormalizedCurves([...props.curvesNormalized])
    }
    return (
        <button type='button' className="btn btn-primary" onClick={handleClick}>
            Auto-Color
        </button>
    )
}

export default LegendChart;