import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { canAccess, modulesDetails } from "./../services/helpers";

export const Navbar = (props: any) => {
    const [user, setUser] = useState<any>({});

    useEffect(() => {
        _getUser();
    }, []);

    const _getUser = (): void => {
        const user = localStorage.getItem('user');
        setUser(JSON.parse(user || '{}'));
    }

    const logout = () => {
        window.location.href = '/';
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken_original');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user_original');
    }

    const isLoggedIn = () => {
        const user = localStorage.getItem('user');
        return (user) ? true : false;
    }

    return <>
        <nav className="navbar navbar-expand-lg bg-body-tertiary mb-4">
            <div className="container">
                <Link to="/" className="navbar-brand">Aptitude Data</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        {isLoggedIn() && <>
                            <li className="nav-item">
                                <Link to="/home" className="nav-link" data-testid="navbar-home" title="Home"><i className="fas fa-home me-2"></i></Link>
                            </li>

                            {/*
                            {modulesDetails.map((module: any, index: number) => {
                                return canAccess(user, module.module) ?
                                    <li key={`module-${module.module}-${index}`} className="nav-item">
                                        <Link data-testid={`navbar-${module.module}`} to={`/${module.module}`} className="nav-link uppercase" title={module.module}><i className={`${module.icon} me-2`}></i></Link>
                                    </li>
                                    : null;
                            })}
                            */}

                            <li className="nav-item">
                                <Link onClick={() => logout()} data-testid="navbar-logout" className="nav-link" to="/" title="Exit"><i className="fas fa-sign-out-alt me-2"></i></Link>
                            </li>
                        </>}
                    </ul>
                </div>
            </div>
        </nav>
    </>
}

export default Navbar;