import { useEffect, useState } from "react";
import { batchUpdateReaders, getReaders } from "../../services/readers.service";
import Separator from "../../components/Separator.component";
import { Modal } from 'react-bootstrap';
import * as yup from 'yup';
import "./Readers.scss";

import '@inovua/reactdatagrid-community/index.css';
import Loading from "../../components/Loading.component";
import { useFormik } from "formik";
import { canAccess } from "../../services/helpers";
import Alert from "../../components/Alert/Alert.component";
import ReadersGrid from "../../components/ReadersGrid.component";

function Readers(props: any) {
    // Do not add any other code here
    useEffect(() => {
        if (!canAccess(props.user, 'plot')) {
            window.location.href = '/home';
            return;
        }
    }, [props.user, props.user.role]);

    const [alert, setAlert] = useState<any>({ type: '', message: '' });

    const [loadingReaders, setLoadingReaders] = useState<boolean>(false);
    const [readers, setReaders] = useState<any>([]);

    const _getReaders = async () => {
        setLoadingReaders(true);
        getReaders().then((res) => {
            if (!res.readers) {
                setAlert({ type: 'danger', message: 'Error loading readers list' });
                setLoadingReaders(false);
                return;
            }
            setReaders(res.readers);
            setLoadingReaders(false);
        }).catch((err) => {
            console.log('ERRO', err);
            setAlert({ type: 'danger', message: 'Error loading readers' });
            setLoadingReaders(false);
        });
    }

    // Table settings
    
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const load = async () => {
            _getReaders();
        }
        if (readers.length === 0) {
            load();
        }
    /* eslint-disable-next-line */
    }, []);

    const batchEditReadersInitialValues: any = {
        column: '',
        newValue: '',
    }

    const [loadingEditReaders, setLoadingEditReaders] = useState<boolean>(false);

    const [selectedReaders, setSelectedReaders] = useState<any>([] as any[]);

    const batchEditReadersFormik = useFormik({
        initialValues: batchEditReadersInitialValues,
        isInitialValid: false,
        validationSchema: yup.object({
            column: yup.string().required('Column is required'),
            newValue: yup.string(),
        }),
        onSubmit: async (values: any) => {
            setLoadingEditReaders(true);

            const readersToUpdate: any[] = [];
            selectedReaders.forEach((reader: any) => {
                readersToUpdate.push(reader.id);
            });

            const body: any = {
                readers: readersToUpdate,
                column: values.column,
                newValue: values.newValue,
            };

            batchUpdateReaders(body).then((res) => {
                if (res.message === "OK") {
                    setShowModal(false);
                    setAlert({ type: 'success', message: 'Readers updated successfully' });
                    const _readers = [...readers];
                    readersToUpdate.forEach((readerId: string) => {
                        _readers.forEach((reader: any) => {
                            if (reader.id === readerId) {
                                reader[values.column] = values.newValue;
                            }
                        });
                    });
                    batchEditReadersFormik.resetForm();
                    setReaders(_readers);
                } else {
                    setAlert({ type: 'danger', message: 'Error updating readers' });
                }
                setLoadingEditReaders(false);
            }).catch((err) => {
                console.log("Error on batch update",err);
            });
        }
    });

    return <>
        <div className="container">
            <h2>Readers <span className="text-muted">({readers.length || 0})</span></h2>
            <p className="text-muted">
                Manage readers and their fields.
            </p>

            <Alert alert={alert} />

            <Separator size={20} />

            <Loading loading={loadingReaders} />

            {!loadingReaders && readers.length > 0 && <>
                <Separator size={10} />

                <button
                    onClick={() => setShowModal(true)}
                    disabled={selectedReaders.length === 0}
                    type="button"
                    className="btn btn-outline-secondary">
                        <i className="fas fa-edit me-2"></i> Batch Edit Fields
                </button>

                <Separator size={20} />

                <div className="row">
                    <div className={`col-12 ${selectedReaders.length > 0 ? "col-md-9" : "col-md-12"}`}>

                        <small className="text-muted">{selectedReaders.length} selected</small>

                        <Separator size={10} />

                        <ReadersGrid setSelectedReaders={setSelectedReaders} />

                    </div>
                    <div className={`col-12 col-md-3 ${selectedReaders.length === 0 ? "d-none" : ""}`}>
                        <small className="text-muted">Preview selected documents</small>

                        <Separator size={10} />

                        <code>
                            <pre>
                                {selectedReaders.length > 0 && selectedReaders.map((reader: any, index: number) => {
                                    return <div key={`selected-reader-${index}`}>
                                        {JSON.stringify(reader, null, 2)}
                                        <br />
                                    </div>
                                })}
                            </pre>
                        </code>
                    </div>
                </div>

            </>}



            {/* Batch edit modal */}

            <Modal show={showModal}>
                <Modal.Header closeButton onClick={() => setShowModal(false)}>
                    <Modal.Title>Edit {selectedReaders.length} readers</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form onSubmit={(e: any) => {
                        e.preventDefault();
                        batchEditReadersFormik.handleSubmit();
                    }}>

                        <div className="row">
                            <div className="col-6">

                            <label className="form-label">Column</label>
                            <select
                                disabled={loadingEditReaders}
                                name="column"
                                value={batchEditReadersFormik.values.column}
                                onChange={batchEditReadersFormik.handleChange}
                                className="form-select" aria-label="Select role"
                            >
                                <option value=""></option>
                                <option value="reader_name">reader_name</option>
                                <option value="status">status</option>
                                <option value="location">location</option>
                                <option value="group">group</option>
                                <option value="version">version</option>
                                <option value="firmware_version">firmware_version</option>
                            </select>

                            </div>
                            <div className="col-6">

                                <label className="form-label">New Value</label>

                                {batchEditReadersFormik.values.column === "status" && <>
                                    <select
                                        disabled={loadingEditReaders}
                                        name="newValue"
                                        value={batchEditReadersFormik.values.newValue}
                                        onChange={batchEditReadersFormik.handleChange}
                                        className="form-select" aria-label="Select role"
                                    >
                                        <option value=""></option>
                                        <option value="finished">finished</option>
                                        <option value="running">running</option>
                                    </select>
                                </>}

                                {batchEditReadersFormik.values.column === "group" && <>
                                    <select
                                        disabled={loadingEditReaders}
                                        name="newValue"
                                        value={batchEditReadersFormik.values.newValue}
                                        onChange={batchEditReadersFormik.handleChange}
                                        className="form-select" aria-label="Select role"
                                    >
                                        <option value=""></option>
                                        <option value="RnD">RnD</option>
                                        <option value="clinical">clinical</option>
                                        <option value="dev">dev</option>
                                    </select>
                                </>}

                                {!["status", "group"].includes(batchEditReadersFormik.values.column) && <>
                                    <input
                                        disabled={loadingEditReaders}
                                        autoComplete="off"
                                        name="newValue"
                                        value={batchEditReadersFormik.values.newValue}
                                        onChange={batchEditReadersFormik.handleChange}
                                        type="text" className="form-control" />
                                </>}

                            </div>
                        </div>

                        {batchEditReadersFormik.isValid && <>
                            <Separator size={20} />

                            Check the changes before confirm:

                            <Separator size={10} />

                            <table className="table table-sm table-bordered">
                                <tbody>
                                    {selectedReaders.length > 0 && selectedReaders.map((reader: any, index: number) => {
                                        return <tr key={`tr-preview-reader-updates-${index}`}>
                                            <td>{reader.id}</td>
                                            <td>
                                                <span className="batch-edit-old-value me-1">{reader[batchEditReadersFormik.values.column] || <>&nbsp;</>}</span>
                                                &rarr; 
                                                <span className="batch-edit-new-value ms-1">{batchEditReadersFormik.values.newValue}</span>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </>}

                        <Separator size={20} />

                        <button
                            disabled={loadingEditReaders || !batchEditReadersFormik.isValid}
                            type="submit" className="btn btn-primary">
                                <Loading loading={loadingEditReaders} parent="inline" color="text-white" />
                                {!loadingEditReaders && <i className="fas fa-check me-2"></i>} Update {selectedReaders.length} Readers
                        </button>

                        <button type="button" className="btn btn-outline-primary ms-2" onClick={() => setShowModal(false)}>Close</button>
                    </form>

                </Modal.Body>
            </Modal>

        </div>
    </>
}

export default Readers;