import React, { useEffect, useState } from 'react';

import { downloadRelease, listReleases } from '../../services/firmwareHub.service';
import { canAccess } from '../../services/helpers';
import Loading from '../../components/Loading.component';
import Separator from '../../components/Separator.component';
import {
  truncateString
} from '@moreirapontocom/npmhelpers';

import './FirmwareHub.scss';

function FirmwareHub(props: any) {
  // Do not add any other code here
  useEffect(() => {
    if (!canAccess(props.user, 'firmwareHub')) {
        window.location.href = '/home';
        return;
    }
  }, [props.user, props.user.role]);

  useEffect(() => {
    getReleases();
  }, []);

  const [loadingReleases, setloadingReleases] = useState(false);
  const [releases, setReleases] = useState([]);

  const getReleases = async () => {
    setloadingReleases(true);
    const res = await listReleases().then((response: any) => response).catch((err: any) => err);
    if (res.message === "OK") {
      setReleases(res.releases);
      setloadingReleases(false);
      return;
    }

    console.error('Error fetching releases', res);
    setloadingReleases(false);
  };

  const checkIfIsProduction = (releaseName: string) => {
    // releaseName for production is like "v1.0.0"
    // releaseName for development is like "v0.0.3-dev-pc_threshold-storage_mem_alloc"

    return releaseName.indexOf("-") === -1 ? "production" : "development";
  };

  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingDownloadId, setLoadingDownloadId] = useState(null as any);
  const downloadItem = async (releaseId: string) => {
    setLoadingDownload(true);
    setLoadingDownloadId(releaseId);
    const res = await downloadRelease(releaseId).then((response: any) => response).catch((err: any) => err);
    if (res.message === "OK") {
      window.open(res.downloadUrl, '_blank');
      setLoadingDownloadId(null);
      setLoadingDownload(false);
      return;
    }

    console.error('Error fetching releases', res);
    setLoadingDownloadId(null);
    setLoadingDownload(false);
  };

  return (
    <div className='container'>

      <div className="row">
        <div className="col">

          <h2>
            Firmware Hub <i className="fas fa-code-branch ms-2"></i>
          </h2>
          <p className="text-muted">
            Download the latest firmware versions for your devices.<br />
            Only STM32 is available at the moment.
          </p>

        </div>
        <div className="col-2 text-end">
          <button
            className="btn btn-primary"
            disabled={loadingReleases}
            onClick={getReleases}>
              <Loading loading={loadingReleases} parent="inline" color="text-white" />
              {!loadingReleases && <i className="fas fa-sync me-2"></i>} Refresh
          </button>
        </div>
      </div>

      <Separator size={30} />

      <div className="row">
        <div className="col-3">

          <div className="form-group">
            <label>Choose the firmware type</label>
            <select className="form-select" disabled>
              <option value="STM32">STM32</option>
            </select>
          </div>

        </div>
      </div>

      <Separator size={30} />

      <small className="text-muted">
        {releases.length} releases found
        {loadingReleases && <span> &middot; Updating...</span>}
      </small>

      <Separator size={10} />

      <Loading loading={loadingReleases && releases.length === 0} />

      <div className="accordion" id="accordionExample">

        {releases.length > 0 && releases.map((release: any) => (
          <div key={release.id} className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${release.id}`} aria-expanded="true" aria-controls={`collapseOne${release.id}`}>

                <div className="row align-items-center" style={{ width: "100%" }}>
                  <div className="col-1">

                    <span className={`badge me-2 ${checkIfIsProduction(release.name) === "production" ? "bg-primary" : "bg-secondary opacity-50"}`}>
                      {checkIfIsProduction(release.name)}
                    </span>

                  </div>
                  <div className="col">

                    {checkIfIsProduction(release.name) === "production" && <strong>{release.tag_name}</strong>}
                    {checkIfIsProduction(release.name) !== "production" && release.tag_name}

                  </div>
                </div>

              </button>
            </h2>
            <div id={`collapseOne${release.id}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">

                <small className='text-muted'>{release.assets.length} assets</small>

                <Separator size={10} />

                {release.assets.map((version: any, index: number) => (
                  <div key={version.id}>
                    <table border={0} width="100%" cellPadding={10}>
                      <tbody>
                        <tr style={{ backgroundColor: index % 2 === 0 ? "#efefef" : "white" }}>
                          <td style={{ width: "80%" }}>
                            <button
                              className={`btn btn-link p-0 ${loadingDownload && loadingDownloadId === version.id && "opacity-50"}`}
                              onClick={() => downloadItem(version.id)}>
                                <Loading loading={loadingDownload && loadingDownloadId === version.id} parent="inline" />
                                {truncateString(version.name, 150, true)}
                            </button>
                          </td>
                          <td style={{  textAlign: "left" }}>
                            {version.size} bytes
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {version.download_count}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}

              </div>
            </div>
          </div>
        ))}

      </div>

    </div>
  );
}

export default FirmwareHub;
