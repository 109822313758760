import { useEffect, useState } from "react";
import { getPatientAuditTrail } from "../../services/sti.service";
import { flatten } from 'flat';
import { flattenAndFilter } from "../../services/helpers";

const HistoryVersions = (props: any) => {
  const {user, patientDocId, patient} = props;

  useEffect(() => {
    _getHistory();
    console.log(patient)
  }, []);

  const [loadingHistory, setLoadingHistory] = useState(false);
  const [alert, setAlert] = useState({ type: '', message: '' });

  const [history, setHistory] = useState([] as any[]);

  const _getHistory = async () => {
    setLoadingHistory(true);
    await getPatientAuditTrail(patientDocId, patient.protocol_nameCollection).then((response: any) => {
      if (response.message === "OK") {
        setHistory(response.auditTrail);
        setLoadingHistory(false);
        return;
      }

      setAlert({ type: 'danger', message: 'Error fetching history' });
      console.log("Response", response);
      setLoadingHistory(false);
    }).catch((err: any) => {
      console.log("Error", err);
      setAlert({ type: 'danger', message: 'Error fetching history' });
      setLoadingHistory(false);
    });
  };

  const [selectedHistoryId, setSelectedHistoryId] = useState(null);
  const [selectedHistoryDocument, setSelectedHistoryDocument] = useState(null as any);

  return <div className="container">
    <div className="row">
      <div className="col-4">

        <div className="list-group">
          {!loadingHistory && history.length > 0 && <>
            {history.map((h: any, indexHistory: number) => <button 
              onClick={() => {
                setSelectedHistoryId(h.id);
                const newFlattenDoc: any = flattenAndFilter(h, "demographics");
                setSelectedHistoryDocument(newFlattenDoc);
              }}
              key={`history-item-${indexHistory}-${h.id}`}
              type="button"
              className={`list-group-item list-group-item-action ${selectedHistoryId === h.id && 'active'}`}>{h.id}</button>)}
          </>}
        </div>

      </div>
      <div className="col">

        {selectedHistoryDocument && <>
          {/*
          <pre>
            {JSON.stringify(selectedHistoryDocument, null, 2)}
          </pre>
          */}

          <ul className="list-unstyled">

            {Object.keys(selectedHistoryDocument).map((key: string, indexHistory: number) => {
              return <li key={`history-${key}-${indexHistory}`}>
                {/* <strong>{JSON.stringify(key, null, 2)}</strong>: {selectedHistoryDocument[key]} */}
                <strong>{key}: </strong> {JSON.stringify(selectedHistoryDocument[key], null, 2)}
              </li>
            })}

          </ul>
        </>}

      </div>
    </div>
  </div>
};

export default HistoryVersions;