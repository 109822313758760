import xlsx from 'node-xlsx';
import { prefixSelectFields } from './helpers';
// import { selectedFieldsNamesThatContainsYvalues } from './helpers';



export const selectedFieldsNamesThatContainsYvalues = (
    selectedRows: any[]
  ): any => {
    const result = [] as any;
    if (!selectedRows) return [];
    selectedRows.forEach((row: any) => {
      const channelsNames = Object.keys(row)
        .filter((key) => key.includes(prefixSelectFields))
        .sort()
        .filter(
          (x) =>
            x.indexOf("userMarkedAs") === -1 &&
            x.indexOf("ReaderCalling") === -1 &&
            x.indexOf("label") === -1
        );
      channelsNames.forEach((channelNameRow: any) => {
          result.push(channelNameRow);
        }
      );
    });
    return result.sort().filter((item:any, idx:any) => result.indexOf(item) === idx);
  };

export const buildXlsxReaderCalling = (payload: any): Promise<Buffer> => {


    function getResult(value: number) {
        const parsedValue = value ? parseInt(value.toString(),10) : value;
        if(parsedValue === 1) return "POSITIVE";
        if(parsedValue === 0) return "NEGATIVE";
        if(parsedValue === -1) return "NA";  
        return value;
    }
    
    function getSheets(data: any, nameFile: string) {
        const sheets = [] as any;
        const mainSheet = [] as any;
        const ranges: any[] = [{s:{c:0,r:0}, e:{c:0,r:1}},{s:{c:1,r:0}, e:{c:1,r:1}},{s:{c:2,r:0}, e:{c:2,r:1}},{s:{c:3,r:0}, e:{c:3,r:1}}];
        const sheetOptions = {'!merges': [ranges]};
        const channels = selectedFieldsNamesThatContainsYvalues(data)
        
        let firstHeaderRow: any[] = ["ID", "Start time", "Reader Name", "File Name", "Reader ID",];
        let secondHeaderRow: any[] = ["ID", "Start time", "Reader Name", "File Name", "Reader ID",];
        
        if (channels.length > 0) {
            channels.forEach((channel: string, index: number) => {
                firstHeaderRow = [...firstHeaderRow, ...[channel, channel, channel, channel]];
                secondHeaderRow = [...secondHeaderRow, ...["RESULT","CT","PR", "SD"]];
                ranges.push({s: {c: index === 0? 4 : (index * 4) + 4, r: 0}, e: {c: index === 0? 7 : (index * 4) + 7, r: 0}}); // A1:A4
            })
        }
        
        mainSheet.push(firstHeaderRow);                
        mainSheet.push(secondHeaderRow);
        sheetOptions['!merges'] = ranges as any;
        
        data.forEach((row: any, index: number) => {
            const newRow = [] as any;
            newRow.push(row?.id || "");
            newRow.push(row?.createdAt || "");
            newRow.push(row?.reader_name || row?.reader_info?.reader_name || "");
            newRow.push(row?.filename || "");
            newRow.push(row?.reader_id || "");
            channels.forEach((channel: string) => {
                newRow.push(getResult(row[channel]?.readercalling));
                newRow.push(row[channel]?.ct || "");
                newRow.push(row[channel]?.pr || "");
                newRow.push(row[channel]?.sd || "");
            })
            mainSheet.push(newRow);
        })
        sheets.push({ name: `${nameFile}`, data: mainSheet, options: sheetOptions })
        return sheets;
    }

    return new Promise<Buffer>((resolve, reject) => {
        const { data } = payload;
        const buffer = xlsx.build(
            getSheets(data, payload.nameFile)
        );
        resolve(buffer);
    });
}


