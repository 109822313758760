import { useState } from "react";
import FlatFilePayload from "./DownloadFlatFile.interface";
import { fetchDataToDownloadFlatFile } from "./DownloadFlatFile.service";
import Loading from "../Loading.component";
import Alert from "../Alert/Alert.component";
import { buildGenericXlsx } from "../../services/genericXlsxBuilder.service";
import Separator from "../Separator.component";

const DownloadFlatFile = (props: any) => {
    const {documents, disabled} = props;

    const [alert, setAlert] = useState({type: '', message: ''});
    const [loadingFlatFileData, setLoadingFlatFileData] = useState(false);
    const [, setFlatDataRows] = useState([]);
    const [fileUrls, setFileUrls] = useState([]);

    const _generateFile = async () => {
        setLoadingFlatFileData(true);
        setFileUrls([]);
        await fetchDataToDownloadFlatFile(props as FlatFilePayload).then(async (response: any) => {
            if (response.message === "OK") {
                if (props.project === "echem") {
                    setFlatDataRows(response.data);
                    await buildGenericXlsx(response.data).then((buffer: Buffer) => {
                        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `FlatFile-${props.project}-${documents.length}.xlsx`;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    });

                    setLoadingFlatFileData(false);
                    setAlert({type: 'success', message: 'Flat file generated successfully'});
                    return;
                } else if (props.project === "ecrf") {
                    /**
                     * ECRF returns a list of file urls
                     * That are already stored in the Firebase Storage Bucket.
                     */
                    setFileUrls(response.data.data);
                    setLoadingFlatFileData(false);
                    return;
                }
            }

            setLoadingFlatFileData(false);
            console.log("error >> ", response);
            setAlert({type: 'danger', message: 'Error generating flat file'});
        }).catch((error: any) => {
            console.log("error >> ", error);
            setAlert({type: 'danger', message: 'Catch error generating flat file'});
            setLoadingFlatFileData(false);
        });
    };

    return <>
        <Alert alert={alert} />

        <button
            className="btn btn-primary"
            disabled={documents.length === 0 || disabled || loadingFlatFileData}
            onClick={() => _generateFile()}>
                <Loading parent="inline" loading={loadingFlatFileData} color="text-white" />
                {!loadingFlatFileData && <><i className="fas fa-file-excel me-2"></i></>} 
                Download Flat File ({documents.length} documents) <small><i>&mdash; beta</i></small>
        </button>

        {fileUrls.length > 0 && <>
            <Separator size={10} />
            <div className="d-inline">
                {fileUrls.map((fileUrl: string, index: number) => {
                    return <a href={fileUrl} key={`download-flat-file-ecrf-${index}`} target="_blank" rel="noreferrer" className="btn btn-outline-primary btn-sm me-2 mb-2">
                        <i className="fas fa-file-download me-2"></i> Download File {index+1}
                    </a>
                })}
            </div>
        </>}
    </>
};

export default DownloadFlatFile;