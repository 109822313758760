import { useState } from "react";
const SetAxisRangeToSaveSession = (props: any) => {
    const [axisRangeToggle, setAxisRangeToggle] = useState(1)
    return <>
        <div className="row align-items-center">
            <div className="col text-start">
                <label>
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        value={axisRangeToggle} 
                        onChange={() => {
                            setAxisRangeToggle(axisRangeToggle === 1 ? 0 : 1);
                            props.setAxisRangeToSaveSession(axisRangeToggle);
                        }}
                    />
                    <small className="text-muted"> Set X axis to 0 (zero) </small>
                </label>
            </div>
        </div>
    </>
}

export default SetAxisRangeToSaveSession;