import React, { useEffect, useState, CSSProperties } from "react";
import { canAccess, flattenAndFilter } from "../../services/helpers";
import Alert from "../../components/Alert/Alert.component";
import Separator from "../../components/Separator.component";
import Messages from "../../components/Messages/Messages.component";
import HistoryVersions from "../../components/HistoryVersions/HistoryVersions.component";
import { getCencalReports, toggleMultiplePatientApproval, togglePatientApproval } from "../../services/sti.service";
import dayjs from "dayjs";
import { Modal } from "react-bootstrap";
import Loading from "../../components/Loading.component";
import { getProtocols } from "../../services/ecrf.service";

// Using https://reactdatagrid.io/
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

import Bugsnag from '@bugsnag/js';

const CencalReports = (props: any) => {
  // Do not add any other code here
  useEffect(() => {
    if (!canAccess(props.user, 'cencalReports')) {
        window.location.href = '/home';
        return;
    }
  }, [props.user, props.user.role]);

  const {user} = props;

  const [alert, setAlert] = useState({ type: '', message: '' });

  // Bugsnag notification PATIENT APPROVAL
  const _notifyBugsnag = (patient: any, message: string) => {
    Bugsnag.notify(new Error(message || ""), function(event: any) {
      event.context = "Cencal Reports > togglePatientApproval";
      event.severity = "info";
      event.unhandled = false;
      event.errors[0].errorClass = "Notification";
      event.setUser(user.id || "", user.email || "", user.name || "");
      event.addMetadata("Details", {
        Patient: patient.id || "",
        Collection: patient.protocol_nameCollection || "",
      });
    });
  };

  useEffect(() => {
    _getReports();
    _getProcols();
  }, []);

  const [currentProtocol, setCurrentProtocol] = useState("");
  const [protocols, setProtocols] = useState([] as any[]);
  const [loadingProtocols, setLoadingProtocols] = useState(false);
  const _getProcols = async () => {
    setLoadingProtocols(true);
    const response: any = await getProtocols().then((r: any) => r).catch((err: any) => err);
    if (response.message === "OK") {
      setProtocols(response.protocols);
      setLoadingProtocols(false);
      return;
    }

    Bugsnag.notify(new Error("Error loading protocols"), function(event: any) {
      event.context = "Cencal Reports > Protocols";
      event.severity = "error";
      event.unhandled = false;
      // event.errors[0].errorClass = "Notification";
      event.setUser(user.id || "", user.email || "", user.name || "");
    });

    console.log("Error loading protocols", response);
    setLoadingProtocols(false);
  };

  const _filterDataByProcol = (data: any) => {
    const metrixProtocolId: string = process.env.REACT_APP_CENCAL_REPORTS_COLUMNS_METRIX || '';
    const stiProtocolId: string = process.env.REACT_APP_CENCAL_REPORTS_COLUMNS_STI || '';
    const respiratoryProtocolId: string = process.env.REACT_APP_CENCAL_REPORTS_COLUMNS_RESPIRATORY || '';
    let newData: any = [];

    if (currentProtocol === "") {
      newData = data;
    } else {
      if (currentProtocol === metrixProtocolId) {
        newData = data.filter((p: any) => p.protocol_nameCollection === "metrixCovidFlu_clinicalTrialData");
        console.log("SHOW ONLY PROTOCOL metrixCovidFlu_clinicalTrialData")
      } else if (currentProtocol === stiProtocolId) {
        newData = data.filter((p: any) => p.protocol_nameCollection === "sti_clinicalTrialData");
        console.log("SHOW ONLY PROTOCOL sti_clinicalTrialData")
      } else if (currentProtocol === respiratoryProtocolId){
        newData = data.filter((p: any) => p.protocol_nameCollection === "clinicalRespiratorySampleCollection_clinicalTrialData");
        console.log("SHOW ONLY PROTOCOL clinicalRespiratorySampleCollection_clinicalTrialData")
      }
    }

    return newData;
  };

  const [loadingReports, setLoadingReports] = useState(false);
  const _getReports = async () => {
    setLoadingReports(true);
    const payload: any = {
      dateRange: {
        from: "2024-01-01",
        to: "2024-12-31",
      },
    };
    await getCencalReports(payload).then((response: any) => {
      if (response.message === "OK") {
        setPatientsImmutable(response.patients);

        const newResponsePatients: any = _filterDataByProcol(response.patients);
        setPatients(newResponsePatients);

        setLoadingReports(false);
        return;
      }

      Bugsnag.notify(new Error("Error loading reports"), function(event: any) {
        event.context = "Cencal Reports > Reports > Then";
        event.severity = "error";
        event.unhandled = false;
        event.setUser(user.id || "", user.email || "", user.name || "");
      });

      console.log("Error response", response);
      setAlert({ type: 'danger', message: 'Error fetching Cencal reports' });
      setLoadingReports(false);
    }).catch((err: any) => {

      Bugsnag.notify(new Error("Error loading reports"), function(event: any) {
        event.context = "Cencal Reports > Reports > Catch";
        event.severity = "error";
        event.unhandled = false;
        // event.errors[0].errorClass = "Notification";
        event.setUser(user.id || "", user.email || "", user.name || "");
      });

      console.log("Error", err);
      setLoadingReports(false);
      setAlert({ type: 'danger', message: 'Error fetching Cencal reports' });
    });
  };

  const [patients, setPatients] = useState([] as any[]);
  const [patientsImmutable, setPatientsImmutable] = useState([] as any[]);

  const [selectedRows, setSelectedRows] = useState(null as any);

  const gridStyle = { minHeight: 600 };

  const getApprovalStyle = ({ value }: { value: any }) => {
    const cellStyleGreen: CSSProperties = { 
      backgroundColor: "green",
      color: "white",
      textAlign: "center",
      borderRadius: "5px",
    };

    const cellStyleGray: CSSProperties = { 
      backgroundColor: "#efefef",
      color: "white",
      textAlign: "center",
      borderRadius: "5px",
    };

    if (value == true) {
      return <div style={cellStyleGreen}>Approved</div>
    } else {
      return <div style={cellStyleGray}>Not Approved</div>
    }
  }

  const patientsColumns: any[] = [
    {
      name: 'isApproved',
      header: 'Approved',
      minWidth: 120,
      defaultFlex: 1,
      render: getApprovalStyle
    },
    { name: 'siteNo', header: 'Site No', minWidth: 20, defaultFlex: 0, },
    { name: 'patientIdComposite', header: 'Patient ID', minWidth: 120, defaultFlex: 1, },
    { name: 'name', header: 'Name', minWidth: 220, defaultFlex: 1, },
    { name: 'protocol', header: 'Protocol', minWidth: 170, defaultFlex: 1, },
    { name: 'collectedBy', header: 'Collected By', minWidth: 140, defaultFlex: 1, },
    {
      name: 'sampleCollectionTime', header: 'Collection Time', minWidth: 170, defaultFlex: 1,
      render: ({value}: any) => value ? dayjs(value).utc().format('MM/DD/YYYY HH:mm:ss') : "",
    },
    {
      name: 'npSampleTime', header: 'NP Sample Time', minWidth: 160, defaultFlex: 1,
      render: ({value}: any) => value ? dayjs(value).utc().format('MM/DD/YYYY HH:mm:ss') : "",
    },
    { name: 'readerLot', header: 'Reader Lot', minWidth: 120, defaultFlex: 1, },
    { name: 'readerId', header: 'Reader Id', minWidth: 120, defaultFlex: 1, },
    { name: 'kitLot', header: 'Kit Lot', minWidth: 120, defaultFlex: 1, },
    { name: 'participantInterpretedResult', header: 'Participant Int. Result', minWidth: 200, defaultFlex: 1, },
    { name: 'coordinatorInterpretedResult', header: 'Coordinator Int. Result', minWidth: 200, defaultFlex: 1, },
    { name: 'repeatParticipantInterpretedResult', header: 'Repeat Part. Int. Result', minWidth: 210, defaultFlex: 1, },
    { name: 'repeatCoordinatorInterpretedResult', header: 'Repeat Coord. Int. Result', minWidth: 220, defaultFlex: 1, },
    {
      name: 'symptomOnsetDate', header: 'Symptom Onset Date', minWidth: 170, defaultFlex: 1,
      render: ({value}: any) => value ? dayjs(value).format('MM/DD/YYYY HH:mm:ss') : "",
    },
    { name: 'comments', header: 'Comments', minWidth: 1000, defaultFlex: 1, },
    { name: 'hasErrorOrDeviation', header: 'Has Error or Deviation', minWidth: 150, defaultFlex: 1, render: ({value}: any) => value ? 'Yes' : 'No'},
    {
      name: 'submited', header: 'Submited', minWidth: 120, defaultFlex: 1,
      render: ({value}: any) => value ? 'Yes' : 'No'
    },
  ];

  const MetrixColumns: any[] = [
    {
      name: 'isApproved',
      header: 'Approved',
      minWidth: 120,
      defaultFlex: 1,
      render: getApprovalStyle
    },
    { name: 'siteNo', header: 'Site No', minWidth: 20, defaultFlex: 0, },
    { name: 'patientIdComposite', header: 'Patient ID', minWidth: 120, defaultFlex: 1, },
    { name: 'name', header: 'Name', minWidth: 220, defaultFlex: 1, },
    { name: 'protocol', header: 'Protocol', minWidth: 170, defaultFlex: 1, },
    { name: 'collectedBy', header: 'Collected By', minWidth: 140, defaultFlex: 1, },
    {
      name: 'sampleCollectionTime', header: 'Collection Time', minWidth: 170, defaultFlex: 1,
      render: ({value}: any) => value ? dayjs(value).utc().format('MM/DD/YYYY HH:mm:ss') : "",
    },
    {
      name: 'npSampleTime', header: 'NP Sample Time', minWidth: 160, defaultFlex: 1,
      render: ({value}: any) => value ? dayjs(value).utc().format('MM/DD/YYYY HH:mm:ss') : "",
    },
    { name: 'readerLot', header: 'Reader Lot', minWidth: 120, defaultFlex: 1, },
    { name: 'readerId', header: 'Reader Id', minWidth: 120, defaultFlex: 1, },
    { name: 'kitLot', header: 'Kit Lot', minWidth: 120, defaultFlex: 1, },
    { name: 'participantInterpretedResult', header: 'Participant Int. Result', minWidth: 200, defaultFlex: 1, },
    { name: 'coordinatorInterpretedResult', header: 'Coordinator Int. Result', minWidth: 200, defaultFlex: 1, },
    { name: 'repeatParticipantInterpretedResult', header: 'Repeat Part. Int. Result', minWidth: 210, defaultFlex: 1, },
    { name: 'repeatCoordinatorInterpretedResult', header: 'Repeat Coord. Int. Result', minWidth: 220, defaultFlex: 1, },
    {
      name: 'symptomOnsetDate', header: 'Symptom Onset Date', minWidth: 170, defaultFlex: 1,
      render: ({value}: any) => value ? dayjs(value).format('MM/DD/YYYY HH:mm:ss') : "",
    },
    { name: 'comments', header: 'Comments', minWidth: 1000, defaultFlex: 1, },
    { name: 'hasErrorOrDeviation', header: 'Has Error or Deviation', minWidth: 150, defaultFlex: 1, render: ({value}: any) => value ? 'Yes' : 'No'},
    {
      name: 'submited', header: 'Submited', minWidth: 120, defaultFlex: 1,
      render: ({value}: any) => value ? 'Yes' : 'No'
    },
  ];

  const StiColumns: any[] = [
    {
      name: 'isApproved',
      header: 'Approved',
      minWidth: 120,
      defaultFlex: 1,
      render: getApprovalStyle
    },
    { name: "customParticipantId", header: "Patient ID", minWidth: 120, defaultFlex: 1, },
    { name: 'protocol', header: 'Protocol', minWidth: 170, defaultFlex: 1, },
    { name: 'collectedBy', header: 'Collected By', minWidth: 140, defaultFlex: 1, },
    {
      name: 'sampleCollectionTime', header: 'Collection Time', minWidth: 170, defaultFlex: 1,
      render: ({value}: any) => value ? dayjs(value).utc().format('MM/DD/YYYY HH:mm:ss') : "",
    },
    { name: "numberSwabsCollected", header: "Number of Swabs", minWidth: 160, defaultFlex: 1, },
    {
      name: "medicalSexualHistory_previousDiagnosisTV",
      header: "Previous TV Diagnosis",
      minWidth: 160,
      defaultFlex: 1,
      render: ({value}: any) => value ? 'Yes' : 'No',
    },
    {
      name: "medicalSexualHistory_previousDiagnosisCT",
      header: "Previous CT Diagnosis",
      minWidth: 160,
      defaultFlex: 1,
      render: ({value}: any) => value ? 'Yes' : 'No',
    },
    {
      name: "medicalSexualHistory_previousDiagnosisNG",
      header: "Previous NG Diagnosis",
      minWidth: 160,
      defaultFlex: 1,
      render: ({value}: any) => value ? 'Yes' : 'No',
    },
    {
      name: "medicalSexualHistory_symptomaticForInfection",
      header: "Symptomatic",
      minWidth: 160,
      defaultFlex: 1,
      render: ({value}: any) => value ? 'Yes' : 'No',
    },
    { name: "comments", header: "comments", minWidth: 120, defaultFlex: 1, },
    // { name: "standardOfCareResults_protocolId", header: "standardOfCareResults_protocolId", minWidth: 120, defaultFlex: 1, },
    { name: "standardOfCareResults_testName", header: "SOC Test Name", minWidth: 160, defaultFlex: 1, },
    { name: "standardOfCareResults_resultTV", header: "SOC TV Result", minWidth: 160, defaultFlex: 1, },
    { name: "standardOfCareResults_resultCT", header: "SOC CT Result", minWidth: 160, defaultFlex: 1, },
    { name: "standardOfCareResults_resultNG", header: "SOC NG Result", minWidth: 160, defaultFlex: 1, },
    { name: "standardOfCareResults_commentsStandardCareOfResults", header: "SOC Comments", minWidth: 160, defaultFlex: 1, },
    { name: "storageTemperature", header: "Storage Temperature", minWidth: 160, defaultFlex: 1, },

    // { name: 'siteNo', header: 'Site No', minWidth: 20, defaultFlex: 0, },
    // {
    //   name: "medicalSexualHistory_sexuallyActive",
    //   header: "medicalSexualHistory_sexuallyActive",
    //   minWidth: 120,
    //   defaultFlex: 1,
    //   render: ({value}: any) => value ? 'Yes' : 'No',
    // },
    // {
    //   name: "medicalSexualHistory_useOfItems",
    //   header: "medicalSexualHistory_useOfItems",
    //   minWidth: 120,
    //   defaultFlex: 1,
    //   render: ({value}: any) => value ? 'Yes' : 'No',
    // },
    // {
    //   name: "medicalSexualHistory_useOfAntibiotics",
    //   header: "medicalSexualHistory_useOfAntibiotics",
    //   minWidth: 120,
    //   defaultFlex: 1,
    //   render: ({value}: any) => value ? 'Yes' : 'No',
    // },
    // { name: "standardOfCareResults_siteNo", header: "standardOfCareResults_siteNo", minWidth: 120, defaultFlex: 1, },
    // { name: "exclusionCriteria_unableToMeetRequirements", header: "exclusionCriteria_unableToMeetRequirements", minWidth: 120, defaultFlex: 1, },
    // { name: "exclusionCriteria_useOfItems", header: "exclusionCriteria_useOfItems", minWidth: 120, defaultFlex: 1, },
    // { name: "exclusionCriteria_previouslyEnrolled", header: "exclusionCriteria_previouslyEnrolled", minWidth: 120, defaultFlex: 1, },
    // { name: "exclusionCriteria_useOfAntibiotics", header: "exclusionCriteria_useOfAntibiotics", minWidth: 120, defaultFlex: 1, },
  ];

  const RespiratoryColumns: any[] = [
    {
      name: 'isApproved',
      header: 'Approved',
      minWidth: 120,
      defaultFlex: 1,
      render: getApprovalStyle
    },
    { name: 'protocol', header: 'Protocol', minWidth: 170, defaultFlex: 1, },
    { name: 'readerLot', header: 'Reader Lot', minWidth: 120, defaultFlex: 1, },
    { name: 'readerId', header: 'Reader Id', minWidth: 120, defaultFlex: 1, },
  ];

  const patientsColumnsFilter: any[] = [
      { name: 'siteNo', operator: 'startsWith', type: 'string', value: '', },
      { name: 'patientIdComposite', operator: 'startsWith', type: 'string', value: '', },
      { name: 'name', operator: 'startsWith', type: 'string', value: '', },
      { name: 'protocol', operator: 'startsWith', type: 'string', value: '', },
      { name: 'collectedBy', operator: 'startsWith', type: 'string', value: '', },
      { name: 'npSampleTime', operator: 'startsWith', type: 'string', value: '', },
      { name: 'readerLot', operator: 'startsWith', type: 'string', value: '', },
      { name: 'readerId', operator: 'startsWith', type: 'string', value: '', },
      { name: 'kitLot', operator: 'startsWith', type: 'string', value: '', },
      { name: 'participantInterpretedResult', operator: 'startsWith', type: 'string', value: '', },
      { name: 'coordinatorInterpretedResult', operator: 'startsWith', type: 'string', value: '', },
      { name: 'repeatParticipantInterpretedResult', operator: 'startsWith', type: 'string', value: '', },
      { name: 'repeatCoordinatorInterpretedResult', operator: 'startsWith', type: 'string', value: '', },
      { name: 'symptomOnsetDate', operator: 'startsWith', type: 'string', value: '', },
      { name: 'comments', operator: 'startsWith', type: 'string', value: '', },
      { name: 'hasErrorOrDeviation', operator: 'startsWith', type: 'string', value: '', },
      { name: 'submited', operator: 'startsWith', type: 'string', value: '', },
  ];

  const [showModal, setShowModal] = useState(false);

  const [columnsToDisplay, setColumnsToDisplay] = useState(patientsColumns); // Default - All columns
  const _changeProtocolView = (protocolId: string) => {
    const metrixProtocolId: string = process.env.REACT_APP_CENCAL_REPORTS_COLUMNS_METRIX || '';
    const stiProtocolId: string = process.env.REACT_APP_CENCAL_REPORTS_COLUMNS_STI || '';
    const respiratoryProtocolId: string = process.env.REACT_APP_CENCAL_REPORTS_COLUMNS_RESPIRATORY || '';

    let columns: any = "";

    switch (protocolId) {
      case "": columns = patientsColumns;
      break;

      case metrixProtocolId: columns = MetrixColumns; // Metrix COVID/Flu
      break;

      case stiProtocolId: columns = StiColumns; // STI
      break;

      case respiratoryProtocolId: columns = RespiratoryColumns; // Clinical Respiratory Sample Collection
      break;

      default: columns = patientsColumns;
    }

    setCurrentProtocol(protocolId);
    setColumnsToDisplay(columns);
  };

  useEffect(() => {
    const newResponsePatients: any = _filterDataByProcol(patientsImmutable);
    setPatients(newResponsePatients);
  }, [currentProtocol]);

  const [loadingToggleApproval, setLoadingToggleApproval] = useState(false);
  const _togglePatientApproval = async (patient: any) => {
    setLoadingToggleApproval(true);
    const response: any = await togglePatientApproval({ patientId: patient.id, protocolNameCollection: patient.protocol_nameCollection }).then((r: any) => r).catch((e: any) => e);

    if (response.message === "OK") {
      if (response.newApprovalStatus === null) {
        _notifyBugsnag(patient, "Approval status is null");
        console.log("Something went wrong. Approval status is null");
        return;
      }

      // Update patient status in the list
      const thePatient: any = patients.find((p: any) => p.id === patient.id);
      thePatient.isApproved = response.newApprovalStatus;
      setPatients([...patients]);
      setSelectedRows(thePatient);
      setAlert({ type: "success", message: "Patient approval status has been updated" });
      setLoadingToggleApproval(false);
      return;
    }

    _notifyBugsnag(patient, "Error on updating patient approval status");

    setAlert({ type: "danger", message: "Error on updating patient approval status" });
    setLoadingToggleApproval(false);
  };


  // When Batch Selecting is enabled
  // Add the first column to select multiple patients
  const [isBatchSelecting, setIsBatchSelecting] = useState(false);
  const [loadingToggleMultipleApproval, setLoadingToggleMultipleApproval] = useState(false);
  const toggleApproval = async () => {
    setLoadingToggleMultipleApproval(true);

    const payload: any[] = selectedRows.map((p: any) => {
      return {
        patientId: p.id,
        protocolNameCollection: p.protocol_nameCollection,
      };
    });

    const response: any = await toggleMultiplePatientApproval(payload).then((r: any) => r).catch((e: any) => e);
    if (response.message === "OK") {
      // Update patients status in the list
      payload.forEach((p: any) => {
        const thePatient: any = patients.find((patient: any) => patient.id === p.patientId);
        thePatient.isApproved = thePatient.isApproved ? false : true;
      });

      setPatients([...patients]);
      setAlert({ type: "success", message: "Patients approval status has been updated" });
      setLoadingToggleMultipleApproval(false);
      return;
    }

    setAlert({ type: "danger", message: "Error on updating patients approval status" });
    setLoadingToggleMultipleApproval(false);
  };

  // STI
  const columnNames: string[] = [
    "id",
    "siteNo",
    "name",
    "protocol",
    "protocol_nameCollection",
    "collectedBy",
    "patientIdComposite",
    "sampleCollectionTime",
    "patientId",
    "npSampleTime",
    "readerLot",
    "readerId",
    "kitLot",
    "participantInterpretedResult",
    "coordinatorInterpretedResult",
    "repeatParticipantInterpretedResult",
    "repeatCoordinatorInterpretedResult",
    "symptomOnsetDate",
    "isApproved",
    "customParticipantId",
    "medicalSexualHistory_previousDiagnosisTV",
    "medicalSexualHistory_previousDiagnosisCT",
    "medicalSexualHistory_sexuallyActive",
    "medicalSexualHistory_symptomaticForInfection",
    "medicalSexualHistory_previousDiagnosisNG",
    "standardOfCareResults_protocolId",
    "standardOfCareResults_resultCT",
    "standardOfCareResults_siteNo",
    "standardOfCareResults_resultTV",
    "standardOfCareResults_resultNG",
    "standardOfCareResults_testName",
    "standardOfCareResults_commentsStandardCareOfResults",
    "exclusionCriteria_unableToMeetRequirements",
    "exclusionCriteria_useOfItems",
    "exclusionCriteria_previouslyEnrolled",
    "exclusionCriteria_useOfAntibiotics",
    "inclusionCriteria_fourteenOrOlder",
    "inclusionCriteria_sexualIntercoursePast6Months",
    "inclusionCriteria_ableToConsent",
    "inclusionCriteria_ableToProvideVaginalSwabs",
    "storageTemperature",
    "comments",
    "numberSwabsCollected",
  ];

  // STI
  const columnNamesDisplay: string[] = [
    "_",
    "Site No",
    "Name",
    "Protocol",
    "_",
    "Collected By",
    "Patient ID",
    "Collection Time",
    "_",
    "_",
    "Reader Lot",
    "Reader ID",
    "Kit Lot",
    "Participant Interpreted Result",
    "Coordinator Interpreted Result",
    "Repeat Participant Interpreted Result",
    "Repeat Coordinator Interpreted Result",
    "Symptom Onset Date",
    "Approved",
    "Participant ID",
    "Previous TV Diagnosis",
    "Previous CT Diagnosis",
    "Sexually Active",
    "Symptomatic",
    "Previous NG Diagnosis",
    "SOC Protocol ID",
    "SOC Result CT",
    "SOC Site No",
    "SOC TV Result",
    "SOC NG Result",
    "SOC Test Name",
    "SOC Comments",
    "Exclusion Criteria Unable to meet requirements",
    "Exclusion Criteria Use of items",
    "Exclusion Criteria Previously enrolled",
    "Exclusion Criteria Use of antibiotics",
    "Inclusion Criteria Fourteen or older",
    "Inclusion Criteria Sexual intercourse past 6 months",
    "Inclusion Criteria Able to consent",
    "Inclusion Criteria Able to provide vaginal swabs",
    "Storage Temperature",
    "Comments",
    "Number of Swabs",
  ];

  return <div className="container">
    <h2>Cencal Labs Reports</h2>
    <p className="text-muted">
      Data visualization from the Cencal Labs website.
    </p>

    <Alert alert={alert} />

    <Separator size={30} />

    <div className="row">
      <div className="col-3">

        <label>Select a protocol to filter data</label>
        <select
          disabled={loadingProtocols || protocols.length === 0}
          value={currentProtocol}
          onChange={(e) => _changeProtocolView(e.target.value)}
          className="form-control">
            <option value="">All Protocols</option>
            {protocols.map((protocol: any, index: number) => <option key={`filter-protocol-${protocol.id}-${index}`} value={protocol.id}>{protocol.name}</option>)}
        </select>

      </div>
    </div>

    <Separator size={30} />

    <Loading loading={loadingReports} />

    {!loadingReports && patients.length > 0 && <>

      <div className="row">
        <div className="col">

          <small className="text-muted">
            Protocol: <strong>{currentProtocol === "" ? "All Protocols" : protocols.find((item: any) => item.id == currentProtocol).name}</strong>
          </small>

        </div>
        <div className="col text-end">

          <div className="form-check form-switch">
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={isBatchSelecting}
                  disabled={loadingToggleMultipleApproval}
                  onChange={() => setIsBatchSelecting(!isBatchSelecting)}
                  id="flexSwitchCheckDefault" />
                Batch Select
            </label>
          </div>

          <Separator size={10} />

          <button
            type="button"
            onClick={() => toggleApproval()}
            disabled={!isBatchSelecting || !selectedRows || loadingToggleMultipleApproval}
            className="btn btn-outline-primary btn-sm me-2">
              <Loading loading={loadingToggleMultipleApproval} parent="inline" color="text-primary" />
              {!loadingToggleMultipleApproval && <i className="fas fa-sync me-2"></i>} Toggle Approval Selected
          </button>

        </div>
      </div>

      <Separator size={20} />

      <ReactDataGrid
        idProperty="patientIdComposite"
        style={gridStyle}
        columns={columnsToDisplay}
        dataSource={patients}
        defaultFilterValue={patientsColumnsFilter}
        editable={true}
        enableSelection
        multiSelect={isBatchSelecting ? true : false}
        enableKeyboardNavigation={true}
        onSelectionChange={(selection: any) => {
          if (isBatchSelecting) {
            const newData: any[] = [];
            // selection.selected is a object of objects (selection.selected: {object1, object2, etc})
            for (let key in selection.selected) {
              newData.push(selection.selected[key]);
            }
            setSelectedRows(newData);
          } else {
            if (selection.selected !== null && selection.selected.length > 0) {
              const newData: any = flattenAndFilter(selection.data, "demographics");
              setSelectedRows(newData);
              setShowModal(true);
            }
          }
        }}
      />
    </>}

    {/* MODAL SELECTED PATIENT */}

    <Modal show={showModal} size="xl">
      <Modal.Header closeButton onClick={() => setShowModal(false)}>
        <Modal.Title>Subject Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="text-end">
          {selectedRows && !selectedRows.isApproved && <>
            <button
              type="button"
              disabled={loadingToggleApproval}
              onClick={() => _togglePatientApproval(selectedRows)}
              className="btn btn-primary me-2">
                <Loading loading={loadingToggleApproval} parent="inline" color="text-white" />
                {!loadingToggleApproval && <i className="fas fa-check-circle me-2"></i>}
                Approve Patient
            </button>
          </>}

          {selectedRows && selectedRows.isApproved && <>
            <button
              type="button"
              disabled={loadingToggleApproval}
              onClick={() => _togglePatientApproval(selectedRows)}
              className="btn btn-warning">
                <Loading loading={loadingToggleApproval} parent="inline" color="text-dark" />
                {!loadingToggleApproval && <i className="fas fa-ban me-2"></i>}
                Unapprove Patient
            </button>
          </>}
        </div>

        <Separator size={10} />

        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="review-tab" data-bs-toggle="tab" data-bs-target="#review-tab-pane" type="button" role="tab" aria-controls="review-tab-pane" aria-selected="true">Review</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="messages-tab" data-bs-toggle="tab" data-bs-target="#messages-tab-pane" type="button" role="tab" aria-controls="messages-tab-pane" aria-selected="false">Messages</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="history-tab" data-bs-toggle="tab" data-bs-target="#history-tab-pane" type="button" role="tab" aria-controls="history-tab-pane" aria-selected="false">History</button>
          </li>
        </ul>

        {selectedRows !== null && <>

          <Separator size={30} />

          <div className="tab-content" id="myTabContent">
            <div className="tab-pane show active" id="review-tab-pane" role="tabpanel" aria-labelledby="review-tab">

              {/*
              <pre>
                {JSON.stringify(selectedRows, null, 2)}
              </pre>
              */}

              <ul className="list-unstyled row">
                {Object.keys(selectedRows).map((key: string, index: number) => {
                  return <React.Fragment key={`reports-${key}-${index}`}>
                    {columnNamesDisplay[columnNames.indexOf(key)] !== "_" && <>
                      <li key={`${key}-${index}`} className="col-6">
                        {/* <strong>{key}: </strong> {JSON.stringify(selectedRows[key], null, 2)} */}
                        <strong>{columnNamesDisplay[columnNames.indexOf(key)]}: </strong> {selectedRows[key]}
                      </li>
                    </>}
                  </React.Fragment>
                })}
              </ul>

            </div>
            <div className="tab-pane" id="messages-tab-pane" role="tabpanel" aria-labelledby="messages-tab">

              <Messages user={props.user} patientDocId={selectedRows.id} patient={selectedRows} />

            </div>
            <div className="tab-pane" id="history-tab-pane" role="tabpanel" aria-labelledby="history-tab">

              <HistoryVersions user={props.user} patientDocId={selectedRows.id} patient={selectedRows} />

            </div>
          </div>
        </>}

      </Modal.Body>
    </Modal>

  </div>
};

export default CencalReports;