import xlsx from "node-xlsx";

export const buildXlsxDataComparison = (payload: any): Promise<Buffer> => {
  let headerRowOne = [
    "Document",
    "Document",
    "Document",
    "Document",
    "Document",
    "Document",
    `Reader (${payload.selectedChannel.toUpperCase()})`,
    `Reader (${payload.selectedChannel.toUpperCase()})`,
    `Reader (${payload.selectedChannel.toUpperCase()})`,
    `Reader (${payload.selectedChannel.toUpperCase()})`,
  ];

  let headerRowTwo = [
    "docId",
    "start_time",
    "reader_info_name",
    "filename",
    "reader_id",
    "UserMarkedAs",
    "CT",
    "PR",
    "SD",
    "ReaderCalling"
  ];

  const algorithm = payload.selectedAlgorithms.filter(
    (item: any, index: any) =>
      payload.selectedAlgorithms.indexOf(item) === index
  );
  const algorithmFields = ["CT", "PR", "SD"];
  let ranges: any[] = [];
  const headerRowOneRanges = [
    { s: { c: 0, r: 0 }, e: { c: 5, r: 0 } },
    { s: { c: 6, r: 0 }, e: { c: 9, r: 0 } },
  ];
  const sheetOptions = { "!merges": [ranges] };

  algorithm.forEach((algorithm: any, index: number) => {
    headerRowOne = [
      ...headerRowOne,
      ...algorithmFields.map((field: string) => `${algorithm}`),
    ];
    headerRowOneRanges.push({
      s: { c: 10 + index * 3, r: 0 },
      e: { c: 12 + index * 3, r: 0 },
    });

    headerRowTwo = [
      ...headerRowTwo,
      ...algorithmFields.map((field: string) => `${field}`),
    ];
  });

  ranges = [...headerRowOneRanges];
  sheetOptions["!merges"] = ranges as any;

  function getSheets(data: any) {
    const sheets = [] as any;
    const filename = "Data Comparison";
    const newSheet = [] as any;
    newSheet.push(headerRowOne);
    newSheet.push(headerRowTwo);
    data.forEach((row: any) => {
      const newRow = [] as any;
      newRow.push(row.docId);
      newRow.push(row.start_time);
      newRow.push(row.reader_info_name);
      newRow.push(row.filename);
      newRow.push(row.reader_id);
      newRow.push(row.channelUserMarkedAs);
      newRow.push(row.channelCT);
      newRow.push(row.channelPR);
      newRow.push(row.channelSD);
      newRow.push(row.channelReaderCalling);
      algorithm.forEach((algorithm: any) => {
        algorithmFields.forEach((field: any) => {
          newRow.push(row[`${algorithm}_${field}`]);
        });
      });
      newSheet.push(newRow);
    });
    sheets.push({
      name: `${filename}`,
      data: newSheet,
      options: sheetOptions,
    });
    return sheets;
  }

  return new Promise<Buffer>((resolve, reject) => {
    const { data } = payload;
    const buffer = xlsx.build(getSheets(data));
    resolve(buffer);
  });
};
