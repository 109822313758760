import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Alert from "../Alert/Alert.component";
import Separator from "../Separator.component";
import { useFormik } from "formik";
import * as yup from 'yup';
import { documentsManagement } from "../../services/data.service";
import Loading from "../Loading.component";
import { RemoteConfigContext } from "../../context/remoteConfig.context";
import EchemQueueViewer from "../EchemQueueViewer/EchemQueueViewer.component";

const DocumentsManagement = (props: any) => {
    const {
        selectedRows,
        collection,
        onRemoveDocuments,
    } = props;

    const remoteConfig: any = useContext(RemoteConfigContext);
    const lastDatabaseBackup: string = remoteConfig.remoteConfig?.lastDatabaseBackup || 'Unknown';

    const [alert, setAlert] = useState<any>({ show: false, message: '', type: '' });
    const [showModalAlgorithms, setShowModalAlgorithms] = useState(false);

    const _openModalDocsManagement = () => {
        setShowModalAlgorithms(true);
    };

    useEffect(() => {
        if (!showModalAlgorithms) {
            formDocs.resetForm();
        }
    }, [showModalAlgorithms]);

    const [loadingManaging, setLoadingManaging] = useState(false);

    const _defaultOptionsForm: any = {
        sourceCollection: collection || "usb_data",
        documents: selectedRows.map((row: any) => row.id) || [],
        action: '',
        destination: '',
        inputConfirm: '',
    };

    const formDocs: any = useFormik({
        initialValues: _defaultOptionsForm,
        isInitialValid: false,
        validateOnChange: true,
        validationSchema: yup.object({
            sourceCollection: yup.string().required().oneOf(['usb_data', 'dev_data', 'clinical_data', 'archive_data']),
            documents: yup.array(
                yup.string().required()
            ).required()
            .min(1, 'Select at least one document'),
            action: yup.string().required().oneOf(['copy', 'move', 'delete']),
            destination: yup.string().when('action', ([action], schema) => {
                if (action === "delete") {
                    return schema.notRequired();
                }
                return schema.required().oneOf(['usb_data', 'dev_data', 'clinical_data', 'archive_data']);
            }),
            inputConfirm: yup.string().required().test('confirm', 'Type the action to confirm', (value: any) => {
                return value.toString().toUpperCase() === `${formDocs.values.action.toUpperCase()} ${formDocs.values.documents.length}`;
            }),
        }),
        onSubmit: async (values) => {
            setLoadingManaging(true);
            await documentsManagement(values).then((response: any) => {
                if (response.message === "OK") {
                    setLoadingManaging(false);
                    formDocs.setFieldValue('inputConfirm', '');

                    let alertMessageRefresh: string = '';

                    // On operations [move, delete], send the selected documents to the parent component
                    // to remove them from the table
                    if (["move", "delete"].includes(values.action)) {
                        onRemoveDocuments(values.documents);
                        alertMessageRefresh = `<br />Some documents were changed.<br /><strong>Click "Filter" to update results.</strong>`;
                    }

                    setAlert({ type: 'success', message: `Documents managed successfully ${alertMessageRefresh}` });
                    return;
                }

                setLoadingManaging(false);
                setAlert({ type: 'danger', message: 'Error managing documents' });
            }).catch((err: any) => {
                console.log("ERROR", err);
                setLoadingManaging(false);
                setAlert({ type: 'danger', message: 'Catch error managing documents. Check logs' });
            });
        }
    });

    useEffect(() => {
        formDocs.setFieldValue('inputConfirm', '');
    }, [formDocs.values.action]);

    return <>
        <Alert alert={alert} />

        <button
            type='button'
            disabled={selectedRows.length === 0 || selectedRows.length > 100}
            onClick={() => _openModalDocsManagement()}
            className='btn btn-outline-secondary'>
                Docs Management
        </button>

        <Modal show={showModalAlgorithms} size="xl" dialogClassName="w-98">
            <Modal.Header closeButton onClick={() => {setShowModalAlgorithms(false)}}>
                <Modal.Title>Documents Management</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 col-md-9">

                        <form onSubmit={formDocs.handleSubmit}>
                            <div className="row">
                                <div className="col-12 col-md-4">

                                    <strong>Source Collection: </strong> {collection}

                                    <Separator size={20} />

                                    <strong>Selected Documents</strong>

                                    <br/>
                                    <small className="text-muted">
                                        Please select all documents you want to manage. <br />
                                        To ensure data consistency, only documents with status "finish" or "done" can be managed. 
                                        Running documents are disabled.
                                    </small>

                                    <Separator size={30} />

                                    <select
                                        multiple
                                        style={{ height: '400px' }}
                                        className="form-select"
                                        name="documents"
                                        value={formDocs.values.documents}
                                        disabled={loadingManaging}
                                        onChange={(e: any) => {
                                            const options = e.target.options;
                                            const value: string[] = [];
                                            for (let i = 0; i < options.length; i++) {
                                                if (options[i].selected) {
                                                    value.push(options[i].value);
                                                }
                                            }
                                            formDocs.setFieldValue('documents', value);
                                        }}
                                        >
                                            {selectedRows.map((row: any, index: number) => (
                                                <option key={index} value={row.id} disabled={!["finish", "done"].includes(row.status)}>{row.id}</option>
                                            ))}
                                    </select>
                                    <small className="text-muted">
                                        {formDocs.values.documents.length} of {selectedRows.length} documents selected.
                                    </small>
                                </div>
                                <div className="col-12 col-md-4">

                                    <strong>Action</strong>

                                    <Separator size={30} />

                                    <label className="cursor-pointer">
                                        <strong>
                                            <input
                                                type="radio"
                                                value="copy"
                                                onChange={formDocs.handleChange}
                                                disabled={formDocs.values.documents.length === 0 || loadingManaging}
                                                name="action" /> Copy
                                        </strong>
                                        <br/>
                                        <small className="text-muted">
                                            Copy the selected documents to the target collection. 
                                            The original documents will remain in the source collection. 
                                            If the document already exists in the target collection, it will be skipped.
                                        </small>
                                    </label>

                                    <Separator size={30} />

                                    <label className="cursor-pointer">
                                        <strong>
                                            <input
                                                type="radio"
                                                value="move"
                                                onChange={formDocs.handleChange}
                                                disabled={formDocs.values.documents.length === 0 || loadingManaging}
                                                name="action" /> Move
                                        </strong>
                                        <br/>
                                        <small className="text-muted">
                                            Move the selected documents to the target collection. 
                                            If the document already exists in the target collection, it will be skipped. 
                                            <strong>The original documents will be deleted from the source collection</strong>.
                                        </small>
                                    </label>

                                    <Separator size={30} />

                                    <label className="cursor-pointer">
                                        <strong>
                                            <input
                                                type="radio"
                                                value="delete"
                                                onChange={formDocs.handleChange}
                                                disabled={formDocs.values.documents.length === 0 || loadingManaging}
                                                name="action" /> Delete
                                        </strong>
                                        <br/>
                                        <small className="text-muted">
                                            Delete the selected documents from the source collection.
                                        </small>
                                    </label>

                                    <Separator size={10} />

                                </div>
                                <div className="col-12 col-md-4">

                                    <strong>Destination</strong>

                                    <Separator size={30} />

                                    <select
                                        className="form-select"
                                        disabled={formDocs.values.action === "delete" || loadingManaging}
                                        name="destination"
                                        onChange={formDocs.handleChange}
                                        value={formDocs.values.destination}>
                                            <option value="">Select a target collection</option>
                                            <option value="usb_data" disabled={formDocs.values.sourceCollection === "usb_data"}>usb_data</option>
                                            <option value="dev_data" disabled={formDocs.values.sourceCollection === "dev_data"}>dev_data</option>
                                            <option value="clinical_data" disabled={formDocs.values.sourceCollection === "clinical_data"}>clinical_data</option>
                                            <option value="archive_data" disabled={formDocs.values.sourceCollection === "archive_data"}>archive_data</option>
                                    </select>
                                </div>
                            </div>

                            <Separator size={20} />

                            {formDocs.values.action !== "" && <>
                                <div className={`alert ${formDocs.values.action === "delete" ? 'alert-danger' : 'alert-warning'}`}>
                                    <i className="fas fa-exclamation-triangle me-2"></i> If proceed, this action will 

                                    {formDocs.values.action === 'copy' && <>
                                        <strong> COPY {formDocs.values.documents.length} documents </strong> from 
                                        <strong> {formDocs.values.sourceCollection} </strong> to <strong> {formDocs.values.destination}</strong>. <br />
                                        Documents will remain in the source collection.
                                    </>}

                                    {formDocs.values.action === 'move' && <>
                                        <strong> MOVE {formDocs.values.documents.length} documents </strong> from 
                                        <strong> {formDocs.values.sourceCollection} </strong> to <strong> {formDocs.values.destination}</strong>. <br />
                                        <strong>Documents will be deleted from the source collection.</strong>
                                    </>}

                                    {formDocs.values.action === 'delete' && <>
                                        <strong> DELETE {formDocs.values.documents.length} documents </strong> from 
                                        <strong> {formDocs.values.sourceCollection}</strong>.
                                    </>}

                                    <Separator size={20} />

                                    <strong>Warning!</strong> This operation cannot be undone.<br />
                                    Please, make sure you have selected the correct documents and action.

                                    <Separator size={20} />

                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="inputConfirm"
                                                    autoComplete="off"
                                                    disabled={formDocs.values.documents.length === 0 || loadingManaging}
                                                    value={formDocs.values.inputConfirm}
                                                    onChange={formDocs.handleChange}
                                                    placeholder={`Type '${formDocs.values.action.toUpperCase()} ${formDocs.values.documents.length || 0}' to confirm`}
                                                    className="form-control uppercase" />
                                            </div>
                                        </div>
                                    </div>

                                    <Separator size={20} />

                                    <button
                                        type="submit"
                                        disabled={!formDocs.isValid || loadingManaging}
                                        className={`btn ${formDocs.values.action === "delete" ? 'btn-danger' : 'btn-warning'}`}>
                                            <Loading loading={loadingManaging} parent="inline" />
                                            {!loadingManaging && <><i className="fas fa-check-circle me-2"></i></>} 

                                            Proceed to &nbsp;
                                            {formDocs.values.action === 'copy' && <><strong>COPY {formDocs.values.documents.length} documents</strong> from <strong>{formDocs.values.sourceCollection}</strong> to <strong>{formDocs.values.destination}</strong></>}

                                            {formDocs.values.action === 'move' && <><strong>MOVE {formDocs.values.documents.length} documents</strong> from <strong>{formDocs.values.sourceCollection}</strong> to <strong>{formDocs.values.destination}</strong></>}

                                            {formDocs.values.action === 'delete' && <><strong>DELETE {formDocs.values.documents.length} documents</strong> from <strong>{formDocs.values.sourceCollection}</strong></>}
                                    </button>
                                </div>
                            </>}
                        </form>

                        <Separator size={5} />

                        <div className="">
                            <small className="text-muted">
                                Last Database Backup: {lastDatabaseBackup}
                            </small>
                        </div>

                    </div>
                    <div className="col-12 col-md-3" style={{ maxHeight: "650px", overflow: "auto" }}>

                        <EchemQueueViewer />

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </>
}

export default DocumentsManagement;