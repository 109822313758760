import axios from 'axios';
import { Route, Routes } from 'react-router-dom';
import Charts from '../Charts/Charts';
import Navbar from '../../components/Navbar.component';
import Login from '../Login/Login';
import { useEffect, useState } from 'react';
import UsersManagement from '../UsersManagement/UsersManagement';
import PasswordRecover from '../PasswordRecover/PasswordRecover';
import Readers from '../Readers/Readers';
import Home from '../Home/Home';
import Lamp from '../Lamp/Lamp';
import PrimerViewer from '../PrimerViewer/PrimerViewer';
import Separator from '../../components/Separator.component';
import Templates from '../Templates/Templates';
import Settings from '../Settings/Settings';
import Ecrf from '../Ecrf/Ecrf';
import Dashboard from '../Dashboard/Dashboard';
import Algorithms from '../Algorithms/Algorithms';
import {appInit} from '../../services/appInit.service';
import AccessGroups from '../AccessGroups/AccessGroups.view';
import CencalReports from '../CencalReports/CencalReports.view';
import FirmwareHub from '../FirmwareHub/FirmwareHub';

axios.defaults.baseURL = process.env.REACT_APP_AXIOS_URL || "";

axios.interceptors.request.use(function (config) {
  const user: any = localStorage.getItem('user');
  if (!user) return config;
  const token = (user) ? JSON.parse(user).accessToken : '';
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});

  const appVersion: string = process.env.REACT_APP_VERSION || '0.0.0';

  appInit();

  useEffect(() => {
    const userStorage: any = localStorage.getItem('user');
    userStorage ? setIsLoggedIn(true) : setIsLoggedIn(false);
    setUser(JSON.parse(userStorage || '{}'));
  }, []);

  return (
    <div className="App">
      <Navbar />

      <div className="container-fluid">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="passwordRecover" element={<PasswordRecover />} />
          <Route path="/home" element={isLoggedIn ? <Home user={user} /> : <Login />} />
          <Route path="/plot" element={isLoggedIn ? <Charts user={user}  setUser={setUser}/> : <Login />} />
          <Route path="/readers" element={isLoggedIn ? <Readers user={user} /> : <Login />} />
          <Route path="/lamp" element={isLoggedIn ? <Lamp user={user} /> : <Login />} />
          <Route path="/users" element={isLoggedIn ? <UsersManagement user={user} /> : <Login />} />
          <Route path="/primerViewer" element={isLoggedIn ? <PrimerViewer user={user} /> : <Login />} />
          <Route path="/ecrf" element={isLoggedIn ? <Ecrf user={user} /> : <Login />} />
          <Route path="/dashboard" element={isLoggedIn ? <Dashboard user={user} /> : <Login />} />
          <Route path="/templates" element={isLoggedIn ? <Templates user={user} /> : <Login />} />
          <Route path="/settings" element={isLoggedIn ? <Settings user={user} /> : <Login />} />
          <Route path="/algorithms" element={isLoggedIn ? <Algorithms user={user} /> : <Login />} />
          <Route path="/cencalReports" element={isLoggedIn ? <CencalReports user={user} /> : <Login />} />
          <Route path="/AccessGroups" element={isLoggedIn ? <AccessGroups user={user} /> : <Login />} />
          <Route path="/firmwareHub" element={isLoggedIn ? <FirmwareHub user={user} /> : <Login />} />
        </Routes>
 
      </div>
      <div className="container text-center">
        <Separator size={50} />

        <small className="text-muted">
          data.aptitudelabs | v{appVersion}
        </small>
      </div>
    </div>
  );
}

export default App;
