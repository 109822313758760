import { useEffect, useState } from "react";
import { canAccess } from "../../services/helpers";
import {
    getDailyPatientsFromECRF,
    getSurveysFromECRF,
} from "../../services/ecrf.service";
import Separator from "../../components/Separator.component";
import Loading from "../../components/Loading.component";
import Alert from "../../components/Alert/Alert.component";
import DateRangeInterface from "../../services/DateRange.interface";

// Using https://reactdatagrid.io/
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import DateRange from "../../components/DateRange/DateRange.component";
import DownloadFlatFile from "../../components/DownloadFlatFile/DownloadFlatFile.component";
import { DashboardFilterInterface } from "../../services/DashboardFilterInterface";
import FilterBarDashboard from "../../components/FilterBarDashboard.component";
dayjs.extend(utc);

function Ecrf(props: any) {
    // Do not add any other code here
    useEffect(() => {
        if (!canAccess(props.user, 'ecrf')) {
            window.location.href = '/home';
            return;
        }
    }, [props.user, props.user.role]);

    const [patients, setPatients] = useState([]);
    const [loadingPatients, setLoadingPatients] = useState(false);
    const [alert, setAlert] = useState({ type: '', message: '' });

    const reloadDataByFilter = (dataFilter: DashboardFilterInterface) => {
        setDashboardFilterParams(dataFilter);
        loadSurveys(dataFilter);
        loadPatients(dataFilter);
    };

    const [dashboardFilterParams, setDashboardFilterParams] = useState({
        dateRange: {
            from: dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
            to: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        } as DateRangeInterface,
        protocol: '',
        sites: '',
        participants: ''
    } as DashboardFilterInterface);

    const loadPatients = async (filter: DashboardFilterInterface) => {
        setLoadingPatients(true);
        await getDailyPatientsFromECRF(filter).then((response: any) => {
            if (response.message === "OK") {
                setPatients(response.patients);
                setLoadingPatients(false);
                return;
            }

            console.log("Error getting patients. Message received", response);
            setAlert({ type: 'danger', message: 'Error getting patients. Message received' });
            setLoadingPatients(false);
        }).catch((err: any) => {
            console.log(err);
            setAlert({ type: 'danger', message: 'Error getting patients.' });
            setLoadingPatients(false);
        });
    };

    const [surveys, setSurveys] = useState([]);
    const [loadingSurveys, setLoadingSurveys] = useState(false);

    const loadSurveys = async (filter: DashboardFilterInterface) => {
        setLoadingSurveys(true);
        await getSurveysFromECRF(filter).then((response: any) => {
            if (response.message === "OK") {
                setSurveys(response.surveys);
                setLoadingSurveys(false);
                return;
            }

            console.log("Error getting surveys. Message received", response);
            setAlert({ type: 'danger', message: 'Error getting surveys. Message received' });
            setLoadingSurveys(false);
        }).catch((err: any) => {
            console.log(err);
            setAlert({ type: 'danger', message: 'Error getting surveys.' });
            setLoadingSurveys(false);
        });
    };

    useEffect(() => {
        setDashboardFilterParams(dashboardFilterParams);
        loadPatients(dashboardFilterParams);
        loadSurveys(dashboardFilterParams);
    }, []);

    const gridStyle = { minHeight: 600 };

    const patientsColumns: any[] = [
        { name: 'siteNo', header: 'Site No', minWidth: 20, defaultFlex: 0, },
        { name: 'patientIdComposite', header: 'Patient ID', minWidth: 120, defaultFlex: 1, },
        { name: 'name', header: 'Name', minWidth: 220, defaultFlex: 1, },
        { name: 'protocol', header: 'Protocol', minWidth: 170, defaultFlex: 1, },
        { name: 'collectedBy', header: 'Collected By', minWidth: 140, defaultFlex: 1, },
        {
            name: 'sampleCollectionTime', header: 'Collection Time', minWidth: 170, defaultFlex: 1,
            render: ({value}: any) => value ? dayjs(value).utc().format('MM/DD/YYYY HH:mm:ss') : "",
        },
        {
            name: 'npSampleTime', header: 'NP Sample Time', minWidth: 160, defaultFlex: 1,
            render: ({value}: any) => value ? dayjs(value).utc().format('MM/DD/YYYY HH:mm:ss') : "",
        },
        { name: 'readerLot', header: 'Reader Lot', minWidth: 120, defaultFlex: 1, },
        { name: 'readerId', header: 'Reader Id', minWidth: 120, defaultFlex: 1, },
        { name: 'kitLot', header: 'Kit Lot', minWidth: 120, defaultFlex: 1, },
        { name: 'participantInterpretedResult', header: 'Participant Int. Result', minWidth: 200, defaultFlex: 1, },
        { name: 'coordinatorInterpretedResult', header: 'Coordinator Int. Result', minWidth: 200, defaultFlex: 1, },
        { name: 'repeatParticipantInterpretedResult', header: 'Repeat Part. Int. Result', minWidth: 210, defaultFlex: 1, },
        { name: 'repeatCoordinatorInterpretedResult', header: 'Repeat Coord. Int. Result', minWidth: 220, defaultFlex: 1, },
        {
            name: 'symptomOnsetDate', header: 'Symptom Onset Date', minWidth: 170, defaultFlex: 1,
            render: ({value}: any) => value ? dayjs(value).format('MM/DD/YYYY HH:mm:ss') : "",
        },
        { name: 'comments', header: 'Comments', minWidth: 1000, defaultFlex: 1, },
        { name: 'hasErrorOrDeviation', header: 'Has Error or Deviation', minWidth: 150, defaultFlex: 1, render: ({value}: any) => value ? 'Yes' : 'No'},
        {
            name: 'submited', header: 'Submited', minWidth: 120, defaultFlex: 1,
            render: ({value}: any) => value ? 'Yes' : 'No'
        },
    ];

    const patientsColumnsFilter: any[] = [
        { name: 'siteNo', operator: 'startsWith', type: 'string', value: '', },
        { name: 'patientIdComposite', operator: 'startsWith', type: 'string', value: '', },
        { name: 'name', operator: 'startsWith', type: 'string', value: '', },
        { name: 'protocol', operator: 'startsWith', type: 'string', value: '', },
        { name: 'collectedBy', operator: 'startsWith', type: 'string', value: '', },
        { name: 'npSampleTime', operator: 'startsWith', type: 'string', value: '', },
        { name: 'readerLot', operator: 'startsWith', type: 'string', value: '', },
        { name: 'readerId', operator: 'startsWith', type: 'string', value: '', },
        { name: 'kitLot', operator: 'startsWith', type: 'string', value: '', },
        { name: 'participantInterpretedResult', operator: 'startsWith', type: 'string', value: '', },
        { name: 'coordinatorInterpretedResult', operator: 'startsWith', type: 'string', value: '', },
        { name: 'repeatParticipantInterpretedResult', operator: 'startsWith', type: 'string', value: '', },
        { name: 'repeatCoordinatorInterpretedResult', operator: 'startsWith', type: 'string', value: '', },
        { name: 'symptomOnsetDate', operator: 'startsWith', type: 'string', value: '', },
        { name: 'comments', operator: 'startsWith', type: 'string', value: '', },
        { name: 'hasErrorOrDeviation', operator: 'startsWith', type: 'string', value: '', },
        { name: 'submited', operator: 'startsWith', type: 'string', value: '', },
    ];

    const surveyColumns: any[] = [
        { name: 'protocolId', header: "Protocol ID", minWidth: 120, defaultFlex: 0, },
        { name: 'patientIdComposite', header: "Patient ID", minWidth: 120, defaultFlex: 0, },
        { name: 'demographics_name', header: "Name", minWidth: 120, defaultFlex: 0, },
        { name: "surveryFormSubmitedAt", header: "Submited At", minWidth: 120, defaultFlex: 0, },
        { name: 'colorBlindness', header: "Color Blindness", minWidth: 120, defaultFlex: 0, },
        { name: 'education', header: "Education", minWidth: 120, defaultFlex: 0, },
        { name: 'englishProficiency', header: "English Proficiency", minWidth: 120, defaultFlex: 0, },
        { name: 'ethnicity', header: "Ethnicity", minWidth: 120, defaultFlex: 0, },
        { name: 'languageToCompleteTest', header: "Lang. to Complete Test", minWidth: 120, defaultFlex: 0, },
        { name: 'nativeLanguage', header: "Native Lang.", minWidth: 120, defaultFlex: 0, },
        { name: 'race', header: "race", minWidth: 120, defaultFlex: 0, },
        { name: "easyToCapCollector", header: "Easy To Cap Collector", minWidth: 120, defaultFlex: 0, },
        { name: "easyToCollectSaliva", header: "Easy To Collect Saliva", minWidth: 120, defaultFlex: 0, },
        { name: "easyToDepositSaliva", header: "Easy To Deposit Saliva", minWidth: 120, defaultFlex: 0, },
        { name: "easyToInsertCollector", header: "Easy To Insert Collector", minWidth: 120, defaultFlex: 0, },
        { name: "easyToInsertCollectorIntoSensor", header: "Easy To Insert Collector Into Sensor", minWidth: 120, defaultFlex: 0, },
        { name: "easyToInsertSensorIntoReader", header: "Easy To Insert Sensor Into Reader", minWidth: 120, defaultFlex: 0, },
        { name: "easyToInsertSwab", header: "Easy To Insert Swab", minWidth: 120, defaultFlex: 0, },
        { name: "easyToObserveFluid", header: "Easy To Observe Fluid", minWidth: 120, defaultFlex: 0, },
        { name: "easyToReadResults", header: "Easy To Read Results", minWidth: 120, defaultFlex: 0, },
        { name: "easyToReadSalivaFluidLevel", header: "Easy To Read Saliva Fluid Level", minWidth: 120, defaultFlex: 0, },
        { name: "easyToRemoveSensor", header: "Easy To Remove Sensor", minWidth: 120, defaultFlex: 0, },
        { name: "easyToSnapOffSwabHandle", header: "Easy To SnapOff Swab Handle", minWidth: 120, defaultFlex: 0, },
        { name: "interactiveInstructions", header: "Interactive Instructions", minWidth: 120, defaultFlex: 0, },
        { name: "overallDevice", header: "Overall Device", minWidth: 120, defaultFlex: 0, },
        { name: "printedInstructions", header: "Printed Instructions", minWidth: 120, defaultFlex: 0, },
        { name: "understoodProcedure", header: "Understood Procedure", minWidth: 120, defaultFlex: 0, },
    ];

    const surveyColumnsFilter: any[] = [
        { name: 'siteNo', operator: 'startsWith', type: 'string', value: '', },
        { name: 'patientIdComposite', operator: 'startsWith', type: 'string', value: '', },
        { name: 'demographics_name', operator: 'startsWith', type: 'string', value: '', },
        { name: 'surveryFormSubmitedAt', operator: 'startsWith', type: 'string', value: '', },
        { name: "protocolId", operator: "startsWith", type: "string", value: "", },
        { name: "colorBlindness", operator: "startsWith", type: "string", value: "", },
        { name: "education", operator: "startsWith", type: "string", value: "", },
        { name: "englishProficiency", operator: "startsWith", type: "string", value: "", },
        { name: "ethnicity", operator: "startsWith", type: "string", value: "", },
        { name: "languageToCompleteTest", operator: "startsWith", type: "string", value: "", },
        { name: "nativeLanguage", operator: "startsWith", type: "string", value: "", },
        { name: "race", operator: "startsWith", type: "string", value: "", },
        { name: "easyToCapCollector", operator: "startsWith", type: "string", value: "", },
        { name: "easyToCollectSaliva", operator: "startsWith", type: "string", value: "", },
        { name: "easyToDepositSaliva", operator: "startsWith", type: "string", value: "", },
        { name: "easyToInsertCollector", operator: "startsWith", type: "string", value: "", },
        { name: "easyToInsertCollectorIntoSensor", operator: "startsWith", type: "string", value: "", },
        { name: "easyToInsertSensorIntoReader", operator: "startsWith", type: "string", value: "", },
        { name: "easyToInsertSwab", operator: "startsWith", type: "string", value: "", },
        { name: "easyToObserveFluid", operator: "startsWith", type: "string", value: "", },
        { name: "easyToReadResults", operator: "startsWith", type: "string", value: "", },
        { name: "easyToReadSalivaFluidLevel", operator: "startsWith", type: "string", value: "", },
        { name: "easyToRemoveSensor", operator: "startsWith", type: "string", value: "", },
        { name: "easyToSnapOffSwabHandle", operator: "startsWith", type: "string", value: "", },
        { name: "interactiveInstructions", operator: "startsWith", type: "string", value: "", },
        { name: "overallDevice", operator: "startsWith", type: "string", value: "", },
        { name: "printedInstructions", operator: "startsWith", type: "string", value: "", },
        { name: "understoodProcedure", operator: "startsWith", type: "string", value: "", },
    ];

    const [selectedRows, setSelectedRows] = useState([] as any[]);

    const _getDocumentsForFlatFile = (allRows: any[], onlySelectedRows: any[]) => {
        if (allRows.length === 0 && onlySelectedRows.length === 0) return [];
        const _finalRows: any[] = [];
        if (onlySelectedRows.length > 0) {
            onlySelectedRows.forEach((row: any) => _finalRows.push({collection: row.protocol_nameCollection, id: row.id}));
            return _finalRows;
        }
        allRows.forEach((row: any) => _finalRows.push({collection: row.protocol_nameCollection, id: row.id}));
        return _finalRows;
    };

    return <>
        <div className="container">
            <h2>ECRF</h2>
            <p className="text-muted">
                Review daily patients and surveys from the ECRF project.
            </p>

            <FilterBarDashboard onFilterDataChange={reloadDataByFilter}/>

            {["lucas.moreira@aptitudemedical.com", "qin.yang@aptitudemedical.com"].includes(props.user.email) && <>
                <div className="row mt-2">
                    <div className="col-4 offset-md-8 d-grid">
                        <DownloadFlatFile
                            project="ecrf"
                            documents={_getDocumentsForFlatFile(patients, selectedRows)}
                        />
                    </div>
                </div>
            </>}

            <Alert alert={alert} />

            <Separator size={20} />


            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="patients-tab" data-bs-toggle="tab" data-bs-target="#patients-tab-pane" type="button" role="tab" aria-controls="patients-tab-pane" aria-selected="true">
                        <Loading loading={loadingPatients} parent="inline" /> Daily Patients
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="survey-tab" data-bs-toggle="tab" data-bs-target="#survey-tab-pane" type="button" role="tab" aria-controls="survey-tab-pane" aria-selected="false">
                        <Loading loading={loadingSurveys} parent="inline" /> Survey Responses
                    </button>
                </li>
            </ul>

            <Separator size={40} />

            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="patients-tab-pane" role="tabpanel" aria-labelledby="patients-tab">

                    <Loading loading={loadingPatients} />

                    {!loadingPatients && patients.length === 0 && <p>No daily patients found.</p>}

                    {!loadingPatients && patients.length > 0 && <>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6">
                                <small className="text-muted">
                                    {patients.length} patients found
                                </small>
                            </div>
                            <div className="col-12 col-md-6 text-end">
                                <button
                                    onClick={() => loadPatients(dashboardFilterParams)}
                                    disabled={loadingPatients}
                                    className="btn btn-link btn-sm inline"
                                    type="button"><i className="fas fa-sync me-2"></i> Refresh Patients List
                                </button>
                            </div>
                        </div>

                        <Separator size={10} />

                        <ReactDataGrid
                            idProperty="patientIdComposite"
                            style={gridStyle}
                            columns={patientsColumns}
                            dataSource={patients}
                            defaultFilterValue={patientsColumnsFilter}
                            editable={true}
                            enableSelection
                            multiSelect
                            enableKeyboardNavigation={true}
                            onSelectionChange={(selection: any) => {
                                const _selectedRows: any[] = [];
                                for (const [, value] of Object.entries(selection.selected)) {
                                  _selectedRows.push(value);
                                }
                                setSelectedRows(_selectedRows);
                            }}
                        />
                    </>}

                </div>
                <div className="tab-pane fade" id="survey-tab-pane" role="tabpanel" aria-labelledby="survey-tab">

                    <Loading loading={loadingSurveys} />

                    {!loadingSurveys && surveys.length === 0 && <p>No surveys found.</p>}

                    {!loadingSurveys && surveys.length > 0 && <>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6">
                                <small className="text-muted">
                                    {surveys.length} surveys found
                                </small>
                            </div>
                            <div className="col-12 col-md-6 text-end">
                                <button
                                    onClick={() => loadSurveys(dashboardFilterParams)}
                                    disabled={loadingSurveys}
                                    className="btn btn-link btn-sm inline"
                                    type="button"><i className="fas fa-sync me-2"></i> Refresh Surveys List
                                </button>
                            </div>
                        </div>

                        <Separator size={10} />

                        <ReactDataGrid
                            idProperty="patientIdComposite"
                            style={gridStyle}
                            columns={surveyColumns}
                            dataSource={surveys}
                            defaultFilterValue={surveyColumnsFilter}
                            editable={true}
                            enableSelection={false}
                        />
                    </>}

                </div>
            </div>
        </div>
    </>
}

export default Ecrf;