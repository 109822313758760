import { useEffect, useState } from "react";
import { canAccess } from "../../services/helpers";
import Separator from "../../components/Separator.component";
import Loading from "../../components/Loading.component";
import { useFormik } from "formik";
import * as yup from 'yup';
import Alert from "../../components/Alert/Alert.component";
import { createRule, deleteRule, getSettings } from "../../services/settings.service";
import { getTemplates } from "../../services/templates.service";
import { getTwilioSmsLog } from "../../services/sales.service";

function Settings(props: any) {
    // Do not add any other code here
    useEffect(() => {
        if (!canAccess(props.user, 'settings')) {
            window.location.href = '/home';
            return;
        }
    }, [props.user, props.user.role]);

    const [alert, setAlert] = useState<any>({ type: '', message: '' });
    const [toggleNewRule, setToggleNewRule] = useState<boolean>(false);
    const [loadingSettings, setLoadingSettings] = useState<boolean>(false);
    const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
    const [loadingAddRule, setLoadingAddRule] = useState<boolean>(false);
    const [loadingRemoveRule, setLoadingRemoveRule] = useState<boolean>(false);
    const [templates, setTemplates] = useState<any[]>([] as any[]);
    const availableCollections: string[] = ["usb_data", "tcp_data", "clinical_data"];
    const [rules, setRules] = useState<any[]>([] as any[]);

    const removeRule = (ruleId: any) => {
        if (loadingRemoveRule) return;
        setLoadingRemoveRule(true);
        deleteRule(ruleId).then((res: any) => {
            if (res.message !== "OK") {
                console.log("ERROR removing rule > res", res);
                setAlert({type: "danger", message: "Error removing rule (res)"});
                setLoadingRemoveRule(false);
                return;
            }
            const newRules = rules.filter((rule: any) => rule.id !== ruleId);
            setRules(newRules);
            setAlert({type: "success", message: "Rule removed successfully"});
            setLoadingRemoveRule(false);
        }).catch((err: any) => {
            console.log("ERROR removing rule", err);
            setAlert({type: "danger", message: "Error removing rule"});
            setLoadingRemoveRule(false);
            return;
        });
    };

    // Check if all recipients are valid emails and not duplicated
    // recipientsList = "lucas.moreira@aptitudemedical.com, qin@aptitudemedical.com"
    const hasInvalidRecipient = (recipientsList: any): boolean => {
        const emailRegex: string = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
        const recipients: string[] = recipientsList.replace(';', ',').split(',');
        let hasInvalidRecipient = false;

        recipients.forEach((recipient: any) => {
            if (recipient.trim() === "") return;

            // Check if email is valid
            if (!(recipient.trim()).match(emailRegex)) {
                hasInvalidRecipient = true;
            }

            // Find duplicates in the list
            const duplicates: any = recipients.filter((item: any) => item === recipient);
            if (duplicates.length > 1) {
                hasInvalidRecipient = true;
            }
        });
        return hasInvalidRecipient;
    }

    const _getSettings = async () => {
        setLoadingSettings(true);
        await getSettings().then((res: any) => {
            setRules(res.settings);
            setLoadingSettings(false);
        }).catch((err: any) => {
            console.log("ERROR getting rules", err);
            setAlert({type: "danger", message: "Error getting rules"});
            setLoadingSettings(false);
            return;
        });
    };

    const _getTemplates = async () => {
        setLoadingTemplates(true);
        await getTemplates().then((res: any) => {
            // Transform each template.templateName to template.name
            const templates = res.templates.map((template: any) => {
                template.name = template.templateName;
                return template;
            });
            setTemplates(templates);
            setLoadingTemplates(false);
        }).catch((err: any) => {
            console.log("ERROR getting rules", err);
            setAlert({type: "danger", message: "Error getting rules"});
            setLoadingTemplates(false);
            return;
        });
    };

    const [loadingTwilioLog, setLoadingTwilioLog] = useState(false);
    const [twilioLog, setTwilioLog] = useState([] as any[]);
    const _getTwilioSmsLog = async () => {
        setLoadingTwilioLog(true);
        await getTwilioSmsLog().then((res: any) => {
            if (res.message === "OK") {
                setTwilioLog(res.data);
                setLoadingTwilioLog(false);
                return;
            }
            console.log("Twilio Log > res", res);
            setLoadingTwilioLog(false);
            setAlert({type: "danger", message: "Error getting Twilio Log"});
        }).catch((err: any) => {
            console.log("ERROR getting Twilio Log", err);
            setAlert({type: "danger", message: "Error getting Twilio Log"});
            setLoadingTwilioLog(false);
            return;
        });
    };

    useEffect(() => {
        const loadInitialData = () => {
            _getSettings();
            _getTemplates();
            _getTwilioSmsLog();
        }
        loadInitialData();
    }, []);

    const newRuleInitialValues = {
        ruleType: "",
        watchCollection: "",
        templateToAppend: {
            id: "",
            name: "",
        },
        recipients: "",
        emailBody: "",
    };

    const newRuleFormik = useFormik({
        initialValues: newRuleInitialValues,
        validateOnMount: false,
        validationSchema: yup.object({
            ruleType: yup.string().required('Rule type is required'),
            watchCollection: yup.string().required('Watch collection is required'),
            // templateToAppend: yup.object({
            //     id: yup.string().required('Template ID is required'),
            //     name: yup.string().required('Template Name is required'),
            // }).optional(),
            recipients: yup.string().optional(),
            emailBody: yup.string().optional(),
        }),
        onSubmit: async (values: any) => {
            // Remove , from first and last position, if any
            values.recipients = values.recipients.replace(/^,|,$/g, '');
            if (values.ruleType === "send_email" && hasInvalidRecipient(values.recipients)) {
                setAlert({type: "danger", message: "Check recipients list"});
                setLoadingAddRule(false);
                return;
            }

            if (values.ruleType === "template_append") {
                // Send only specific fields to the backend
                values.templateToAppend = {
                    id: values.templateToAppend.id,
                    name: values.templateToAppend.templateName,
                };
            }

            setLoadingAddRule(true);
            createRule(values).then((res: any) => {
                if (res.message !== "OK") {
                    console.log("ERROR adding new rule > res", res);
                    setAlert({type: "danger", message: "Error adding new rule (res)"});
                    setLoadingAddRule(false);
                    return;
                }
                setRules([...rules, res.newSettingData]);
                setAlert({type: "success", message: "New rule added successfully"});
                setToggleNewRule(false);
                setLoadingAddRule(false);
                newRuleFormik.resetForm();
            }).catch((err: any) => {
                console.log("ERROR adding new rule", err);
                setAlert({type: "danger", message: "Error adding new rule"});
                setLoadingAddRule(false);
                return;
            });
        }
    });

    return <>
        <Alert alert={alert} />

        <div className="container">
            <h2>Settings</h2>
            <p className="text-muted">
                Some templates might have references to other collections.<br />
                If the reference has a match, it will be appended to the new entry.
            </p>

            <Loading loading={loadingSettings} />

            {!loadingSettings && <>
                <Separator size={20} />

                <small className="text-muted">{rules.length} rules found</small>

                <Separator size={10} />

                {/* CURRENT RULES LIST */}
                {rules.length > 0 && rules.map((rule: any, ruleIndex: number) => {
                    return (
                        <div className="card shadow-0 border-0 bg-light mb-4" key={`rule-${ruleIndex}`}>
                            <div className="card-header">
                                <div onClick={() => removeRule(rule.id)} className="float-end text-danger cursor-pointer">
                                    <small>
                                        <Loading loading={loadingRemoveRule} parent="inline" color="text-danger" />
                                        {!loadingRemoveRule && <><i className="fas fa-trash me-2"></i></>} remove
                                    </small>
                                </div>
                                <strong>Rule #{ruleIndex+1} <small className="text-muted">(author: {rule.createdBy.name || ""})</small></strong>
                            </div>
                            <div className="card-body">
                                {/* TEMPLATE APPEND RULE */}
                                {rule.ruleType === "template_append" && <>
                                    When new entry is added to
                                    <span className="rule-keyword">{rule.watchCollection}</span> append the <span className="rule-keyword">{rule.templateToAppend.name}</span> template
                                </>}

                                {/* SEND EMAIL RULE */}

                                {rule.ruleType === "send_email" && <>
                                    When new entry is added to
                                    <span className="rule-keyword">{rule.watchCollection}</span> send email to <span className="rule-keyword">{rule.recipients}</span> with this body <span className="rule-keyword">{rule.emailBody}</span>
                                </>}

                            </div>
                        </div>
                    )
                })}


                {/* NEW RULE FORM */}
                {toggleNewRule && <>
                    <form onSubmit={(e: any) => {
                        e.preventDefault();
                        newRuleFormik.handleSubmit();
                    }}>
                        <div className="card shadow-0 border-0 bg-light mb-4">
                            <div className="card-body">
                                <strong>New Rule Definition</strong>

                                <Separator size={20} />

                                <div className="row mb-4">
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Choose the rule type</label>
                                            <select
                                                disabled={loadingAddRule}
                                                value={newRuleFormik.values.ruleType}
                                                onChange={newRuleFormik.handleChange}
                                                name="ruleType"
                                                className="form-control">
                                                    <option value="">Select type</option>
                                                    <option value="template_append">Append Template</option>
                                                    <option value="send_email">Send Email</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>


                                {newRuleFormik.values.ruleType !== "" && <>
                                    When new entry is added to
                                    <select
                                        disabled={loadingAddRule}
                                        value={newRuleFormik.values.watchCollection}
                                        onChange={newRuleFormik.handleChange}
                                        name="watchCollection"
                                        className="form-control ms-2 me-2 inline"
                                    >
                                        <option value="">Select a collection</option>
                                        {availableCollections.map((collection: any, collectionIndex: number) => {
                                            return (
                                                <option key={`template-${collectionIndex}`} value={collection}>{collection}</option>
                                            )
                                        })}
                                    </select>
                                </>}


                                {/* TEMPLATE APPEND RULE */}
                                {newRuleFormik.values.ruleType === "template_append" && <>
                                    append this template
                                    <select
                                        disabled={loadingAddRule || loadingTemplates || loadingSettings || loadingRemoveRule}
                                        value={newRuleFormik.values.templateToAppend.id}
                                        onChange={(e: any) => {
                                            const selectedTemplate: any = templates.find((template: any) => template.id === e.target.value);
                                            newRuleFormik.setFieldValue('templateToAppend', selectedTemplate);
                                        }}
                                        name="templateToAppend.nd"
                                        className="form-control ms-2 me-2 inline"
                                    >
                                        <option value="">Select a template</option>
                                        {!loadingTemplates && templates.map((template: any, templateIndex: number) => {
                                            return (
                                                <option key={`template-${templateIndex}`} value={template.id}>{template.templateName}</option>
                                            )
                                        })}
                                    </select>
                                </>}

                                {/* SEND EMAIL RULE */}
                                {newRuleFormik.values.ruleType === "send_email" && <>
                                    send email to
                                    <input
                                        disabled={loadingAddRule}
                                        name="recipients"
                                        onChange={newRuleFormik.handleChange}
                                        type="text"
                                        autoComplete="off"
                                        className="form-control ms-2 me-2 inline lowercase"
                                        value={newRuleFormik.values.recipients}
                                        style={{ minWidth: "600px" }}
                                        placeholder="Enter recipients separated by comma"
                                    />

                                    <Separator size={10} />

                                    <textarea className="form-control" rows={2}
                                        disabled={loadingAddRule}
                                        name="emailBody"
                                        onChange={newRuleFormik.handleChange}
                                        value={newRuleFormik.values.emailBody}
                                        placeholder={"Email body"}
                                    />

                                    <small className="text-mute">You can use tags to be replaced by its current value. Tags are the field name (case-sensitive) inside brakets. Like [name], [email], etc.</small>
                                </>}

                                {newRuleFormik.values.ruleType !== "" && <>
                                    <Separator size={20} />
                                    <button
                                        disabled={loadingAddRule || !newRuleFormik.isValid}
                                        type="submit"
                                        className="btn btn-success"
                                    >
                                        <Loading loading={loadingAddRule} parent="inline" color="text-white" />
                                        {!loadingAddRule && <><i className="fas fa-check me-2"></i></>} Save New Rule
                                    </button>
                                </>}

                            </div>
                        </div>
                    </form>
                </>}
                {/* /NEW RULE FORM */}


                <button
                    disabled={loadingAddRule || loadingRemoveRule}
                    onClick={() => setToggleNewRule(!toggleNewRule)}
                    className="btn btn-primary"
                >
                        <Loading loading={loadingAddRule} parent="inline" color="text-white" />
                        {!loadingAddRule && <><i className="fas fa-plus-circle me-2"></i></>} {toggleNewRule ? 'Cancel Add Rule' : 'Add Rule'}
                </button>
            </>}

            <Separator size={40} />

            {props.user && props.user.email === "lucas.moreira@aptitudemedical.com" && <>
                <strong>Twilio SMS Log</strong>

                <Separator size={20} />

                <Loading loading={loadingTwilioLog} />

                {!loadingTwilioLog && twilioLog.length === 0 && <>No logs found</>}

                {!loadingTwilioLog && twilioLog.length > 0 && <>
                    <small className="text-muted">{twilioLog.length} logs found</small>
                    <Separator size={10} />
                    <ul className="list-group">
                        {twilioLog.map((log: any, logIndex: number) => {
                            return (
                                <li key={`twilio-log-${logIndex}`} className="list-group-item">
                                    <div className="row">
                                        <div className="col">
                                            "{log.body}"
                                        </div>
                                        <div className="col-2 text-end">
                                            <small className="text-muted">at {log.createdAt}</small>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </>}
            </>}
        </div>
    </>
}

export default Settings;