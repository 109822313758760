import { createContext, useEffect, useState } from "react";
import { getFirebaseRemoteConfig } from "../services/remoteConfig.service";

export const RemoteConfigContext = createContext<any>(null);

export const RemoteConfigProvider = ({ children }: any) => {
  const [remoteConfig, setRemoteConfigData] = useState<any>({});

  useEffect(() => {
    const start = async () => {
      const remoteConfig: any = await _startRemoteConfig();
      setRemoteConfigData(remoteConfig);
    };
    start();
  }, []);

  const _startRemoteConfig = async () => {
    const remoteData: any = await getFirebaseRemoteConfig();

    let remote: any = {};
    const keys = Object.keys(remoteData);
    keys.forEach((key) => {
      let value: any = remoteData[key]._value;

      // Remote Config values are always strings
      // For feature flags, we can use JSON.parse to convert the string to an object
      // Default format is { enable: boolean, users: string[] }
      value = (value.startsWith("{") && value.endsWith("}")) ? JSON.parse(value) : value;

      remote = {
        ...remote,
        [key]: value
      };
    });

    return remote;
  };

  return (
    <RemoteConfigContext.Provider value={{remoteConfig}}>
      {children}
    </RemoteConfigContext.Provider>
  );
};