import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getLogsOfRowId } from "../../services/plot.service";
import Alert from "../Alert/Alert.component";
import Separator from "../Separator.component";
import Loading from "../Loading.component";

const DataLog = (props: any) => {
    const { selectedRows, dataOrigin } = props;

    const [alert, setAlert] = useState({type: "", message: ""});
    const [showModalDataLog, setShowModalDataLog] = useState(false);
    const [logs, setLogs] = useState([]);
    const [dirty, setDirty] = useState(false);
    const [filter, setFilter] = useState({ limit: 100, order: "asc"});
    const [loadingLogs, setLoadingLogs] = useState(false);
    const [countFullLog, setCountFullLog] = useState(0);

    useEffect(() => {
        if (showModalDataLog) {
            if (selectedRows && selectedRows[0] && selectedRows[0].id)
            getLogsOfSelectedRow();
            return;
        }

        setLogs([]);
    }, [showModalDataLog]);

    const getLogsOfSelectedRow = async () => {
        setLoadingLogs(true);
        const payload: any = {
            rowId: selectedRows[0].id,
            dataOrigin,
            limit: filter.limit,
            order: filter.order
        };

        setLogs([]);

        await getLogsOfRowId(payload).then((res: any) => {
            setDirty(false);
            if (res.message === "OK") {
                setLogs(res.logfile);
                setCountFullLog(res.countFullLog);
                setLoadingLogs(false);
                return;
            }

            setCountFullLog(0);
            setLoadingLogs(false);
            setAlert({type: "danger", message: res.message});
            console.log('Error getLogsOfRowId', res);
        }).catch((err: any) => {
            setCountFullLog(0);
            setAlert({type: "danger", message: err.message});
            setLoadingLogs(false);
            console.log('Catch getLogsOfRowId', err);
        });
    };

    const _downloadLogs = () => {
        // Convert ARRAY to simple string, one line per entry
        let logsString = "";
        logs.forEach((log: any) => {
            logsString += JSON.stringify(log, null, 2).replace(/\\n/g, "") + "\n";
        });

        // logsString || JSON.stringify(logs, null, 2)
        const element = document.createElement("a");
        const file = new Blob([logsString], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = `${selectedRows[0]?.id || "Logs"}-${logs.length || ""}-entries.txt`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        return false;
    };

    return <>
        <Alert alert={alert} />

        <div className="DataLog">
            <div className="d-grid">
                <button
                type='button'
                disabled={selectedRows && (selectedRows.length === 0 || selectedRows.length > 1)}
                onClick={() => setShowModalDataLog(true)}
                className='btn btn-outline-secondary'>View Logs</button>
            </div>

            <Modal show={showModalDataLog} size="xl">
                <Modal.Header closeButton onClick={() => setShowModalDataLog(false)}>
                    <Modal.Title>Log Viewer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRows && selectedRows[0] && selectedRows[0].id && <>
                        <small className="text-muted">Doc ID: {selectedRows[0].id}</small>

                        <Separator size={20} />

                        <div className="row align-items-end">
                            <div className="col-12 col-md-3">
                                <div className="form-group">
                                    <label>List Entries</label>
                                    <select
                                        className="form-control"
                                        defaultValue={filter.limit}
                                        disabled={loadingLogs}
                                        onChange={(e) => {
                                            setFilter({...filter, limit: parseInt(e.target.value)});
                                            setLogs([]);
                                            setDirty(true);
                                        }}>
                                            <option value={100}>100</option>
                                            <option value={300}>300</option>
                                            <option value={1000}>1000</option>
                                            <option value={2000}>2000</option>
                                            <option value={0}>All entries</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label>From</label>
                                    <select
                                        className="form-control"
                                        defaultValue={filter.order}
                                        disabled={loadingLogs}
                                        onChange={(e) => {
                                            setFilter({...filter, order: e.target.value});
                                            setLogs([]);
                                            setDirty(true);
                                        }}>
                                            <option value="asc">Begining (equivalent to the first N items)</option>
                                            <option value="desc">End (equivalent to the last N items)</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    disabled={loadingLogs}
                                    onClick={() => getLogsOfSelectedRow()}>
                                        {loadingLogs && <Loading loading={loadingLogs} parent="inline" />}
                                        {!loadingLogs && <i className="fas fa-check me-2"></i>} Apply
                                </button>
                            </div>
                        </div>
                    </>}

                    <Separator size={10} />

                    <small className="text-muted">

                        {filter.limit === 0 && <>
                            Will show all entries of {countFullLog}
                        </>}

                        {filter.limit !== 0 && <>
                            Will fetch the <strong className="text-primary">{filter.order === "asc" ? "first" : "last"} {filter.limit}</strong> entries of {countFullLog}
                        </>}

                        {filter.order === "desc" && countFullLog > 0 && <>
                            <br />
                            <div>It shows -1 (lines) because arrays start from 0</div>
                        </>}
                    </small>

                    <Separator size={20} />

                    <Loading loading={loadingLogs} />

                    {!dirty && <>
                        {!loadingLogs && logs.length === 0 && <strong>No logs found.</strong>}

                        {!loadingLogs && logs.length > 0 && <>
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => _downloadLogs()}>
                                    <i className="fas fa-download me-2"></i> Download Log
                            </button>

                            <Separator size={20} />

                            <ul className="list-group">
                                {logs.map((log: any, index: number) => {
                                    return (
                                        <li key={`log-entry-${index}`} className="list-group-item">
                                            <small className="text-muted me-2">
                                                {filter.order === "asc" ? index : (countFullLog-1) - index}
                                            </small> {JSON.stringify(log, null, 2).replace(/\\n/g, "")}
                                        </li>
                                    );
                                })}
                            </ul>
                        </>}
                    </>}

                    <Separator size={20} />

                    <button type="button" className="btn btn-outline-primary" onClick={() => setShowModalDataLog(false)}>
                        Close
                    </button>
                </Modal.Body>
            </Modal>

        </div>
    </>
};

export default DataLog;