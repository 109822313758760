import { useEffect, useState } from "react";
import {
    clearQueue,
    countPendingQueue,

} from "../../services/data.service";
import Loading from "../Loading.component";
import Alert from "../Alert/Alert.component";
import Separator from "../Separator.component";

const DataAnalysisCounterQueue = (props: any) => {
    const {
        pending,
        finish,
        error,
        user,
    } = props;

    const [alert, setAlert] = useState<any>({ type: '', message: '' });

    const [totalCounters, setTotalCounters] = useState({pending: "-", finish: "-", error: "-"} as any);
    const [loadingCounters, setLoadingCounters] = useState(true);

    const logsUrl: string = process.env.REACT_APP_DATA_ANALYSIS_CLOUD_LOG_URL || "";

    useEffect(() => {
        _getCounters();
    }, []);

    const _getCounters = async () => {
        setLoadingCounters(true);
        await countPendingQueue({pending, finish, error}).then((response: any) => {
            if (response.message === "OK") {
                setTotalCounters({pending: response.pending, finish: response.finish, error: response.error});
                setLoadingCounters(false);
                return;
            }

            setTotalCounters({pending: "-", finish: "-", error: "-"});
            setLoadingCounters(false);
        });
    };

    const [loadingClearFinishedQueue, setLoadingClearFinishedQueue] = useState(false);

    const clearFinishedQueue = async () => {
        setLoadingClearFinishedQueue(true);
        await clearQueue().then((response: any) => {
            console.log("response", response);
            if (response.message === "OK") {
                setAlert({ type: 'success', message: 'Queue cleared successfully<br/><small>("finish" status only)</small>' });
                _getCounters();
                setLoadingClearFinishedQueue(false);
                return;
            }

            setLoadingClearFinishedQueue(false);
            setAlert({ type: 'danger', message: 'Error clearing queue' });
        }).catch((err: any) => {
            console.log("err", err);
            setLoadingClearFinishedQueue(false);
            setAlert({ type: 'danger', message: 'Catch error on clearing queue' });
        });
    };

    return <>
        <Alert alert={alert} />

        <small className="text-muted">
            Data Analysis Queue {!loadingCounters && <>(<span className="text-primary cursor-pointer" onClick={() => _getCounters()}>refresh</span>)</>}:
            {loadingCounters && <>
                <span className="ms-3">
                    <Loading loading={true} parent="inline" />
                </span>
            </>}

            {!loadingCounters && <>
                <span className="ms-3">{totalCounters.pending} pending</span>
                <span className="ms-3">{totalCounters.finish} finish</span>
                <span className="ms-3">
                    <a href={logsUrl} target="_blank" rel="noopener noreferrer">{totalCounters.error} error</a>
                </span>
                {user.email === "lucas.moreira@aptitudemedical.com" && <>
                    <div className="mt-3 d-grid">
                        <button
                            type="button"
                            disabled={loadingClearFinishedQueue}
                            onClick={() => clearFinishedQueue()}
                            className="btn btn-outline-danger btn-sm">
                                <Loading loading={loadingClearFinishedQueue} parent="inline" color="text-danger" />
                                {!loadingClearFinishedQueue && <><i className="fas fa-trash me-2"></i></>} Clear Finished
                            </button>
                        <Separator size={10} />
                        <small className="text-muted">
                            <strong>Docs Management Queue</strong> and <strong>Data Analysis Queue</strong>
                        </small>
                    </div>
                </>}
            </>}
        </small>
    </>
}

export default DataAnalysisCounterQueue;