import { useEffect, useState } from "react";
import { addUser, deleteUser, editUser, getUserPermissionsOnCencal, getUsers } from "../../services/users.service";
import Loading from "../../components/Loading.component";
import Separator from "../../components/Separator.component";
import { useFormik } from "formik";
import * as yup from 'yup';
import { Modal } from 'react-bootstrap';
import { getAllAdminRoles, canAccess, getRoles, getAllRoles } from "../../services/helpers";
import Alert from "../../components/Alert/Alert.component";
import { getAccessGroups } from "../../services/accessGroups.service";
import { getUserProfile } from "../../services/auth.service";

// Using https://reactdatagrid.io/
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

function UsersManagement(props: any) {
    const user: any = props.user || {};

    // Do not add any other code here
    useEffect(() => {
        if (!canAccess(props.user, 'users')) {
            window.location.href = '/home';
            return;
        }
    }, [props.user, props.user.role]);

    const [isRunningAs, setIsRunningAs] = useState(false);

    useEffect(() => {
        const load = () => {
            _getUsers();
            _getAccessGroups();

            // Check if localStorage has the original user and accessToken
            const userOriginal: any = localStorage.getItem('user_original');
            const accessTokenOriginal: any = localStorage.getItem('accessToken_original');
            if (userOriginal || accessTokenOriginal) {
                setIsRunningAs(true);
            }
        };
        load();
    }, []);

    const [loadingGroups, setLoadingGroups] = useState(false);
    const [groups, setGroups] = useState([] as any[]);

    const _getAccessGroups = async () => {
        setLoadingGroups(true);
        await getAccessGroups().then((response: any) => {
            if (response.message === "OK") {
                setGroups(response.groups);
                setLoadingGroups(false);
                return;
            }
        }).catch((err: any) => {
            console.log("Error", err);
            setLoadingGroups(false);
        });
    };

    const [alert, setAlert] = useState({ type: '', message: '' });

    const [users, setUsers] = useState([] as any[]);
    const [loadingUsers, setLoadingUsers] = useState(false);

    // Table settings
    const gridStyle = { minHeight: 450 };

    const userColumns = [
        { name: 'id', header: 'ID', minWidth: 50, defaultFlex: 2 },
        { name: 'name', header: 'Name', minWidth: 50, defaultFlex: 2 },
        { name: 'email', header: 'Email', maxWidth: 1000, defaultFlex: 1 },
        { name: 'role', header: 'Echem Role', maxWidth: 1000, defaultFlex: 1 },
        { name: 'cencalRole', header: 'Role Cencal', maxWidth: 1000, defaultFlex: 1 },
        { name: 'groupId', header: 'Group ID', maxWidth: 1000, defaultFlex: 1 },
    ];

    const filterValue = [
        { name: 'name', operator: 'startsWith', type: 'string', value: '' },
        { name: 'email', operator: 'eq', type: 'string', value: null },
        { name: 'role', operator: 'eq', type: 'string', value: '' },
        { name: 'cencalRole', operator: 'eq', type: 'string', value: '' },
        { name: 'groupId', operator: 'eq', type: 'string', value: '' },
    ];
    // /Table settings

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (!showModal) {
            editUserFormik.resetForm();
            return;
        }

        if (editUserFormik.values.hasCencalAccess) {
            _getUserPermissionsOnCencal(editUserFormik.values);
        }
    }, [showModal]);

    const [availableRoles, setAvailableRoles] = useState([] as any[]);

    const _getUsers = async () => {
        setLoadingUsers(true);
        await getUsers().then((response: any) => {
            if (response.users) {
                setUsers(response.users);

                const roles = response.users.map((user: any) => user.role);
                const uniqueRoles = [...new Set(roles)];
                setAvailableRoles(uniqueRoles);

                setLoadingUsers(false);
                return;
            }
            console.log("Error getting users: ", response);
            setAlert({ type: 'danger', message: 'Error getting users' });
            setLoadingUsers(false);
        }).catch((error: any) => {
            setAlert({ type: 'danger', message: 'Error getting user' });
            setLoadingAddUser(false);
            console.log("Error getting user: ", error);
        });
    }

    const [loadingAddUser, setLoadingAddUser] = useState(false);

    const addUserInitialValues: any = {
        name: '',
        email: '',
        password: '',
        role: '',
        hasCencalAccess: false,
    }

    const editUserInitialValues: any = {
        id: '',
        name: '',
        email: '',
        role: '',

        hasCencalAccess: false,
        cencalRole: '',
        groupId: '',
    }

    const addUserFormik = useFormik({
        initialValues: addUserInitialValues,
        isInitialValid: false,
        validationSchema: yup.object({
            name: yup.string().required('Name is required'),
            email: yup.string().email().required('Email is required'),
            password: yup.string().required('Password is required'),
            role: yup.string().required('Role is required').oneOf(getAllRoles()),
        }),
        onSubmit: async (values: any) => {
            setLoadingAddUser(true);
            addUser(values).then((response: any) => {
                if (response.newUserId) {
                    // Add user to table in the first position
                    const newUsers = [...users];
                    newUsers.unshift({
                        id: response.newUserId,
                        name: values.name,
                        email: values.email,
                        role: values.role,
                    });
                    setUsers(newUsers);
                    setAlert({ type: 'success', message: 'User added successfully' });
                    setLoadingAddUser(false);
                    addUserFormik.resetForm();
                    return;
                }
                setAlert({ type: 'danger', message: `Error adding user: ${response.response.data.message || response.message}` });
                setLoadingAddUser(false);
                console.log("Error adding user: ", response);
            }).catch((error: any) => {
                setAlert({ type: 'danger', message: `Error adding user: ${error.response.data.message || error.message}` });
                setLoadingAddUser(false);
                console.log("Error adding user: ", error);
            });
        }
    });

    const [loadingEditUser, setLoadingEditUser] = useState(false);

    const editUserFormik = useFormik({
        initialValues: editUserInitialValues,
        isInitialValid: false,
        validationSchema: yup.object({
            id: yup.string().required('id is required AQUI'),
            name: yup.string().required('Name is required'),
            email: yup.string().email().required('Email is required'),
            role: yup.string().required('Role is required').oneOf(getAllRoles()),

            hasCencalAccess: yup.boolean().optional().default(false),
            cencalRole: yup.string().when('hasCencalAccess', ([hasCencalAccess], schema) => {
                if (hasCencalAccess) {
                    return schema.required('Cencal Role is required').oneOf(["super_admin", "site_admin", "coordinator"]);
                }
                return schema.optional().default('');
            }),

            groupId: yup.string().required().default(''),
        }),
        onSubmit: async (values: any) => {
            setLoadingEditUser(true);
            editUser(values).then((response: any) => {
                if (response.message === "OK") {
                    const index = users.findIndex((user: any) => user.id === values.id);
                    if (index !== -1) {
                        const newUsers = [...users];
                        newUsers[index] = values;
                        setUsers(newUsers);
                        setAlert({ type: 'success', message: 'User edited successfully' });
                        setShowModal(false);
                    }
                    setLoadingEditUser(false);
                    editUserFormik.resetForm();
                    return;
                }

                setAlert({ type: 'danger', message: `Error editing user: ${response.response.data.message || response.message}` });
                setLoadingEditUser(false);
                console.log("Error editing user: ", response);
            }).catch((error: any) => {
                setAlert({ type: 'danger', message: `Error editing user: ${error.response.data.message || error.message}` });
                setLoadingEditUser(false);
                console.log("Error editing user: ", error);
            });
        }
    });

    const [loadingDelete, setLoadingDelete] = useState(false);

    const removeUser = async () => {
        setLoadingDelete(true);
        deleteUser({ id: editUserFormik.values.id }).then((response: any) => {
            if (response.message !== "OK") {
                setAlert({ type: 'danger', message: response.message });
                setLoadingDelete(false);
                return;
            }

            // Remove user from table
            const newUsers = [...users];
            const index = newUsers.findIndex((user: any) => user.id === editUserFormik.values.id);
            if (index !== -1) {
                newUsers.splice(index, 1);
                setUsers(newUsers);
            }
            setAlert({ type: 'success', message: "User deleted" });
            setLoadingDelete(false);
            setShowModal(false);
        }).catch((error: any) => {
            console.log('Error on delete user', error);
            setAlert({ type: 'danger', message: error.message });
            setLoadingDelete(false);
        });
    }



    const _rolesOptions = ()  => {
        return getRoles(user.role).map((role: string) => {
            return <option key={role} value={role}>{role}</option>
        });
    }

    const [loadingPermissions, setLoadingPermissions] = useState(false);
    const [permissionsFromCencal, setPermissionsFromCencal] = useState(null as any);

    const [cencalRoleToView, setCencalRoleToView] = useState(user.cencalRole || "");

    useEffect(() => {
        if (editUserFormik.values.hasCencalAccess) {
            _getUserPermissionsOnCencal(editUserFormik.values);
        }
    }, [editUserFormik.values.hasCencalAccess]);

    const _getUserPermissionsOnCencal = async (user: any) => {
        if (editUserFormik.values.email) {
            setLoadingPermissions(true);
            setPermissionsFromCencal(null);
            await getUserPermissionsOnCencal({email: editUserFormik.values.email, cencalRole: cencalRoleToView}).then((response: any) => {
                if (response.message === "OK") {
                    setPermissionsFromCencal(response.data);
                    if (response.cencalRole !== user.cencalRoleToView) {
                        editUserFormik.setFieldValue("cencalRole", response.cencalRole);
                    }
                    setLoadingPermissions(false);
                    return;
                }

                console.log("RESPONSE", response);
                setLoadingPermissions(false);
            }).catch((error: any) => {
                console.log("ERROR", error);
                setLoadingPermissions(false);
            });
        }
    };

    const _previewNewPermissionsOnCencal = async (newRoleToPreview: string) => {
        setLoadingPermissions(true);
        setPermissionsFromCencal(null);
        await getUserPermissionsOnCencal({email: editUserFormik.values.email, cencalRole: newRoleToPreview}).then((response: any) => {
            if (response.message === "OK") {
                setPermissionsFromCencal(response.data);
                setLoadingPermissions(false);
                return;
            }

            setLoadingPermissions(false);
        }).catch((error: any) => {
            console.log("ERROR", error);
            setLoadingPermissions(false);
        });
    };

    const [loadingRunAs, setLoadingRunAs] = useState(false);
    const _runAs = async (uid: string) => {
        setLoadingRunAs(true);

        // 1. Get current user profile and backup it
        // When this page is loaded, if this user is running as another user,
        // display the "Run as" option.
        localStorage.setItem('user_original', localStorage.getItem('user') || '{}');
        localStorage.setItem('accessToken_original', localStorage.getItem('accessToken') || '');

        const useAs: any = await getUserProfile(uid).then((response: any) => response.user);

        localStorage.setItem('user', JSON.stringify({...useAs}));
        localStorage.setItem('accessToken', useAs.accessToken);

        setLoadingRunAs(false);
        window.location.href = '/home';
    };

    return <>
        <div className="container">
            <h2>Users <span className="text-muted">({users.length || 0})</span></h2>
            <Alert alert={alert} />

            <Separator size={20} />

            {user && getAllAdminRoles().includes(user.role) && <>
                <div className="card bg-light border-0">
                    <div className="card-body">
                        <strong>Add User</strong>
                        <Separator size={10} />
                        <form onSubmit={(e: any) => {
                            e.preventDefault();
                            addUserFormik.handleSubmit();
                        }}>
                            <div className="row align-items-end">
                                <div className="col">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input
                                        disabled={loadingAddUser || loadingEditUser}
                                        autoComplete="off"
                                        name="name"
                                        data-testid="name"
                                        value={addUserFormik.values.name}
                                        onChange={addUserFormik.handleChange}
                                        type="text" className="form-control" id="name" />
                                </div>
                                <div className="col">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        disabled={loadingAddUser || loadingEditUser}
                                        autoComplete="off"
                                        name="email"
                                        data-testid="email"
                                        value={addUserFormik.values.email}
                                        onChange={addUserFormik.handleChange}
                                        type="email" className="form-control" id="email" />
                                </div>
                                <div className="col">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input
                                        disabled={loadingAddUser}
                                        autoComplete="off"
                                        name="password"
                                        data-testid="password"
                                        value={addUserFormik.values.password}
                                        onChange={addUserFormik.handleChange}
                                        type="password" className="form-control" id="password" />
                                </div>
                                <div className="col">
                                    <label htmlFor="role" className="form-label">Role</label>
                                    <select
                                        disabled={loadingAddUser || loadingEditUser}
                                        name="role"
                                        data-testid="role"
                                        value={addUserFormik.values.role}
                                        onChange={addUserFormik.handleChange}
                                        className="form-select" aria-label="Select role"
                                    >
                                        {_rolesOptions()}
                                    </select>
                                </div>
                                <div className="col-2 text-end">
                                    <button
                                        disabled={loadingAddUser || loadingEditUser || !addUserFormik.isValid}
                                        type="submit" data-testid="add" className="btn btn-primary">
                                            {loadingAddUser && <Loading loading={loadingAddUser} parent="inline" color="text-white" />}
                                            {!loadingAddUser && <i className="fas fa-check me-2"></i>} Add
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <Separator size={20} />
            </>}

            <Loading loading={loadingUsers} />

            {!loadingUsers && users.length > 0 && <>
                {(props.user.email === "lucas.moreira@aptitudemedical.com" || isRunningAs) && <>
                    <div className="row">
                        <div className="col-6">

                            <strong><Loading loading={loadingRunAs} parent="inline" /> Run as &rarr;</strong>
                            {isRunningAs && <>
                                <Separator size={5} />
                                Running as <strong>{user.name}</strong> <small className="text-muted">({user.email})</small>
                                <Separator size={5} />
                            </>}
                            <select
                                className="form-select"
                                disabled={loadingRunAs}
                                onChange={(e: any) => _runAs(e.target.value)}>
                                    <option value="">--</option>
                                    {availableRoles.map((role: string) => {
                                        return <optgroup label={role} key={`user-groupByRole-${role}`}>
                                            {users.map((user: any) => {
                                                if (user.role === role) {
                                                    return <option
                                                        key={`user-groupByRole-user-${user.id}`}
                                                        disabled={user.email === props.user.email}
                                                        value={user.id}>
                                                            {user.name} | {user.email} {(user.id === props.user.uid) && " (You)"}
                                                    </option>
                                                }
                                            })}
                                        </optgroup>
                                    })}
                            </select>
                            <Separator size={5} />
                            <small className="text-muted">Remember to sign out and sign in again to regain your original permissions.</small>

                        </div>
                    </div>

                    <Separator size={20} />
                </>}

                {/* Using https://reactdatagrid.io/ */}
                <ReactDataGrid
                    idProperty="id"
                    columns={userColumns}
                    dataSource={users}
                    style={gridStyle}
                    pagination
                    defaultFilterValue={filterValue}
                    enableSelection
                    onSelectionChange={(selection: any) => {
                        editUserFormik.setValues(selection.data);
                        setCencalRoleToView(selection.data.cencalRole);
                        _getUserPermissionsOnCencal(selection.data);
                        setShowModal(true);
                    }}
                    // multiSelect
                />
            </>}
        </div>


        <Modal show={showModal} size="xl">
            <Modal.Header closeButton onClick={() => setShowModal(false)}>
                <Modal.Title>Edit {editUserFormik.values.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <form onSubmit={(e: any) => {
                    e.preventDefault();
                    editUserFormik.handleSubmit();
                }}>

                    <div className="row">
                        <div className="col">

                            <strong>User Profile</strong>

                            <Separator size={3} />

                            <small className="text-muted">{editUserFormik.values.id}</small>

                            <Separator size={10} />

                            <input
                                disabled={loadingEditUser}
                                name="id"
                                value={editUserFormik.values.id}
                                onChange={editUserFormik.handleChange}
                                type="hidden" className="form-control" />

                            <label htmlFor="name" className="form-label">Name</label>
                            <input
                                disabled={loadingEditUser}
                                autoComplete="off"
                                name="name"
                                value={editUserFormik.values.name}
                                onChange={editUserFormik.handleChange}
                                type="text" className="form-control" id="name" />

                            <Separator size={10} />

                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                disabled={loadingEditUser}
                                autoComplete="off"
                                name="email"
                                value={editUserFormik.values.email}
                                onChange={editUserFormik.handleChange}
                                type="email" className="form-control" id="email" />

                            <Separator size={10} />

                            <label htmlFor="role" className="form-label">Role (Echem) <i>- deprecated</i></label>
                            <select
                                disabled={loadingEditUser || (user && user.uid === editUserFormik.values.id)}
                                name="role"
                                value={editUserFormik.values.role}
                                onChange={editUserFormik.handleChange}
                                className="form-select" aria-label="Select role"
                            >
                                {_rolesOptions()}
                            </select>

                            <Separator size={10} />

                            <label className="form-label">User Group (Echem)</label>
                            <select
                                disabled={loadingEditUser || loadingGroups || !groups.length}
                                name="groupId"
                                value={editUserFormik.values.groupId}
                                onChange={editUserFormik.handleChange}
                                className="form-select" aria-label="Select role"
                            >
                                <option value="">Select a group</option>
                                {groups.length > 0 && groups.map((group: any) => {
                                    return <option key={`group-${group.id}-${user.uid}`} value={group.id}>{group.groupName}</option>
                                })}
                            </select>

                            <Separator size={40} />

                            <button
                                disabled={loadingEditUser || !editUserFormik.isValid}
                                type="submit" className="btn btn-primary">
                                    <Loading loading={loadingEditUser} parent="inline" color="text-white" />
                                    {!loadingEditUser && <i className="fas fa-check me-2"></i>} Update
                            </button>

                            {(user && user.uid !== editUserFormik.values.id) && <>
                                <button
                                    type="button"
                                    data-testid="remove-user"
                                    disabled={loadingEditUser || loadingDelete}
                                    className="btn btn-danger ms-2"
                                    onClick={() => removeUser()}>
                                        <Loading loading={loadingDelete} parent="inline" color="text-white" />
                                        {!loadingDelete && <><i className="fas fa-trash me-2"></i> </>} Remove User
                                </button>
                            </>}

                            <button type="button" data-testid="close-update-modal" className="btn btn-outline-primary ms-2" onClick={() => setShowModal(false)}>Close</button>

                        </div>
                        <div className="col">

                            <strong className="me-2">Cencal Labs Permissions</strong> <Loading loading={loadingPermissions} parent="inline" />

                            <Separator size={10} />

                            <label className="form-label ms-2">
                                <input
                                    type="checkbox"
                                    id="hasCencalAccess"
                                    name="hasCencalAccess"
                                    className="me-2"
                                    disabled={loadingEditUser || loadingPermissions}
                                    checked={editUserFormik.values.hasCencalAccess}
                                    onChange={editUserFormik.handleChange}
                                    value={editUserFormik.values.hasCencalAccess} />
                                    Has Cencal Access
                            </label>

                            {editUserFormik.values.hasCencalAccess && <>

                                <Separator size={20} />

                                <strong>Notes:</strong>
                                <Separator size={5} />
                                <small className="text-muted">
                                    <ul>
                                        <li>The email "{editUserFormik.values.email}" must exists on Cencal Labs website</li>
                                        <li>The role selected in this section won't affect Echem website, only the data visualization on the "Cencal Reports" module</li>

                                        <li><strong>SUPER ADMIN</strong>: can read data from all organizations, all protocols.</li>
                                        <li><strong>SITE ADMIN</strong>: can read data from its organization + sub organizations, only selected protocols to "read".</li>
                                        <li><strong>SITE COORDINATOR</strong>: can read data only from its organization, only the selected protocols to "read".</li>
                                    </ul>
                                </small>

                                <Separator size={10} />

                                <label htmlFor="cencalRole" className="form-label">Role (Cencal)</label>
                                <select
                                    disabled={loadingEditUser || loadingPermissions}
                                    name="cencalRole"
                                    value={editUserFormik.values.cencalRole}
                                    onChange={(e: any) => {
                                        editUserFormik.handleChange(e);
                                        _previewNewPermissionsOnCencal(e.target.value);
                                    }}
                                    className="form-select" aria-label="Select role"
                                >
                                    <option value="super_admin">Super Admin</option>
                                    <option value="site_admin">Site Admin</option>
                                    <option value="coordinator">Coordinator</option>
                                </select>

                                {editUserFormik.values.cencalRole !== "" && <>
                                    <Separator size={5} />

                                    <small className="text-muted">
                                        {editUserFormik.values.cencalRole === "super_admin" && <>
                                            <strong>Be Careful: </strong>
                                            Super Admin can read data from all organizations, all protocols.
                                        </>}

                                        {editUserFormik.values.cencalRole === "site_admin" && <>
                                            Site Admin can read data from its organization + sub organizations, only selected protocols to "read".
                                        </>}

                                        {editUserFormik.values.cencalRole === "coordinator" && <>
                                            Site Coordinator can read data only from its organization, only the selected protocols.
                                        </>}
                                    </small>
                                </>}

                                <Separator size={20} />

                                {!loadingPermissions && <>

                                    {!permissionsFromCencal && <>
                                        <small className="text-danger">
                                            This email were not found on the Cencal Labs users database.<br />
                                            You can proceed with the update, but the user won't have access to any data on Cencal Reports.
                                            <br />
                                            <br />
                                            <strong>
                                                Create "{editUserFormik.values.email}" on Cencal Labs website.
                                            </strong>
                                        </small>
                                    </>}

                                    {!loadingPermissions && permissionsFromCencal && <>
                                        <small className="text-muted">Here is a preview of the data this user will see in Cencal Reports:</small>

                                        <Separator size={20} />

                                        {/* SUPER_ADMIN */}

                                        {editUserFormik.values.cencalRole === "super_admin" && <>
                                            {permissionsFromCencal.allOrgs && permissionsFromCencal.allOrgs.length > 0 && permissionsFromCencal.allOrgs.map((itemOrg: any) => {
                                                return <div key={`user-${editUserFormik.values.email}-${itemOrg.id}-suborgs`} style={{ borderLeft: "solid 4px #efefef" }} className="ps-3">
                                                    <Separator size={10} />
                                                    <strong>{itemOrg.abbvName || "-"} - {itemOrg.fullName || "No Full Name"}</strong>

                                                    {/*
                                                    List all protocols
                                                    Since "protocols" already contains protocolsToRead,
                                                    Don't need to list protocolsToRead again
                                                    */}
                                                    {itemOrg.protocols && itemOrg.protocols.length > 0 && <>
                                                        <Separator size={5} />
                                                        <ul className="list-group">
                                                            {itemOrg.protocols.map((protocol: any) => {
                                                                return <li key={`user-${editUserFormik.values.email}-${itemOrg.id}-${protocol.id}-protocols`} className="list-group-item">
                                                                    {protocol.name}
                                                                </li>
                                                            })}
                                                        </ul>
                                                    </>}
                                                </div>
                                            })}
                                        </>}

                                        {/* OTHER ROLES (NOT SUPER_ADMIN) */}

                                        {editUserFormik.values.cencalRole !== "super_admin" && <>
                                            <Separator size={10} />

                                            {permissionsFromCencal.org && <>
                                                <strong>User Org</strong>

                                                <Separator size={10} />

                                                {permissionsFromCencal.org?.abbvName || "No Abbv Name"} - {permissionsFromCencal.org?.fullName || "No Full Name"}

                                                <Separator size={10} />

                                                {!permissionsFromCencal.org?.protocolsToRead && <>
                                                    <small className="text-muted">No protocols to read on "{permissionsFromCencal.org?.fullName || ""}"</small>
                                                </>}
                                            </>}

                                            {permissionsFromCencal.org && permissionsFromCencal.org?.protocolsToRead?.length > 0 && <>
                                                <div style={{ borderLeft: "solid 4px #efefef" }} className="ps-3">
                                                    <ul className="list-group">
                                                        {permissionsFromCencal.org.protocolsToRead.map((protocol: any) => {
                                                            return <li key={`user-${editUserFormik.values.email}-${protocol.id}-protocols`} className="list-group-item">
                                                                {protocol.name}
                                                            </li>
                                                        })}
                                                    </ul>
                                                </div>
                                            </>}

                                            {permissionsFromCencal.subOrgs && permissionsFromCencal.subOrgs.length > 0 && <>
                                                <Separator size={20} />

                                                <strong>Sub Orgs</strong>

                                                <Separator size={10} />

                                                {permissionsFromCencal.subOrgs.map((subOrg: any) => {
                                                    return <div key={`user-${editUserFormik.values.email}-${subOrg.id}-suborgs`} style={{ borderLeft: "solid 4px #efefef" }} className="ps-3">
                                                        <Separator size={10} />
                                                        {subOrg.abbvName || "No Abbv Name"} - {subOrg.fullName || "No Full Name"}

                                                        <Separator size={5} />

                                                        {!subOrg.protocolsToRead && <>
                                                            <small className="text-muted">No protocols to read on "{subOrg.fullName}"</small>
                                                        </>}

                                                        {subOrg.protocolsToRead && subOrg.protocolsToRead.length > 0 && <>
                                                            <ul className="list-group">
                                                                {subOrg.protocolsToRead.map((protocol: any) => {
                                                                    return <li key={`user-${editUserFormik.values.email}-${subOrg.id}-${protocol.id}-protocols`} className="list-group-item">
                                                                        {protocol.name}
                                                                    </li>
                                                                })}
                                                            </ul>
                                                        </>}
                                                    </div>
                                                })}
                                            </>}

                                        </>}

                                    </>}
                                </>}
                            </>}

                        </div>
                    </div>

                </form>

            </Modal.Body>
        </Modal>
    </>;
}

export default UsersManagement;