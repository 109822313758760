import xlsx from 'node-xlsx';
import { prefixSelectFields } from './helpers';

const fixChannelIndexName = (channelName: string) => {
    const channelNameIndex = channelName.toUpperCase().replace(prefixSelectFields, "");
    return `${prefixSelectFields}${parseInt(channelNameIndex,10) + 1}`;
}

export const selectChannelsFromRow = (row: any): any[] => {
    const result = [] as any;
    const channelsNames = Object.keys(row)
      .filter((key) => key.includes(prefixSelectFields))
      .sort()
      .filter(
        (x) =>
          x.indexOf("userMarkedAs") === -1 &&
          x.indexOf("ReaderCalling") === -1 &&
          x.indexOf("label") === -1
      );
    channelsNames.forEach((channelNameRow: any) => {
        result.push(channelNameRow);
      }
    );
    return result;
}

export const buildXlsxRaw = (payload: any): Promise<Buffer> => {
    let headerRow = ['Filename', 'DeviceID', 'DeviceName', 'StartTime'];
    
    function getSheets(data: any) {
        const sheets = [] as any;
        data.forEach((row: any) => {
            const newSheet = [] as any;
            const channels = selectChannelsFromRow(row)
            if (channels.length > 0) {
                let newHeaderRow = [...headerRow];
                channels.forEach((channel: string) => {
                    const channelIndexName = fixChannelIndexName(channel)
                    newHeaderRow = [...newHeaderRow, ...[`${channelIndexName}-y`,`${channelIndexName}-x`, `UserMarkedAs`]];
                })
                const times = row[channels[0]]?.data?.time
                newSheet.push(newHeaderRow);
                if (times && times.length > 0) {                    
                    times.forEach((time: any, index: number) => {
                        const newRow = [] as any;
                        newRow.push(row.filename);
                        newRow.push(row.reader_info && row.reader_info["Device ID"] ? row.reader_info["Device ID"] : "");
                        newRow.push(row.reader_info && row.reader_info["reader_name"] ? row.reader_info["reader_name"] : "");
                        newRow.push(row.start_time);
                        channels.forEach((channel: string) => {
                            if (row[channel]?.data?.fit){
                                newRow.push(`${row[channel].data.fit[index]}`);
                                newRow.push(`${time}`);
                            }
                            newRow.push(row[channel]?.userMarkedAs);
                        });
                        newSheet.push(newRow);
                    })
                }
            }else{
                newSheet.push(headerRow);
            }
            sheets.push({ name: `${row?.id}`, data: newSheet, options: {} })
        })
        return sheets;
    }

    return new Promise<Buffer>((resolve, reject) => {
        const { data } = payload;
        const buffer = xlsx.build(
            getSheets(data)
        );
        resolve(buffer);
    });
}

