import axios from 'axios';

interface IAlgorithm {
    name: string;
    url: string;
    chipType: IChipType[];
}

interface IChipType {
    name: string;
    cutoff_start: number;
    cutoff_end: number;
}

export const getAlgorithms = async () => {
    return await axios.get("/data/algorithms").then((response: any) => response.data).catch((err: any) => err);
}

export const dataComparison = async (payload: {idsToCompare: string[], dataOrigin: string, selectedAlgorithms: string[], selectedChannel: string[]}) => {
    return await axios.post("/data/algorithms/compareAlgorithmsFeatures", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const createAlgorithm = async (payload: IAlgorithm) => {
    return await axios.post("/data/algorithms", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const updateAlgorithm = async (payload: {id: string, autoRun: boolean}) => {
    return await axios.put(`/data/algorithms/${payload.id}`, payload).then((response: any) => response.data).catch((err: any) => err);
}

export const removeAlgorithm = async (id: string) => {
    return await axios.delete(`/data/algorithms/${id}`).then((response: any) => response.data).catch((err: any) => err);
}

export const checkAlgorithmUniqueName = async (payload: {name: string}) => {
    return await axios.post("/data/algorithms/checkUniqueName", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const countPendingQueue = async (payload: {pending: boolean, finish: boolean, error: boolean}) => {
    return await axios.post("/data/queue/countPending", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const clearQueue = async () => {
    return await axios.get("/data/queue/clear").then((response: any) => response.data).catch((err: any) => err);
}

export const addRowsToProcess = async (payload: any) => {
    return await axios.post("/data/addRowsToProcess", payload).then((response: any) => response.data).catch((err: any) => err);
}

interface DocumentsManagement {
    sourceCollection: string
    documents: string[]
    action: string
    destination: string
    inputConfirm: string
}

export const documentsManagement = async (payload: DocumentsManagement) => {
    return await axios.post("/data/documentsManagement", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const getPendingEchemQueueItems = async () => {
    return await axios.get("/echem_queue/pending").then((response: any) => response.data).catch((err: any) => err);
}
