import { useEffect, useState } from "react";
import { canAccess } from "../../services/helpers";
import {
    checkAlgorithmUniqueName,
    createAlgorithm,
    getAlgorithms,
    removeAlgorithm,
    updateAlgorithm,
} from "../../services/data.service";
import Alert from "../../components/Alert/Alert.component";
import Loading from "../../components/Loading.component";
import Separator from "../../components/Separator.component";
import { useFormik } from "formik";
import * as yup from 'yup';

const Algorithms = (props: any) => {
    // Do not add any other code here
    useEffect(() => {
        if (!canAccess(props.user, 'algorithms')) {
            window.location.href = '/home';
            return;
        }
    /* eslint-disable-next-line */
    }, [props.user.role]);

    const [alert, setAlert] = useState<any>({ type: '', message: '' });
    const [algorithms, setAlgorithms] = useState([] as any);
    const [loadingAlgorithms, setLoadingAlgorithms] = useState(true);
    const [loadingCreateAlgorithm, setLoadingCreateAlgorithm] = useState(false);
    const [uniqueAlgorithmName, setUniqueAlgorithmName] = useState('');
    const [isUniqueAlgorithmName, setIsUniqueAlgorithmName] = useState(null as any);
    const [loadingCheckAlgorithmUniqueName, setLoadingCheckAlgorithmUniqueName] = useState(false);
    const [toggleCreateAlgorithm, setToggleCreateAlgorithm] = useState(false);

    useEffect(() => {
        _getAlgorithms();
    }, []);

    const _getAlgorithms = async () => {
        getAlgorithms().then((response: any) => {
            if (response.message === "OK") {
                setAlgorithms(response.algorithms);
                setLoadingAlgorithms(false);
                return;
            }

            setLoadingAlgorithms(false);
        }).catch((error: any) => {
            console.log("Error", error);
            setLoadingAlgorithms(false);
        });
    }

    useEffect(() => {
        if (uniqueAlgorithmName === "") {
            setIsUniqueAlgorithmName(false);
            setLoadingCheckAlgorithmUniqueName(false);
        }

        if (uniqueAlgorithmName.length > 3) {
            generateUniqueAlgorithmName();
        }
    }, [uniqueAlgorithmName]);

    const generateUniqueAlgorithmName = async () => {
        const name = formCreateAlgorithm.values.name;

        let uniqueName = name;
        uniqueName = uniqueName.replace(/\s/g, '-');

        setLoadingCheckAlgorithmUniqueName(true);
        await checkAlgorithmUniqueName({name: uniqueName}).then((responseUniqueName: any) => {
            if (responseUniqueName.message === "OK") {
                setIsUniqueAlgorithmName(true);
                setLoadingCheckAlgorithmUniqueName(false);
                return;
            }

            setIsUniqueAlgorithmName(false);
            setLoadingCheckAlgorithmUniqueName(false);
        }).catch((errorUniqueName: any) => {
            setIsUniqueAlgorithmName(false);
            setLoadingCheckAlgorithmUniqueName(false);
        });

        setUniqueAlgorithmName(uniqueName);
    };

    const createAlgorithmInitialValues: any = {
        name: '',
        url: '',
        chipType: [
            {
                name: '4-Channel Covid-19 FluA and B Sensor',
                cutoff_start: 0,
                cutoff_end: 0,
            },
            {
                name: '2-Channel Covid-19 sensor',
                cutoff_start: 0,
                cutoff_end: 0,
            },
        ],
    };

    const formCreateAlgorithm = useFormik({
        initialValues: createAlgorithmInitialValues,
        isInitialValid: false,
        validateOnMount: true,
        validationSchema: yup.object({
            name: yup.string().required('Required'),
            url: yup.string().required('Required'),
            chipType: yup.array().of(
                yup.object().shape({
                    name: yup.string().required('Required'),
                    cutoff_start: yup.number().required('Required'),
                    cutoff_end: yup.number().required('Required'),
                })
            ).required('Required'),
        }),
        onSubmit: async (values: any) => {
            setLoadingCreateAlgorithm(true);
            values.uniqueName = uniqueAlgorithmName;
            await createAlgorithm(values).then((responseCreateAlgorithm: any) => {
                if (responseCreateAlgorithm.message === "OK") {
                    setAlert({ type: 'success', message: 'Algorithm created successfully' });
                    setLoadingCreateAlgorithm(false);
                    _getAlgorithms();
                    setToggleCreateAlgorithm(false);
                    formCreateAlgorithm.resetForm();
                    return;
                }

                setAlert({ type: 'danger', message: 'Error creating algorithm' });
                setLoadingCreateAlgorithm(false);
            }).catch((errorCreateAlgorithm: any) => {
                console.log("Catch Error on Create", errorCreateAlgorithm);
                setAlert({ type: 'danger', message: 'Catch error creating algorithm' });
                setLoadingCreateAlgorithm(false);
            });
        }
    });

    const trimString = (str: string, length: number) => {
        if (str.length > length) {
            return `...${str.substring(str.length - length, str.length)}`;
        }
        return str;
    };

    const updateAutoRun = async (algorithm: any, autoRun: boolean) => {
        console.log("Update Auto Run", algorithm.id, autoRun);

        algorithms.find((item: any) => item.id === algorithm.id).autoRun = autoRun;
        setAlgorithms([...algorithms]);
        setAlert({ type: 'success', message: `Auto Run updated for "${algorithm.name}"` });

        await updateAlgorithm({id: algorithm.id, autoRun}).then((responseUpdateAlgorithm: any) => {
            if (responseUpdateAlgorithm.message !== "OK") {
                algorithms.find((item: any) => item.id === algorithm.id).autoRun = !autoRun;
                setAlgorithms([...algorithms]);
                setAlert({ type: 'danger', message: 'Error updating Auto Run' });
            }
        }).catch((errorUpdateAlgorithm: any) => {
            console.log("Catch Error on Update", errorUpdateAlgorithm);
            setAlert({ type: 'danger', message: 'Catch error updating Auto Run' });

            algorithms.find((item: any) => item.id === algorithm.id).autoRun = !autoRun;
            setAlgorithms([...algorithms]);
        });
    };

    const [loadingRemoveAlgorithm, setLoadingRemoveAlgorithm] = useState(false);

    const _removeAlgorithm = async (algorithm: any) => {
        setLoadingRemoveAlgorithm(true);
        await removeAlgorithm(algorithm.id).then((responseRemoveAlgorithm: any) => {
            if (responseRemoveAlgorithm.message === "OK") {
                setAlgorithms(algorithms.filter((item: any) => item.id !== algorithm.id));
                setAlert({ type: 'success', message: `Algorithm <strong>${algorithm.name}</strong> successfully` });
                setLoadingRemoveAlgorithm(false);
                return;
            }

            console.log("Error removing algorithm", responseRemoveAlgorithm);
            setAlert({ type: 'danger', message: `Error removing algorithm <strong>${algorithm.name}</strong>` });
            setLoadingRemoveAlgorithm(false);
        });
    };

    return <>
        <Alert alert={alert} />

        <div className="container">
            <h2>Algorithms <span className="text-muted">({algorithms.length || 0})</span></h2>
            <p className="text-muted">
                Data Analysis Algorithms
            </p>

            <Separator size={20} />

            {!toggleCreateAlgorithm && <>
                <button type="button" disabled={loadingAlgorithms || loadingCreateAlgorithm} className="btn btn-outline-primary" onClick={() => setToggleCreateAlgorithm(true)}>
                    <i className="fas fa-plus-circle me-2"></i> Create Algorithm
                </button>
            </>}

            {toggleCreateAlgorithm && <>
                <div className="card shadow-sm bg-light border-0">
                    <div className="card-body">

                        <strong>Create Algorithm</strong>

                        <Separator size={20} />

                        <form onSubmit={formCreateAlgorithm.handleSubmit}>
                            <div className="row mb-3">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Algorithm Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            onChange={formCreateAlgorithm.handleChange}
                                            onKeyUp={(e: any) => setUniqueAlgorithmName(e.target.value)}
                                            value={formCreateAlgorithm.values.name}
                                            disabled={loadingCreateAlgorithm}
                                            autoComplete="off"
                                            className="form-control" />
                                    </div>
                                    <small className="text-muted">
                                        This name (case-sensitive) must be unique to identify the algorithm in the database.<br />
                                        Spaces will be replaced by dashes.<br />
                                        <strong>Unique Name:</strong> <strong>{uniqueAlgorithmName}</strong> 
                                        {!loadingCheckAlgorithmUniqueName && isUniqueAlgorithmName && <><i className="fas fa-check-circle text-primary ms-2"></i></>}
                                        {!loadingCheckAlgorithmUniqueName && isUniqueAlgorithmName === false && <><i className="fas fa-times-circle text-danger ms-2"></i></>}
                                        <span className="ms-2"><Loading loading={loadingCheckAlgorithmUniqueName} parent="inline" /></span>
                                    </small>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <label>URL</label>
                                        <input
                                            type="url"
                                            name="url"
                                            onChange={formCreateAlgorithm.handleChange}
                                            value={formCreateAlgorithm.values.url}
                                            disabled={loadingCheckAlgorithmUniqueName || loadingCreateAlgorithm}
                                            autoComplete="off"
                                            className="form-control" />
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col">

                                    <div className="card shadow-sm border-0">
                                        <div className="card-body">

                                            <strong>4-Channel Covid-19 FluA and B Sensor</strong>

                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label>cutoff_start</label>
                                                        <input
                                                            type="number"
                                                            name="chipType[0].cutoff_start"
                                                            onChange={formCreateAlgorithm.handleChange}
                                                            value={formCreateAlgorithm.values.chipType[0].cutoff_start}
                                                            disabled={loadingCheckAlgorithmUniqueName || loadingCreateAlgorithm}
                                                            autoComplete="off"
                                                            className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label>cutoff_end</label>
                                                        <input
                                                            type="number"
                                                            name="chipType[0].cutoff_end"
                                                            onChange={formCreateAlgorithm.handleChange}
                                                            value={formCreateAlgorithm.values.chipType[0].cutoff_end}
                                                            disabled={loadingCheckAlgorithmUniqueName || loadingCreateAlgorithm}
                                                            autoComplete="off"
                                                            className="form-control" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="col">

                                    <div className="card shadow-sm border-0">
                                        <div className="card-body">
                                            <strong>2-Channel Covid-19 sensor</strong>

                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label>cutoff_start</label>
                                                        <input
                                                            type="number"
                                                            name="chipType[1].cutoff_start"
                                                            onChange={formCreateAlgorithm.handleChange}
                                                            value={formCreateAlgorithm.values.chipType[1].cutoff_start}
                                                            disabled={loadingCheckAlgorithmUniqueName || loadingCreateAlgorithm}
                                                            autoComplete="off"
                                                            className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label>cutoff_end</label>
                                                        <input
                                                            type="number"
                                                            name="chipType[1].cutoff_end"
                                                            onChange={formCreateAlgorithm.handleChange}
                                                            value={formCreateAlgorithm.values.chipType[1].cutoff_end}
                                                            disabled={loadingCheckAlgorithmUniqueName || loadingCreateAlgorithm}
                                                            autoComplete="off"
                                                            className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <Separator size={20} />

                            <button
                                type="submit"
                                disabled={formCreateAlgorithm.isSubmitting || !formCreateAlgorithm.isValid || loadingCreateAlgorithm || loadingCheckAlgorithmUniqueName || !isUniqueAlgorithmName}
                                className="btn btn-primary">
                                    {!loadingCreateAlgorithm && <><i className="fas fa-check me-2"></i></>}
                                    <Loading loading={loadingCreateAlgorithm} parent="inline" color="text-white" /> Create Algorithm
                            </button>

                            <button type="button" className="btn btn-outline-secondary ms-2" onClick={() => setToggleCreateAlgorithm(false)}>Cancel</button>
                        </form>

                    </div>
                </div>

                <Separator size={20} />
            </>}

            <Separator size={20} />

            <Loading loading={loadingAlgorithms} />

            {!loadingAlgorithms && algorithms.length > 0 && <>
                <div className="mt-3">

                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Algorithm</th>
                                <th>Parameters</th>
                            </tr>
                        </thead>
                        <tbody>
                            {algorithms.map((algorithm: any, index: number) => {
                                return <tr key={`algorithm-${index}`}>
                                    <td>
                                        <strong>{algorithm.name}</strong><br />
                                        <small><a href={algorithm.url} title={algorithm.url} target="_blank" rel="noopener noreferrer">{trimString(algorithm.url, 50)}</a></small>

                                        <Separator size={10} />

                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        onChange={(e) => updateAutoRun(algorithm, e.target.checked)}
                                                        checked={algorithm.autoRun} />
                                                    <label className="form-check-label">Auto Run</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-danger btn-sm"
                                                    disabled={loadingRemoveAlgorithm}
                                                    onClick={() => _removeAlgorithm(algorithm)}>
                                                        <Loading loading={loadingRemoveAlgorithm} parent="inline" color="text-danger" />
                                                        {!loadingRemoveAlgorithm && <><i className="fas fa-times me-2"></i></>} Remove
                                                </button>
                                            </div>
                                        </div>

                                    </td>
                                    <td>
                                        <div className="row">
                                            {algorithm.chipType.map((chip: any, index: number) => {
                                                return <div key={`chip-${index}`} className="col-6">
                                                    <div className="card shadow-0">
                                                        <div className="card-body">
                                                            <strong>{chip.name}</strong>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <strong>cutoff_start:</strong> {chip.cutoff_start}
                                                                </div>
                                                                <div className="col">
                                                                    <strong>cutoff_end:</strong> {chip.cutoff_end}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>

                </div>
            </>}
        </div>
    </>
}

export default Algorithms;