import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {RemoteConfigProvider} from './context/remoteConfig.context';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './views/App/App';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance'

import './index.scss';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || '',
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_VERSION || "1.0.0",
  appType: "echem_frontend",
  enabledReleaseStages: ["production", "development"],
  releaseStage: process.env.REACT_APP_ENV || 'development',
  user: {
    id: "00",
    name: "Echem Frontend",
    email: "no-reply@aptitudelabs.com",
  },
});

BugsnagPerformance.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || '',
  appVersion: process.env.REACT_APP_VERSION || "1.0.0",
  enabledReleaseStages: ["production", "development"],
  releaseStage: process.env.REACT_APP_ENV || 'development',
});

const ErrorBoundary: any = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
// const plugin = Bugsnag.getPlugin("react") as BugsnagPluginReactResult;
// export const ErrorBoundary = plugin.createErrorBoundary();

const ErrorView = () => {
  return <>
    <center style={{ marginTop: "40px" }}>
      <div>
        <p>
          Something went wrong. Please try again.<br />
          If the problem persists, please contact the IT Support.
        </p>
      </div>
    </center>
  </>
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorView}>
      {/* <ErrorBoundary> */}
        <RemoteConfigProvider>
          <App />
        </RemoteConfigProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
