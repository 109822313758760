import axios from 'axios';

export const togglePatientApproval = async (payload: {patientId: string, protocolNameCollection: string}) => {
    return await axios.post(`sti/${payload.patientId}/toggleApproval`, {protocolNameCollection: payload.protocolNameCollection}).then((response: any) => response.data).catch((err: any) => err);
}

export const toggleMultiplePatientApproval = async (payload: {patientId: string, protocolNameCollection: string, newApprovalStatus: string}[]) => {
    return await axios.post(`sti/multiPatientToggleApproval`, payload).then((response: any) => response.data).catch((err: any) => err);
}

export const getCencalReports = async (payload: any) => {
    return await axios.post("cencal", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const getStiMessages = async (patientDocId: string) => {
    return await axios.get(`sti/${patientDocId}/messages`).then((response: any) => response.data).catch((err: any) => err);
}

export const getPatientAuditTrail = async (patientDocId: string, collectionName: string) => {
    return await axios.post(`sti/${patientDocId}/auditTrail`, {collectionName}).then((response: any) => response.data).catch((err: any) => err);
}

interface SendMessagePayload {
    message: string;
    to: string;
    patientIdComposite: string;
    name: string;
    protocol: string;
}
export const sendMessage = async (payload: SendMessagePayload) => {
    return await axios.post("sti/messages", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const markMessageAsComplete = async (messageId: string) => {
    return await axios.patch(`sti/messages/${messageId}`).then((response: any) => response.data).catch((err: any) => err);
}
